<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';
import type { RawLocation } from 'vue-router';

import BaseMenuItem from './private/BaseMenuItem.vue';

export default Vue.extend({
  functional: true,
  props: {
    to: {
      type: [String, Object] as PropType<RawLocation>,
      default: null,
    },
    href: {
      type: String,
      default: '#',
    },
  },
  render(h, { props, data, children }) {
    const tag = props.to !== null ? 'router-link' : 'a';

    return h(
      BaseMenuItem,
      mergeData(data, {
        scopedSlots: {
          default: ({ attrs, on, children: content }) => {
            return h(
              tag,
              {
                props: tag === 'router-link' ? { to: props.to } : undefined,
                attrs: tag === 'a' ? { ...attrs, href: props.href } : undefined,
                on: {
                  ...on,
                  click: (e: Event) => {
                    if (props.href === '#') {
                      e.preventDefault();
                    }
                    if (on.click !== undefined) {
                      on.click(e);
                    }
                  },
                },
              },
              content
            );
          },
        },
      }),
      children
    );
  },
});
</script>
