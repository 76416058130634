import Vue from 'vue';

import Icon from '../Icon/Icon';

export default Vue.extend({
  name: 'SldsWelcomeMatTile',
  functional: true,
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  render(h, { props }) {
    return h(
      'li',
      {
        staticClass: 'slds-welcome-mat__tile slds-welcome-mat__tile_info-only',
      },
      [
        h('div', { staticClass: 'slds-media' }, [
          h(
            'div',
            {
              staticClass:
                'slds-media__figure slds-media__figure_fixed-width slds-align_absolute-center',
            },
            [
              h('div', { staticClass: 'slds-welcome-mat__tile-figure' }, [
                h(
                  'div',
                  {
                    staticClass: 'slds-welcome-mat__tile-icon-container',
                  },
                  [
                    h(Icon, {
                      props: { category: 'utility', name: props.icon },
                    }),
                  ]
                ),
              ]),
            ]
          ),
          h('div', { staticClass: 'slds-welcome-mat__tile-body' }, [
            h(
              'h3',
              { staticClass: 'slds-welcome-mat__tile-title' },
              props.title
            ),
            h(
              'p',
              { staticClass: 'slds-welcome-mat__tile-description' },
              props.description
            ),
          ]),
        ]),
      ]
    );
  },
});
