<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { SelectableMenuItemType } from './Menu.types';
import BaseMenuItem from './private/BaseMenuItem.vue';
import MenuIconVue from '../MenuIcon.vue';

export default Vue.extend({
  name: 'SldsSelectableMenuIcon',
  functional: true,
  props: {
    type: {
      type: String as PropType<SelectableMenuItemType>,
      default: undefined,
      validator: (val: SelectableMenuItemType) =>
        Object.values(SelectableMenuItemType).includes(val),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children }) {
    return h(
      BaseMenuItem,
      mergeData(data, {
        class: {
          'slds-is-selected': props.selected,
        },
        scopedSlots: {
          'icon-left': () =>
            h(MenuIconVue, {
              props: { name: 'check' },
              staticClass: 'slds-icon_selected',
            }),
          default: ({ attrs, on, children: content }) => {
            return h(
              'a',
              {
                attrs: {
                  ...attrs,
                  role:
                    props.type === SelectableMenuItemType.RADIO
                      ? 'menuitemradio'
                      : 'menuitemcheckbox',
                  href: '#',
                  'aria-checked': props.selected ? 'true' : 'false',
                },
                on,
              },
              content
            );
          },
        },
      }),
      children
    );
  },
});
</script>
