import { computed } from '@vue/composition-api';

import { useListIntegrationsQuery } from '@/graphql/types';

export default function useIntegrations() {
  const { result, refetch, error } = useListIntegrationsQuery();
  const integrations = computed(() => result.value?.tenant.integrations ?? []);

  return {
    integrations,
    refetch,
    error,
  };
}
