import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import TimeAgo from '@/components/TimeAgo/TimeAgo';
import MarkdownView from '@/components/MarkdownView/MarkdownView.vue';

import Media from '../Media/Media';
import type { Post } from './Post.types';

export default Vue.extend({
  name: 'SldsPost',
  functional: true,
  props: {
    post: {
      type: Object as PropType<Post>,
      required: true,
    },
  },
  render(h, { props, data, scopedSlots }) {
    const { post } = props;
    const content = post.content ?? '';
    return h('article', mergeData(data, { staticClass: 'slds-post' }), [
      h(Media, {
        props: { tag: 'header' },
        staticClass: 'slds-post__header',
        class: { 'slds-m-bottom_none': content === '' },
        scopedSlots: {
          figure: () => scopedSlots.avatar({ item: post }),
          body: () => [
            h('div', undefined, post.author ?? 'Unknown'),
            h(TimeAgo, { props: { since: post.updatedAt } }),
          ],
        },
      }),
      content !== ''
        ? h(MarkdownView, {
            props: { value: post.content },
            staticClass: 'slds-post__content slds-text-longform',
          })
        : null,
    ]);
  },
});
