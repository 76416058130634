const LOCAL_KEY = 'io.bluecanvas.webapp.whatsnew';

interface LocalData {
  lastReadTime?: Date;
  likedIds?: string[];
}

export function readLocalStorage(): LocalData {
  const encoded = localStorage.getItem(LOCAL_KEY);
  if (!encoded) {
    return {};
  }
  const data: LocalData = JSON.parse(encoded, (key, value) =>
    key === 'lastReadTime' && value ? new Date(value) : value
  );
  return data;
}

export function writeLocalStorage(data: LocalData): void {
  localStorage.setItem(LOCAL_KEY, JSON.stringify(data));
}

export function removeLocalStorage(): void {
  localStorage.removeItem(LOCAL_KEY);
}
