<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsRecordRow',
  functional: true,
  render(h, { data, children }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'slds-form__row' }),
      children
    );
  },
});
</script>
