import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsExpressionRow',
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children }) {
    return h('li', mergeData(data, { staticClass: 'slds-expression__row' }), [
      h('fieldset', undefined, [
        h(
          'legend',
          {
            staticClass: 'slds-expression__legend',
          },
          [
            h(
              'span',
              { class: { 'slds-assistive-text': props.noTitle } },
              props.title
            ),
          ]
        ),
        h('div', { staticClass: 'slds-grid slds-gutters_xx-small' }, children),
      ]),
    ]);
  },
});
