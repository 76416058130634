import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsPageHeaderRow',
  functional: true,
  props: {
    gutters: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, slots }) {
    const { title = null, actions = null, details = null } = slots();
    return h(
      'div',
      mergeData(data, {
        staticClass: 'slds-page-header__row',
        class: { 'slds-page-header__row_gutters': props.gutters },
      }),
      [
        title !== null
          ? h('div', { staticClass: 'slds-page-header__col-title' }, title)
          : null,
        actions !== null
          ? h('div', { staticClass: 'slds-page-header__col-actions' }, [
              h(
                'div',
                { staticClass: 'slds-page-header__controls' },
                actions.map((action) =>
                  h(
                    'div',
                    {
                      staticClass: 'slds-page-header__control',
                    },
                    [action]
                  )
                )
              ),
            ])
          : null,
        details !== null
          ? h('div', { staticClass: 'slds-page-header__col-details' }, [
              h('ul', { staticClass: 'slds-page-header__detail-row' }, details),
            ])
          : null,
      ]
    );
  },
});
