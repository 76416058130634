import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'ActivityTimeline',
  functional: true,
  render(h, { data, children }) {
    return h('ul', mergeData(data, { staticClass: 'slds-timeline' }), children);
  },
});
