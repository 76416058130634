import type { Ref } from '@vue/composition-api';
import { computed } from '@vue/composition-api';

export default function useModal(
  prop: Ref<boolean>,
  emit: (event: 'input', value: boolean) => void
) {
  const open = computed({
    get: () => prop.value,
    set: (v) => {
      emit('input', v);
    },
  });

  function show() {
    open.value = true;
  }

  function hide() {
    open.value = false;
  }

  return {
    open,
    show,
    hide,
  };
}
