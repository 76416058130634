<template>
  <div class="slds-col slds-grid slds-grid_vertical slds-nowrap">
    <div
      class="slds-p-vertical_x-small slds-p-horizontal_large slds-shrink-none slds-theme_shade"
    >
      <slds-combo-box
        v-model="localSelected"
        lookup
        :items="items"
        label="search"
        hide-label
        :option-label="optionLabel"
        :option-value="optionValue"
        hide-selected
        :disabled-fn="isDisabled"
      />
      <bc-pill-container label="Selected Options:">
        <slds-pill
          v-for="item in selectedItems"
          :key="item[optionValue]"
          :removable="isDisabled(item) === false"
          @remove="removeFromSelected(item)"
          >{{ item[optionLabel] }}</slds-pill
        >
      </bc-pill-container>
      <div class="slds-text-title slds-m-top_x-small">
        {{ localSelected.length }} Item(s) Selected
      </div>
    </div>
    <div class="slds-scrollable slds-grow">
      <div class="slds-scrollable_none">
        <slds-table
          ref="table"
          v-bind="$attrs"
          :items="items"
          :columns="computedColumns"
          :selected="localSelected"
          :selected-identity="selectedIdentityFn"
          no-selection-controls
          v-on="computedListeners"
        >
          <template #cell:selection="{ item, selected, index }"
            ><slds-checkbox-button
              :checked="selected"
              title="Toggle Selection"
              :disabled="isDisabled(item)"
              @change="onSelectionChange(item, $event, index)"
          /></template>
          <template v-for="(_, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
          </template>
        </slds-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithRefs } from 'vue-typed-refs';
import { isEqual } from 'lodash-es';

import type { VueComponentListeners } from '../types';

import SldsTable from '../Table/Table';
import TableColumn from '../Table/TableColumn';
import CustomColumn from '../Table/CustomColumn';
import type { TableItem, SelectedIdentityFn } from '../Table/Table.types';
import SldsComboBox from '../ComboBox/ComboBox.vue';
import SldsCheckboxButton from '../CheckboxButton/CheckboxButton';
import SldsPill from '../Pill.vue';
import BcPillContainer from '../../BcPillContainer/BcPillContainer.vue';

import type { Events } from './ListBuilder.types';

type Refs = {
  table: InstanceType<typeof SldsTable>;
};

export default (Vue as WithRefs<Refs, WithEvents<Events>>).extend({
  name: 'SldsListBuilder',
  components: {
    SldsTable,
    SldsComboBox,
    SldsCheckboxButton,
    SldsPill,
    BcPillContainer,
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Array as PropType<TableItem[]>,
      required: true,
    },
    columns: {
      type: Array as PropType<Array<TableColumn>>,
      required: true,
    },
    selected: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedIdentityFn: {
      type: Function as PropType<SelectedIdentityFn>,
      required: true,
    },
    optionValue: {
      type: String,
      required: true,
    },
    optionLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localSelected: this.selected,
    };
  },
  computed: {
    computedColumns(): Array<TableColumn | CustomColumn> {
      return [
        new CustomColumn({ key: 'selection', width: '3.75rem' }),
        ...this.columns,
      ];
    },
    computedListeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        select: (payload: string[]) => {
          this.localSelected = payload;
        },
      };
    },
    selectedItems(): TableItem[] {
      return this.items.filter((item) => this.isSelected(item));
    },
    dirty(): boolean {
      const local = new Set(this.localSelected);
      const selected = new Set(this.selected);
      return isEqual(local, selected) === false;
    },
  },
  watch: {
    dirty(value) {
      this.$emit('update:dirty', value);
    },
  },
  methods: {
    /** @public */
    apply() {
      this.$emit('input', this.localSelected);
      return this.localSelected;
    },
    /** @public */
    selectAll() {
      this.$refs.table.selectAll();
    },
    /** @public */
    addToSelected(identities: string[]) {
      this.localSelected = [...new Set(this.localSelected.concat(identities))];
    },
    /** @public */
    removeFromSelected(item: TableItem) {
      const identity = this.selectedIdentityFn(item);
      this.localSelected = this.localSelected.filter((i) => i !== identity);
    },
    onSelectionChange(item: TableItem, value: boolean, index: number) {
      this.$refs.table.selectionHandler(value, item, index);
    },
    isSelected(item: TableItem) {
      return this.localSelected.includes(this.selectedIdentityFn(item));
    },
    isDisabled(item: TableItem) {
      return this.selected.includes(this.selectedIdentityFn(item));
    },
  },
});
</script>
