import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsDockedFormFooter',
  functional: true,
  render(h, { data, children }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'slds-docked-form-footer' }),
      children
    );
  },
});
