import type { UnwrapSet } from '@/types';
import { Options as PopperOptions } from '@/mixins/popper';

import InputComponent from '../Input.vue';
import ModalComponent from '../Modal.vue';

export { NUBBIN } from './nubbin';

export type Undefined<T> = T | undefined;
export type Nullable<T> = T | null;
export type Maybe<T> = T | null | undefined;
export type Pixels = number;

type Listener = (...args: any[]) => void;
export type VueComponentListeners = Record<string, Listener | Listener[]>;

export type Input = InstanceType<typeof InputComponent>;
export type Modal = InstanceType<typeof ModalComponent>;

export type MenuOptions = Partial<PopperOptions>;

export type { Placement } from '@/mixins/popper';

export const SLDS_STATUS = new Set(['error', 'warning', 'success'] as const);
export type SldsStatus = UnwrapSet<typeof SLDS_STATUS>;
