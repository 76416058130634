import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import ExpressionTitle from './ExpressionTitle';

export default Vue.extend({
  name: 'SldsExpression',
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children }) {
    return h('div', mergeData(data, { staticClass: 'slds-expression' }), [
      h(
        ExpressionTitle,
        {
          class: { 'slds-assistive-text': props.noTitle },
        },
        props.title
      ),
      h('ul', undefined, children),
    ]);
  },
});
