<template>
  <div :id="id">
    <div class="slds-welcome-mat__info-badge-container">
      <img
        class="slds-welcome-mat__info-badge"
        :src="image"
        width="50"
        height="50"
        alt=""
      />
      <slds-icon
        category="action"
        name="check"
        :title="completedAssistiveText"
      />
    </div>

    <slot />

    <template v-if="isComplete">
      this.props.onCompleteRenderActions()
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

/**
 * InfoBadge component item represents a tile in a Welcome Mat
 *
 * @slot default
 */
export default Vue.extend({
  props: {
    /**
     *  **Assistive text for accessibility.**
     * * `completed` : For users of assistive technology, assistive text for completed icon.
     */
    assistiveText: {
      type: Object as PropType<{ completed: string }>,
    },
    /**
     * HTML id for component.
     */
    id: {
      type: String,
    },
    /**
     * Icon for the tile
     */
    image: {
      type: String,
    },
    /**
     * Whether the trail is completed
     */
    isComplete: {
      type: Boolean,
    },
    /**
     * Actions to be rendered on completion of the trail
     */
    // onCompleteRenderActions: PropTypes.func,
  },
  computed: {
    completedAssistiveText(): string {
      return (
        (this.assistiveText && this.assistiveText.completed) || 'Completed'
      );
    },
  },
});
</script>
