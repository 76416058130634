export enum DeploymentTab {
  DISCUSSION = 'discussion',
  REVIEWS = 'reviews',
  FILES = 'files',
  STORIES = 'stories',
  PERMISSIONS = 'permissions',
  PERMISSION_SETS = 'permission-sets',
  PROFILES = 'profiles',
  DETAILS = 'details',
}
