import type { TableItem } from './Table.types';

import type { TableColumnOptions } from './TableColumn';
import TableColumn from './TableColumn';

export enum EditableType {
  SELECT = 'SELECT',
}

export type Editable = {
  type: EditableType.SELECT;
  options: { value: unknown; label: string }[];
};

export interface EditableTableColumnOptions<T extends TableItem, U>
  extends TableColumnOptions<T, U> {
  editable: Editable;
}

export default class EditableTableColumn<
  T extends TableItem = TableItem,
  U = unknown,
> extends TableColumn<T, U> {
  public readonly editable: Editable;
  constructor(options: EditableTableColumnOptions<T, U>) {
    super(options);
    this.editable = options.editable;
  }
}
