import './style/slds.scss';
import './assets/scss/main.scss';
import './style/tailwind.scss';

import Vue from 'vue';
import VueCompositionAPI, { h, computed, provide } from '@vue/composition-api';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import PortalVue from 'portal-vue';
import VueApollo from '@vue/apollo-option';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueAnalytics from 'vue-analytics';
import { ApolloError } from '@apollo/client/errors';
import {
  init,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/vue';

import { useGetTenantQuery } from '@/graphql/types';
import AuthContainer from '@/module/auth/AuthContainer.vue';

import defaultErrorHandler from './util/error-handler';
import router from './router';
import { SENTRY_DSN } from '../conf';
import ToastPlugin from './plugins/toast';
import InfoPlugin from './plugins/info';

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

Vue.use(VueCompositionAPI);
Vue.use(Router);
Vue.use(VueApollo);
Vue.use(VueMeta);
Vue.use(PortalVue);

Vue.use(ToastPlugin);
Vue.use(InfoPlugin);

Vue.config.productionTip = false;

// Load SLDS global components

function autoloader(
  requireFn: __WebpackModuleApi.RequireContext,
  namePrefix = ''
) {
  return function autoload(filename: string) {
    let config = requireFn(filename);
    config = config.default || config;
    const name =
      (config.options && config.options.name) ||
      namePrefix + filename.replace(/^.+\//, '').replace(/\.\w+$/, '');
    Vue.component(name, config);
  };
}

const requireSLDS = require.context(
  '@/components/slds',
  true,
  /(^|\/)[A-Z].*\.vue$/
);

requireSLDS.keys().forEach(autoloader(requireSLDS, 'Slds'));

if (
  process.env.NODE_ENV === 'production' &&
  window.CONFIG.GOOGLE_ANALYTICS_ID !== undefined
) {
  Vue.use(VueAnalytics, {
    id: window.CONFIG.GOOGLE_ANALYTICS_ID,
    router,
  });
}

if (process.env.NODE_ENV !== 'development') {
  init({
    Vue,
    dsn: SENTRY_DSN,
    release: process.env.RELEASE_NAME,
    environment: process.env.NODE_ENV,
    integrations: [browserTracingIntegration({ router }), replayIntegration()],
    tracesSampleRate: 0.1,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/[a-z]+\.my\.bluetesting\.io\//,
      /^https:\/\/[a-z]+\.my\.bluecanvas\.io\//,
    ],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

import App from './App.vue';
import apolloClient from './apolloClient';
import { useProfile } from './composables';

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      error: (e) => {
        // Error and ApolloError are thrown at the same time
        if (e instanceof ApolloError) {
          defaultErrorHandler(e);
        }
      },
    },
  },
});

new Vue({
  el: 'body',
  router,
  apolloProvider,
  setup() {
    provide(DefaultApolloClient, apolloClient);
    const { result } = useGetTenantQuery();
    const { profile } = useProfile();
    const tenant = computed(() => result.value?.tenant ?? null);
    return () => {
      if (tenant.value === null) {
        return null;
      }
      if (profile.value === null) {
        return null;
      }
      return h(
        AuthContainer,
        {
          props: { tenant: tenant.value, profile: profile.value },
        },
        [h(App)]
      );
    };
  },
});

const originalErrorHandler = Vue.config.errorHandler;

Vue.config.errorHandler = function errorHandler(...args) {
  defaultErrorHandler(args[0]);
  if (originalErrorHandler !== null) {
    originalErrorHandler(...args);
  }
  if (process.env.NODE_ENV === 'development') {
    console.error(...args);
  }
};

window.addEventListener('error', (e) => {
  if (e.error instanceof ApolloError) {
    defaultErrorHandler(e.error);
  }
});
