import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsPageHederDetailBlock',
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data, children }) {
    return h(
      'li',
      mergeData(data, { staticClass: 'slds-page-header__detail-block' }),
      [
        h('div', { staticClass: 'slds-text-title slds-truncate' }, props.title),
        children,
      ]
    );
  },
});
