<template>
  <ProgressRingShape
    v-bind="{
      fillPercentDecimal,
      flowDirection,
      size,
    }"
    :class="[
      themeClass,
      {
        'slds-progress-ring_large': size === 'large',
      },
    ]"
  >
    <slot>
      <slds-icon
        v-if="theme === 'warning'"
        category="utility"
        name="warning"
        title="Warning"
      />
      <slds-icon
        v-if="theme === 'expired'"
        category="utility"
        name="error"
        title="Expired"
      />
      <slds-icon
        v-if="theme === 'complete'"
        category="utility"
        name="check"
        title="Complete"
      />
    </slot>
  </ProgressRingShape>
</template>

<script lang="ts">
import Vue from 'vue';

import ProgressRingShape from './ProgressRingShape.vue';

const THEME_CLASSES: { [theme: string]: string } = {
  active: 'slds-progress-ring_active-step',
  warning: 'slds-progress-ring_warning',
  expired: 'slds-progress-ring_expired',
  complete: 'slds-progress-ring_complete',
};

export default Vue.extend({
  name: 'SldsProgressRing',
  components: {
    ProgressRingShape,
  },
  props: {
    theme: {
      type: String,
      validator(val: string) {
        return ['active', 'warning', 'expired', 'complete'].includes(val);
      },
      default: undefined,
    },
    value: {
      type: Number,
      required: true,
    },
    flowDirection: {
      type: String,
      validator(val: string) {
        return ['drain', 'fill'].includes(val);
      },
      default: 'drain',
    },
    size: {
      type: String,
      validator(val: string) {
        return ['medium', 'large'].includes(val);
      },
      default: 'medium',
    },
  },
  computed: {
    fillPercentDecimal(): number {
      return this.value / 100;
    },
    themeClass(): string {
      return THEME_CLASSES[this.theme] || '';
    },
  },
});
</script>

<style lang="scss">
@use '../../../node_modules/@salesforce-ux/design-system/design-tokens/dist/components.default'
  as components;

.slds-progress-ring_complete .slds-progress-ring__path {
  fill: components.$progress-bar-color-background-fill-success;
}
.slds-progress-ring_complete .slds-progress-ring__content {
  background: components.$progress-bar-color-background-fill-success;
}
</style>
