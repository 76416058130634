import Vue, { VNode } from 'vue';
import type { WithProperties } from 'vue-typed-properties';

import { hasSlot, normalizeSlot, generateId } from '@/util';

import Button from '../Button.vue';
import BaseIcon from '../private/BaseIcon.vue';

import Accordion from './Accordion';

type A = InstanceType<typeof Accordion>;

export default (Vue as WithProperties<{ index: number; accordion: A }>).extend({
  name: 'SldsAccordionItem',
  inject: ['accordion'],
  props: {
    summary: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      default() {
        return generateId();
      },
    },
    contentClassName: {
      type: [Object, Array, String],
      default: undefined,
    },
    contentAttrs: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localIsOpen: false,
    };
  },
  computed: {
    isOpen(): boolean {
      if (this.accordion.value === null) {
        return this.localIsOpen;
      }

      return this.accordion.isItemExpanded(this.index);
    },
  },
  created() {
    if (this.accordion.value === null) {
      return;
    }
    const index = this.accordion.addItem(this);
    this.index = index;
    if (this.accordion.value.includes(index)) {
      this.localIsOpen = true;
    }
  },
  methods: {
    toggle() {
      if (this.accordion.value === null) {
        this.localIsOpen = !this.localIsOpen;
        return;
      }

      this.accordion.toggleItem(this.index);
    },
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    return h('li', { staticClass: 'slds-accordion__list-item' }, [
      h(
        'section',
        {
          staticClass: 'slds-accordion__section',
          class: { 'slds-is-open': this.isOpen },
        },
        [
          h('div', { staticClass: 'slds-accordion__summary' }, [
            h('h2', { staticClass: 'slds-accordion__summary-heading' }, [
              h(
                Button,
                {
                  props: {
                    variant: 'base',
                  },
                  attrs: {
                    title: this.summary,
                    'aria-controls': this.uid,
                    'aria-expanded': String(this.isOpen),
                  },
                  staticClass:
                    'slds-button_reset slds-accordion__summary-action',
                  nativeOn: {
                    click: this.toggle,
                  },
                },
                [
                  h(BaseIcon, {
                    props: { category: 'utility', name: 'switch' },
                    staticClass:
                      'slds-accordion__summary-action-icon slds-button__icon slds-button__icon_left',
                  }),
                  h(
                    'span',
                    { staticClass: 'slds-accordion__summary-content' },
                    this.hasSlot('summary')
                      ? this.normalizeSlot('summary', { isOpen: this.isOpen })
                      : this.summary
                  ),
                ]
              ),
            ]),
            this.normalizeSlot('actions'),
          ]),
          h(
            'div',
            {
              attrs: {
                ...this.contentAttrs,
                id: this.uid,
                hidden: this.isOpen === false,
              },
              class: this.contentClassName,
              staticClass: 'slds-accordion__content',
            },
            this.$slots.default
          ),
        ]
      ),
    ]);
  },
});
