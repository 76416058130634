<template>
  <span v-bind="$attrs" class="slds-pill" v-on="$listeners">
    <span class="slds-pill__label"><slot></slot></span>
    <slds-icon
      v-if="removable"
      category="utility"
      name="close"
      title="Remove"
      container
      class="slds-pill__remove"
      @click="remove"
    />
  </span>
</template>

<script lang="ts">
import Vue from 'vue';
import type { WithEvents } from 'vue-typed-emit';

import SldsIcon from './Icon.vue';

export interface Events {
  remove: undefined;
}

export default (Vue as WithEvents<Events>).extend({
  name: 'SldsPill',
  components: {
    SldsIcon,
  },
  inheritAttrs: false,
  props: {
    removable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
  },
});
</script>
