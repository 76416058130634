<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';

const ALIGNMENTS = ['start', 'center', 'end', 'space', 'spread'] as const;
const VERTICAL_ALIGNMENTS = ['start', 'center', 'end'] as const;

type Align = (typeof ALIGNMENTS)[number];
type VerticalAlign = (typeof VERTICAL_ALIGNMENTS)[number];

export default Vue.extend({
  name: 'SldsGrid',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    gutters: {
      type: Boolean,
      default: true,
    },
    gutter: {
      type: String,
      validator(val: string) {
        return [
          'xxx-small',
          'xx-small',
          'x-small',
          'small',
          'medium',
          'large',
          'x-large',
          'xx-large',
        ].includes(val);
      },
      default: undefined,
    },
    align: {
      type: String as PropType<Align>,
      default: undefined,
      validator: (val) => ALIGNMENTS.includes(val),
    },
    verticalAlign: {
      type: String as PropType<VerticalAlign>,
      default: undefined,
      validator: (val) => VERTICAL_ALIGNMENTS.includes(val),
    },
  },
  render(h) {
    return h(
      this.tag,
      {
        staticClass: 'slds-grid',
        class: [
          {
            [this.gutter ? `slds-gutters_${this.gutter}` : 'slds-gutters']:
              this.gutters,
            [`slds-grid_vertical-align-${this.verticalAlign}`]:
              this.verticalAlign,
            [`slds-grid_align-${this.align}`]: this.align,
          },
        ],
      },
      this.$slots.default
    );
  },
});
</script>
