import Vue from 'vue';

export default Vue.extend({
  name: 'SldsNotifyContainer',
  render(h) {
    return h(
      'div',
      { staticClass: 'slds-notify-container' },
      this.$slots.default
    );
  },
});
