import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsFeed',
  functional: true,
  render(h, { data, children }) {
    return h('div', mergeData(data, { staticClass: 'slds-feed' }), [
      h(
        'ul',
        { staticClass: 'slds-feed__list' },
        children.map((child) =>
          h('li', { key: child.key, staticClass: 'slds-feed__item' }, [child])
        )
      ),
    ]);
  },
});
