import type { PropType, VNode } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithRefs } from 'vue-typed-refs';
import Vue from 'vue';

import Select from '../Select.vue';
import type { TableEditable } from './Table.types';

interface Events {
  input: [value: unknown, editable: TableEditable];
  cancel: undefined;
}

type Refs = {
  select: InstanceType<typeof Select> & { focus(): void };
};

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: 'TablePopoverSelect',
  props: {
    editable: {
      type: Object as PropType<TableEditable>,
      required: true,
    },
  },
  mounted() {
    this.$refs.select.focus();
  },
  methods: {
    input(value: unknown) {
      this.$emit('input', value, this.editable);
    },
    cancel() {
      this.$emit('cancel');
    },
    onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.cancel();
      }
    },
  },
  render(h): VNode {
    return h(Select, {
      ref: 'select',
      props: {
        value: this.editable.column.getter(this.editable.item),
        label: this.editable.column.title,
        hideLabel: true,
        options: this.editable.column.editable.options,
      },
      on: {
        input: this.input,
        blur: this.cancel,
      },
      nativeOn: {
        keydown: this.onKeydown,
      },
    });
  },
});
