import TurndownService from 'turndown';

import { MENTION_CLASS_NAME } from '@/module/rte/rte.constants';

const MAILTO = 'mailto:';

export default class HTMLMarkdownConverter {
  private service: TurndownService;

  constructor() {
    this.service = new TurndownService({
      headingStyle: 'atx',
      codeBlockStyle: 'fenced',
      /**
       * Allows to have italicized part of a word.
       * Ap<em>pl</em>e is converted to Ap*pl*e.
       * Without this option it would be Ap_pl_e,
       * which is not converted to HTML properly
       * (it stays Ap_pl_e instead of Ap<em>pl</em>e)
       * by any Markdown to HTML converter.
       */
      emDelimiter: '*',
      bulletListMarker: '-',
      br: '<br>',
    });

    this.service.addRule('input', {
      filter: ['input'],
      replacement(_, node) {
        return (node as HTMLInputElement).checked ? '[x] ' : '[ ] ';
      },
    });

    this.service.addRule('strikethrough', {
      filter: ['del', 's'],
      replacement(content) {
        return `~~${content}~~`;
      },
    });

    this.service.addRule('mention', {
      filter: ['span', 'a'],
      replacement(content, node) {
        if (node instanceof HTMLSpanElement) {
          const data = node.dataset;
          if (data.type !== 'mention') return content;
          if (data.id === undefined) return content;
          return `@${data.id}`;
        }
        if (node instanceof HTMLAnchorElement) {
          if (node.classList.contains(MENTION_CLASS_NAME) === false)
            return content;
          if (node.href.startsWith(MAILTO) === false) return content;
          const email = node.href.substring(MAILTO.length);
          return `@${email}`;
        }
        return content;
      },
    });
  }

  convert(html: string): string {
    // empty paragraphs are converted to `\n\n`, which is ignored by CommonMark
    const normalized = html.replace(/<p><\/p>/gm, '<br>');
    const result = this.service.turndown(normalized);
    return result;
  }
}
