<template>
  <body>
    <SldsAlert v-if="isAppVersionOutdated" variant="warning"
      >There's a new version available! To ensure an optimal experience, please
      <a href="">refresh the page</a>.</SldsAlert
    >
    <header
      class="slds-global-header_container"
      :class="{ '--shifted': isAppVersionOutdated }"
    >
      <a href="#nav" class="slds-assistive-text slds-assistive-text_focus"
        >Skip to Navigation</a
      >
      <a href="#main" class="slds-assistive-text slds-assistive-text_focus"
        >Skip to Main Content</a
      >
      <GlobalHeader id="header">
        <template #navigation>
          <RouterView
            class="webapp-view__global-navigation"
            name="navigation"
          />
        </template>
      </GlobalHeader>
    </header>
    <RouterView />
    <PortalTarget name="slds-modal" transition />
    <PortalTarget name="slds-menu" multiple />
    <PortalTarget name="popover" />
    <PortalTarget name="slds-tooltip" multiple />
  </body>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';
import type { MetaInfo } from 'vue-meta';
import { PortalTarget } from 'portal-vue';

import { SldsAlert } from '@ui';
import { useAppVersion } from '@/composables';

import GlobalHeader from './components/GlobalHeader.vue';

export default defineComponent({
  name: 'App',
  components: {
    SldsAlert,
    PortalTarget,
    GlobalHeader,
  },
  setup() {
    const { isOutdated: isAppVersionOutdated } = useAppVersion();
    return {
      isAppVersionOutdated,
    };
  },
  metaInfo(): MetaInfo {
    return {
      titleTemplate: (chunk) => {
        return chunk ? `${chunk} - Blue Canvas` : 'Blue Canvas';
      },
    };
  },
});
</script>

<style lang="scss">
@use './style/variables';

html,
body {
  overflow: hidden;
}

#main {
  overflow-y: auto;
}

.slds-dropdown-trigger {
  display: inline-flex;
}

// General adjustments
code,
kbd,
pre,
samp {
  font-family: variables.$font-family-monospace;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.slds-global-header_container {
  &.--shifted {
    transform: translateY(36px);
  }
}
</style>
