export enum IconType {
  Utility = 'utility',
  Doctype = 'doctype',
  Standard = 'standard',
  Custom = 'custom',
  Action = 'action',
}

export enum IconSize {
  XXSmall = 'xx-small',
  XSmall = 'x-small',
  Small = 'small',
  Large = 'large',
}

export enum IconColor {
  Default = 'default',
  Current = 'current',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  light = 'light',
}
