import { computed } from '@vue/composition-api';

import { useGetBranchesQuery } from '@/graphql/types';
import { extractNode } from '@/util';
import { usePreferredBranch } from '@/module/branch';

export default function useBranches() {
  const { result, loading } = useGetBranchesQuery({}, { errorPolicy: 'all' });
  const { get } = usePreferredBranch();
  const branches = computed(
    () => result.value?.branches.edges.map(extractNode) ?? []
  );
  const preferred = computed(() => get(branches.value));
  return {
    branches,
    preferred,
    loading,
  };
}
