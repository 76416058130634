<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'VerticalNavigation',
  functional: true,
  props: {
    label: {
      type: String,
      default: 'Navigation',
    },
  },
  render(h, { props, data, children }) {
    return h(
      'nav',
      mergeData(data, {
        attrs: { 'aria-label': props.label },
        staticClass: 'slds-nav-vertical',
      }),
      children
    );
  },
});
</script>
