<template>
  <slds-button
    v-if="variant === 'button'"
    v-bind="{
      variant: 'icon',
      iconName: name,
      iconClassName: 'slds-icon-text-light',
      ...$attrs,
    }"
    :class="[
      'slds-input__icon',
      { [`slds-input__icon_${position}`]: position },
    ]"
    v-on="$listeners"
  />

  <span
    v-else-if="variant === 'combobox' || variant === 'lookup'"
    class="slds-icon_container slds-input__icon slds-input__icon_right"
  >
    <base-icon
      v-bind="{
        category: 'utility',
        name: variant === 'combobox' ? 'down' : 'search',
        size: 'x-small',
        colorVariant: 'default',
        ...$attrs,
      }"
      class="slds-icon"
    />
  </span>

  <base-icon
    v-else
    v-bind="{
      category: 'utility',
      name,
      ...$attrs,
    }"
    color-variant="default"
    :class="[
      'slds-icon',
      'slds-input__icon',
      { [`slds-input__icon_${position}`]: position },
    ]"
  />
</template>

<script lang="ts">
import Vue from 'vue';

import BaseIcon from './private/BaseIcon.vue';

/**
 * A wrapper for icons that will be rendered inside of an Input
 *
 * If the the variant is 'button', the `design-system-react/components/button` component is used.
 * If not, the icon will be an instance of `design-system-react/components/utilities/utility-icon`.
 * Checkout out the appropriate component to see what props can be passed along via the `{...$attrs}` rest operator
 *
 * @event click
 */
export default Vue.extend({
  components: {
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    /**
     * This is only needed if an input contains two icons, the Input component handles this prop for you.
     */
    position: {
      type: String,
      validator(val: string) {
        return ['left', 'right'].includes(val);
      },
    },
    /**
     * Name of the icon. Visit <a href='http://www.lightningdesignsystem.com/resources/icons'>Lightning Design System Icons</a> to reference icon names.
     */
    name: String,
    variant: {
      type: String,
      validator(val: string) {
        return ['base', 'button', 'combobox', 'lookup'].includes(val);
      },
      default: 'base',
    },
  },
});
</script>
