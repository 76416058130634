<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import SldsIcon from './Icon/Icon';

enum State {
  Complete = 'complete',
  Error = 'error',
}

export default Vue.extend({
  name: 'SldsWelcomeMatTileIcon',
  functional: true,
  props: {
    name: {
      type: String,
      required: true,
    },
    state: {
      type: String as PropType<State>,
      default: undefined,
      validator: (val: State) => Object.values(State).includes(val),
    },
  },
  render(h, { props, data }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'slds-welcome-mat__tile-icon-container' }),
      [
        h(SldsIcon, { props: { type: 'utility', name: props.name } }),
        props.state !== undefined
          ? h(SldsIcon, {
              props: {
                type: 'action',
                name: props.state === State.Error ? 'reject' : 'check',
                title: props.state === State.Error ? 'Error' : 'Completed',
              },
            })
          : null,
      ]
    );
  },
});
</script>

<style lang="scss">
@use '../../../node_modules/@salesforce-ux/design-system/design-tokens/dist/components.default'
  as components;
@use '../../style/variables';
@use 'sass:math';

.slds-welcome-mat {
  .slds-icon-action-check {
    border-color: components.$card-color-background;
  }

  .slds-icon-action-reject {
    position: absolute;
    bottom: -(math.div(components.$welcome-mat-complete-icon-size, 2));
    right: -(math.div(components.$welcome-mat-complete-icon-size, 2));
    height: components.$welcome-mat-complete-icon-size;
    width: components.$welcome-mat-complete-icon-size;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    border: variables.$border-width-thick solid
      components.$card-color-background;
    border-radius: components.$welcome-mat-complete-icon-size;
    line-height: 0;
    background: variables.$palette-red-60;

    .slds-icon {
      fill: variables.$color-text-inverse;
    }
  }
}
</style>
