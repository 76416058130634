import { ref, onMounted } from '@vue/composition-api';
import { useEventListener } from '@vueuse/core';

declare global {
  interface Window {
    BLUE_CANVAS_VERSION?: string;
  }
}

export default function useAppVersion() {
  const isOutdated = ref(false);
  const check = () => {
    if (process.env.NODE_ENV !== 'production') return;
    if (window.BLUE_CANVAS_VERSION === undefined) return;
    fetch('/_version')
      .then((response) => {
        if (response.ok === false) return undefined;
        return response.text();
      })
      .then((version) => {
        if (version === undefined) return;
        if (version !== window.BLUE_CANVAS_VERSION) {
          isOutdated.value = true;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };
  onMounted(check);
  useEventListener(document, 'online', check);
  useEventListener(document, 'visibilitychange', () => {
    if (document.visibilityState !== 'visible') return;
    check();
  });
  return { isOutdated };
}
