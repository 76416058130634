<template>
  <li class="slds-item slds-global-header__notification slds-p-top_medium">
    <article class="slds-post" style="background: none">
      <div class="slds-post__content slds-text-longform">
        <h3>
          <strong>{{ title }}</strong>
        </h3>
        <slot>
          <div v-html="safeHtml"></div>
        </slot>
      </div>
      <footer class="slds-post__footer">
        <ul class="slds-post__footer-actions-list slds-list_horizontal">
          <li class="slds-col slds-item slds-m-right_medium">
            <WhatsnewButtonLike
              :active="isLiked"
              :disabled="isLiked"
              @click="$emit('like')"
            />
          </li>
        </ul>
        <ul
          class="slds-post__footer-meta-list slds-list_horizontal slds-has-dividers_right slds-text-title"
        >
          <li class="slds-item">
            <abbr
              v-if="isUnread"
              class="slds-text-link slds-m-horizontal_xx-small"
              title="Unread Message"
              >●</abbr
            >
            <TimeAgo :since="createTime" />
          </li>
        </ul>
      </footer>
    </article>
  </li>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import TimeAgo from '@/components/TimeAgo/TimeAgo';

import WhatsnewButtonLike from './WhatsnewButtonLike.vue';

export default Vue.extend({
  components: {
    TimeAgo,
    WhatsnewButtonLike,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    safeHtml: {
      type: String,
    },
    createTime: {
      type: String,
      required: true,
    },
    lastReadTime: {
      type: Date as PropType<Date>,
    },
    likedIds: {
      type: Array as PropType<string[]>,
    },
  },
  computed: {
    isUnread(): boolean {
      if (!this.lastReadTime) return true;
      return new Date(this.lastReadTime) < new Date(this.createTime);
    },
    isLiked(): boolean {
      if (!this.likedIds) return false;
      return this.likedIds.includes(this.id);
    },
  },
});
</script>

<style lang="scss" scoped>
.slds-post__content ::v-deep {
  img {
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  code {
    padding: 2px 3px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: rgba(0, 0, 0, 0.025);
    border-radius: 3px;
  }
}
</style>
