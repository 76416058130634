var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'slds-form-element',
    {
      'slds-has-error': _vm.error,
      'slds-form-element_stacked': _vm.stacked,
      'slds-form-element_readonly': _vm.isStatic,
    },

    _vm.containerClassName ],style:(_vm.containerStyle)},[_c('slds-utility-label',_vm._b({class:{ 'slds-assistive-text': _vm.noLabel }},'slds-utility-label',{
      assistiveText: _vm.assistiveText,
      htmlFor: _vm.isStatic ? undefined : _vm.id,
      label: _vm.label,
      required: _vm.required,
      variant: _vm.isStatic ? 'static' : 'base',
    },false)),(_vm.$slots.fieldLevelHelpTooltip && _vm.hasRenderedLabel)?_c('slds-utility-tooltip-field-level-help',[_vm._t("fieldLevelHelpTooltip")],2):_vm._e(),_c('Input_InnerInput',_vm._g(_vm._b({ref:"input",scopedSlots:_vm._u([(_vm.hasSlot('icon-left'))?{key:"icon-left",fn:function(){return [_vm._t("icon-left")]},proxy:true}:null,(_vm.hasSlot('icon-right'))?{key:"icon-right",fn:function(){return [_vm._t("icon-right",null,{"classes":"slds-input__icon slds-input__icon_right"})]},proxy:true}:null,(_vm.hasSlot('addon-right'))?{key:"addon-right",fn:function(){return [_vm._t("addon-right")]},proxy:true}:null,(_vm.$slots.inlineEditTrigger)?{key:"inlineEditTrigger",fn:function(){return [_vm._t("inlineEditTrigger")]},proxy:true}:null],null,true)},'Input_InnerInput',Object.assign({}, _vm.$attrs,
      {'aria-describedby': _vm.errorId,
      assistiveText: _vm.assistiveText,
      containerClassName: 'slds-form-element__control',
      className: [
        { 'slds-input_counter': _vm.variant === 'counter' },
        { 'slds-p-horizontal_none': _vm.variant === 'counter' && _vm.readonly },
        _vm.inputClassName ],
      id: _vm.id,
      inputStyle: _vm.inputStyle,
      isStatic: _vm.isStatic,
      readonly: _vm.readonly,
      required: _vm.required,
      type: _vm.variant === 'counter' ? 'number' : _vm.type,
      value: _vm.value,
      variant: _vm.variant,
      placeholder: _vm.placeholder,
      clearable: _vm.clearable}),false),_vm.$listeners)),(_vm.inlineHelpText || _vm.$slots.inlineHelpText)?_c('div',{staticClass:"slds-form-element__help"},[_vm._t("inlineHelpText",function(){return [_vm._v(_vm._s(_vm.inlineHelpText))]})],2):_vm._e(),(_vm.error || _vm.$slots.error)?_c('div',{staticClass:"slds-form-element__help",attrs:{"id":_vm.errorId}},[_vm._t("error",function(){return [_vm._v(_vm._s(_vm.error))]})],2):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }