import Vue from 'vue';

import { SldsToast, SldsNotifyContainer } from '@ui';
import { ToastTheme } from '@/components/slds/Toast/Toast.types';

export default function toast(
  message: string | Error,
  props: Record<string, unknown> = {}
): void {
  const div = document.createElement('div');
  document.body.append(div);

  const instance = new Vue({
    render(h) {
      return h(SldsNotifyContainer, undefined, [
        h(SldsToast, {
          props: {
            ...props,
            heading: message instanceof Error ? message.message : message,
          },
          on: {
            input: () => {
              instance.$destroy();
              instance.$el.remove();
            },
          },
        }),
      ]);
    },
  }).$mount(div);
}

export type Toast = typeof toast;

toast.error = function error(...args: Parameters<Toast>) {
  const props = args[1] ?? {};
  props.theme = ToastTheme.Error;
  return toast(args[0], props);
};

toast.success = function success(...args: Parameters<Toast>) {
  const props = args[1] ?? {};
  props.theme = ToastTheme.Success;
  return toast(args[0], props);
};
