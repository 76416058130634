<template>
  <span :class="['slds-radio', { 'radio--block': block }]">
    <slot name="input" />
    <label class="slds-radio__label" :for="aFor">
      <span class="slds-radio_faux"></span>
      <component :is="block ? 'div' : 'span'" class="slds-form-element__label">
        <slot />
      </component>
    </label>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    for: String,
    /**
     * Sometimes we want a radio label to contain a block content
     */
    block: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      aFor: this.for,
    };
  },
  mounted() {
    if (this.$el.hasAttribute('for')) {
      this.aFor = this.$el.getAttribute('for')!;
      this.$el.removeAttribute('for');
    }
  },
});
</script>

<style lang="scss">
.radio {
  &--block {
    .slds-radio__label {
      display: flex;
    }

    .slds-radio_faux {
      flex-shrink: 0;
    }

    .slds-form-element__label {
      flex-grow: 1;
    }
  }
}
</style>
