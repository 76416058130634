import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { BadgeColor } from './Badge.types';

export default Vue.extend({
  name: 'SldsBadge',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'span',
    },
    color: {
      type: String as PropType<BadgeColor>,
      default: undefined,
      validator: (val) => Object.values(BadgeColor).includes(val),
    },
  },
  render(h, { props, data, slots, children }) {
    const s = slots();
    return h(
      props.tag,
      mergeData(data, {
        staticClass: 'slds-badge',
        class: {
          'slds-badge_inverse': props.color === BadgeColor.Inverse,
          'slds-badge_lightest': props.color === BadgeColor.Lightest,
          [`slds-theme_${props.color}`]:
            props.color !== undefined &&
            props.color !== BadgeColor.Inverse &&
            props.color !== BadgeColor.Lightest,
        },
      }),
      [
        s['icon-left'] !== undefined
          ? h(
              'span',
              { staticClass: 'slds-badge__icon slds-badge__icon_left' },
              s['icon-left']
            )
          : null,
        children,
        s['icon-right'] !== undefined
          ? h(
              'span',
              { staticClass: 'slds-badge__icon slds-badge__icon_right' },
              s['icon-right']
            )
          : null,
      ]
    );
  },
});
