<template>
  <div
    class="slds-scoped-notification slds-media slds-media_center"
    :class="[
      {
        [`slds-scoped-notification_${variant}`]: theme === undefined,
        [`slds-theme_${theme}`]: theme,
      },
    ]"
    role="status"
  >
    <div v-if="icon !== undefined" class="slds-media__figure">
      <slot name="icon">
        <SldsIcon
          type="utility"
          :name="icon"
          size="small"
          :color="theme !== undefined ? 'default' : undefined"
        />
      </slot>
    </div>
    <div class="slds-media__body">
      <p>
        <slot />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from '@vue/composition-api';
import { defineComponent, computed } from '@vue/composition-api';

import SldsIcon from '../Icon/Icon';
import { SldsScopedNotificationTheme } from './ScopedNotification.types';

export default defineComponent({
  name: 'SldsScopedNotification',
  components: {
    SldsIcon,
  },
  props: {
    theme: {
      type: String as PropType<SldsScopedNotificationTheme>,
      validator: (val: SldsScopedNotificationTheme) =>
        Object.values(SldsScopedNotificationTheme).includes(val),
      default: undefined,
    },
    variant: {
      type: String,
      validator: (val: string) => {
        return ['light', 'dark'].includes(val);
      },
      default: 'light',
    },
  },
  setup(props) {
    const icon = computed(() => props.theme);
    return { icon };
  },
});
</script>
