import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import ButtonIconIcon from './ButtonIconIcon';
import {
  ButtonIconVariant,
  ButtonIconSize,
  ButtonIconIconSize,
} from './ButtonIcon.types';

export default Vue.extend({
  name: 'SldsButtonIcon',
  functional: true,
  props: {
    variant: {
      type: String as PropType<ButtonIconVariant>,
      default: undefined,
      validator: (val) => Object.values(ButtonIconVariant).includes(val),
    },
    size: {
      type: String as PropType<ButtonIconSize>,
      default: undefined,
      validator: (val) => Object.values(ButtonIconSize).includes(val),
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    dropdown: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [Object, String],
    },
  },
  render(h, { props, data, children }) {
    let tag = 'button';

    if (props.href) {
      tag = 'a';
    }

    if (props.to) {
      tag = 'router-link';
    }

    const d = {
      props: tag === 'router-link' ? { to: props.to } : undefined,
      attrs: {
        title: props.title,
        disabled: props.disabled,
        href: props.href,
        type: tag === 'button' ? 'button' : undefined,
      },
      staticClass: 'slds-button slds-button_icon',
      class: {
        [`slds-button_icon-${props.variant}`]: props.variant !== undefined,
        'slds-button_icon-error': props.error,
        'slds-button_icon-warning': props.warning,
        [`slds-button_icon-${props.size}`]: props.size !== undefined,
        'slds-button_icon-more': props.dropdown,
        'slds-is-selected': props.selected,
      },
    };

    if (tag === 'router-link') {
      data.nativeOn = data.on;
      delete data.on;
    }

    return h(tag, mergeData(data, d), [
      children,
      props.dropdown
        ? h(ButtonIconIcon, {
            props: { name: 'down', size: ButtonIconIconSize.XSmall },
          })
        : null,
      h('span', { staticClass: 'slds-assistive-text' }, props.title),
    ]);
  },
});
