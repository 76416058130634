<template>
  <slds-popover
    placement="bottom-end"
    clickable
    size="large"
    header="What's New in Blue Canvas"
    body-class-name="slds-p-around_none"
    @close="markAsRead"
  >
    <template #activator="{ on }">
      <WhatsnewButton v-bind="{ changelogs, lastReadTime }" v-on="on" />
    </template>
    <WhatsnewPopover
      v-bind="{ changelogs, gqlError, lastReadTime, likedIds }"
      @like="like"
    />
  </slds-popover>
</template>

<script lang="ts">
import {
  ChangelogFragment,
  LikeChangelogMutation,
  LikeChangelogMutationVariables,
} from '@/graphql/types';

import ApolloConsumer from '@/ApolloConsumer.vue';

import { readLocalStorage, writeLocalStorage } from './whatsnewLocalStorage';

import WhatsnewButton from './WhatsnewButton.vue';
import WhatsnewPopover from './WhatsnewPopover.vue';

export default ApolloConsumer.extend({
  components: {
    WhatsnewButton,
    WhatsnewPopover,
  },
  data() {
    return {
      shouldShowPopover: false,
      changelogs: [] as ChangelogFragment[],
      likedIds: [] as string[],
      lastReadTime: undefined as Date | undefined,
    };
  },
  methods: {
    async like({ id }: { id: string }): Promise<void> {
      this.likedIds = [...this.likedIds, id];
      await this.mutate<LikeChangelogMutation, LikeChangelogMutationVariables>({
        mutation: require('./schema/likeChangelog.graphql'),
        variables: { id },
      });
      this.writeStorage();
    },

    markAsRead(): void {
      this.lastReadTime = new Date();
      this.writeStorage();
    },

    readStorage(): void {
      const { likedIds, lastReadTime } = readLocalStorage();
      this.likedIds = likedIds || [];
      this.lastReadTime = lastReadTime;
    },
    writeStorage(): void {
      const { likedIds, lastReadTime } = this;
      writeLocalStorage({ likedIds, lastReadTime });
    },
  },
  mounted() {
    this.readStorage();
  },
  apollo: {
    changelogs: {
      query: require('./schema/listChangelogs.graphql'),
    },
  },
});
</script>
