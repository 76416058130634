import type { Properties } from 'csstype';

import type { ClassNames } from '@/types';

import type { TableColumnKey } from './Table.types';
import type {
  BaseColumnOptions,
  ValueOrColumnGetter,
} from './BaseColumn.types';

export default class BaseColumn<T> {
  readonly key: TableColumnKey;
  readonly title?: string;
  readonly width?: string;
  readonly className?: string;
  readonly headerClassName?: string;
  readonly dataClassName?: ValueOrColumnGetter<ClassNames, T>;
  readonly style?: Properties;
  readonly hederStyle?: Properties;
  readonly dataStyle?: Properties;
  readonly truncate?: boolean;
  readonly noTitle?: boolean;
  readonly resizable?: boolean;

  constructor(options: BaseColumnOptions<T>) {
    this.key = options.key;
    this.title = options.title;
    this.width = options.width;
    this.className = options.className;
    this.headerClassName = options.headerClassName;
    this.dataClassName = options.dataClassName;
    this.style = options.style;
    this.hederStyle = options.hederStyle;
    this.dataStyle = options.dataStyle;
    this.truncate = options.truncate;
    this.noTitle = options.noTitle;
    this.resizable = options.resizable;
  }

  getMinWidth(): string | undefined {
    if (this.width !== undefined) {
      return this.normalizeWidth(this.width);
    }

    if (this.style !== undefined) {
      const { width, minWidth } = this.style;
      if (width !== undefined) {
        return this.normalizeWidth(width);
      }
      if (minWidth !== undefined) {
        return this.normalizeWidth(minWidth);
      }
    }
    return undefined;
  }

  private normalizeWidth(width: string | number): string {
    if (typeof width === 'string') {
      return width;
    }

    return `${width}px`;
  }
}
