import type { TreeNode, TreeNodeIdentifier } from './Tree.types';

type SearchTreeResult = Set<TreeNodeIdentifier>;

export function searchTree(tree: TreeNode[], query: string): SearchTreeResult {
  const parents: Map<TreeNodeIdentifier, TreeNodeIdentifier> = new Map();
  const result = new Set<TreeNodeIdentifier>();
  const lowercased = query.toLowerCase();

  function internal(node: TreeNode) {
    if (node.children !== undefined) {
      for (const n of node.children) {
        parents.set(n.id, node.id);
        internal(n);
      }
    }

    const label = node.displayName ?? node.label;

    if (label.toLowerCase().indexOf(lowercased) !== -1) {
      let parent = parents.get(node.id);
      while (parent !== undefined) {
        result.add(parent);
        parent = parents.get(parent);
      }
      result.add(node.id);
    }
  }

  for (const node of tree) {
    internal(node);
  }

  return result;
}

export function filterTree(
  tree: TreeNode[],
  ids: SearchTreeResult
): TreeNode[] {
  const result: TreeNode[] = [];
  for (const node of tree) {
    if (ids.has(node.id)) {
      const copy: TreeNode = { ...node };
      if (node.children !== undefined) {
        copy.children = filterTree(node.children, ids);
      }
      result.push(copy);
    }
  }
  return result;
}
