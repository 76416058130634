<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import BaseIcon from './private/BaseIcon.vue';

export default Vue.extend({
  functional: true,
  props: {
    category: {
      type: String,
      default: 'utility',
    },
    size: {
      type: String,
      default: 'x-small',
    },
    colorVariant: {
      type: String,
      default: 'default',
    },
  },
  render(h, { props, data }) {
    return h(
      BaseIcon,
      mergeData(data, {
        props: {
          category: props.category,
          size: props.size,
          colorVariant: props.colorVariant,
        },
        staticClass: 'slds-icon',
      })
    );
  },
});
</script>
