import { computed } from '@vue/composition-api';

import { useListAvailableSalesforceApiVersionsQuery } from '@/graphql/types';

export default function useAvailableSalesforceApiVersions() {
  const { result } = useListAvailableSalesforceApiVersionsQuery();
  const versions = computed(
    () => result.value?.availableSalesforceApiVersions ?? []
  );
  return {
    versions,
  };
}
