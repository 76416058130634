<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { category, colorVariant } from '../common/icon/props';

const SLDS_ICONS: { [category: string]: any } = {
  action: require('@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg'),
  custom: require('@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg'),
  doctype: require('@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg'),
  standard: require('@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg'),
  utility: require('@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg'),
};

/*
 * If inline icons are present and icon bundle imports are not just an empty
 * object, then inline icons will be used instead of external icons that
 * require HTTP access.
 */
export default Vue.extend({
  functional: true,
  props: {
    category,
    colorVariant,
    /**
     * Name of the icon. Visit <a href='http://www.lightningdesignsystem.com/resources/icons'>Lightning Design System Icons</a> to reference icon names.
     */
    name: String,
    /**
     * Size of the icon. Visit [lightningdesignsystem.com/components/icons/#flavor-sizes](https://www.lightningdesignsystem.com/components/icons/#flavor-sizes)
     */
    size: {
      type: String,
      validator(val: string) {
        return ['xx-small', 'x-small', 'small', 'medium', 'large'].includes(
          val
        );
      },
      default: 'medium',
    },
  },
  render(h, { props, data }) {
    const use = h('use', {
      attrs: {
        'xlink:href': `${SLDS_ICONS[props.category]}#${props.name}`,
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
      },
    });
    const svg = h(
      'svg',
      mergeData(data, {
        attrs: { xmlns: 'http://www.w3.org/2000/svg', 'aria-hidden': true },
        class: {
          [`slds-icon_${props.size}`]: props.size !== 'medium',
          [`slds-icon-text-${props.colorVariant}`]:
            props.colorVariant &&
            props.category === 'utility' &&
            props.colorVariant !== 'base',
        },
      }),
      [use]
    );
    return svg;
  },
});
</script>
