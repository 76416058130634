<template>
  <div :id="id" :class="['slds-progress-ring', className]">
    <div
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="fillPercentDecimal * 100"
      class="slds-progress-ring__progress"
      role="progressbar"
      :style="progressStyles"
    >
      <svg viewBox="-1 -1 2 2">
        <path
          v-if="fillPercentDecimal < 1.0"
          class="slds-progress-ring__path"
          :d="calculateD(fillPercentDecimal)"
        />
        <circle v-else class="slds-progress-ring__path" cx="0" cy="0" r="1" />
      </svg>
    </div>
    <div class="slds-progress-ring__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

/**
 * Generates the string for the D value of the SVG path
 * @param isLong {number} a binary flag if the arc should 'take the long path' (used for > 50% fill)
 * @param arcX {decimal} the arc's x position
 * @param arcY {decimal} the arc's y position
 */
function getD(isLong: number, arcX: number, arcY: number) {
  return `M 1 0 A 1 1 0 ${isLong} 1 ${arcX} ${arcY} L 0 0`;
}

/**
 * Calculates the fill part of the ring
 * @param fillPercent {decimal} Decimal percentage that represents the amount of the ring which is filled with color.
 */
function calculateD(fillPercent: number) {
  const isLong = fillPercent > 0.5 ? 1 : 0;
  const arcX = Math.cos(2 * Math.PI * fillPercent);
  const arcY = Math.sin(2 * Math.PI * fillPercent);

  return getD(isLong, arcX, arcY);
}

export default Vue.extend({
  props: {
    /**
     * HTML id for component.
     */
    id: String,
    /**
     * CSS class names to be added to the container element. `array`, `object`, or `string` are accepted.
     */
    className: {},
    /**
     * Decimal percentage drain of the ring [0.0 - 1.0]
     */
    fillPercentDecimal: {
      type: Number,
      default: 0,
    },
    /**
     * Direction that the progress ring "flows." Default is counter-clockwise, or `drain`. For clockwise flow, use `fill`
     */
    flowDirection: String,
    /**
     * Size of the progress ring. Default is 'medium'
     */
    size: String,
  },
  computed: {
    progressStyles() {
      let progressStyles: Partial<CSSStyleDeclaration> = {
        height: this.size === 'large' ? '2rem' : '1.5rem',
      };
      if (this.flowDirection === 'fill') {
        progressStyles.transform = 'scaleX(1) rotate(-90deg)';
      }
      return progressStyles;
    },
  },
  methods: {
    calculateD,
  },
});
</script>
