import Vue, { VNode, PropType } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithProperties } from 'vue-typed-properties';

import type { Events } from './Accordion.types';

export default (
  Vue as WithProperties<
    {
      items: Array<Vue>;
    },
    WithEvents<Events>
  >
).extend({
  name: 'SldsAccordion',
  provide(): { accordion: Vue } {
    return {
      accordion: this,
    };
  },
  props: {
    value: {
      type: Array as PropType<number[] | null>,
      default: null,
    },
  },
  created() {
    this.items = [];
  },
  methods: {
    addItem(item: Vue) {
      this.items.push(item);
      return this.items.length - 1;
    },
    isItemExpanded(index: number) {
      if (this.value === null) {
        return true;
      }
      return this.value.includes(index);
    },
    toggleItem(index: number) {
      if (this.value === null) {
        return;
      }

      if (this.value.includes(index)) {
        this.$emit(
          'input',
          this.value.filter((item) => item !== index)
        );
        return;
      }

      this.$emit('input', [...this.value, index].sort());
    },
  },
  render(h): VNode {
    return h('ul', { staticClass: 'slds-accordion' }, this.$slots.default);
  },
});
