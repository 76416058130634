import Vue, { PropType, VNode } from 'vue';
import type { WithRefs } from 'vue-typed-refs';
import type { WithEvents } from 'vue-typed-emit';
import type { WithProperties } from 'vue-typed-properties';

import { hasSlot, normalizeSlot, generateId } from '@/util';

import Icon from '../Icon/Icon';
import Input from '../Input.vue';

import type { TreeNode, TreeNodeIdentifier, TreeEvents } from './Tree.types';
import TreeItem from './TreeItem';
import { searchTree, filterTree } from './Tree.util';

type Refs = {
  header: HTMLHeadingElement;
};

export default (
  Vue as WithEvents<
    TreeEvents,
    WithRefs<
      Refs,
      WithProperties<{ debouncedOnQuery: null | ((query: string) => void) }>
    >
  >
).extend({
  name: 'SldsTree',
  provide(): { tree: Vue } {
    return {
      tree: this,
    };
  },
  props: {
    items: {
      type: Array as PropType<TreeNode[]>,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    headerId: {
      type: String,
      default() {
        return generateId();
      },
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    debounce: {
      type: Function,
      default: null,
    },
    selected: {
      type: Set as PropType<Set<TreeNodeIdentifier>>,
      default: null,
    },
  },
  data() {
    return {
      opened: new Map<TreeNodeIdentifier, true>(),
      query: '',
      debouncedQuery: '',
      filteredTree: null as TreeNode[] | null,
    };
  },
  computed: {
    tree(): TreeNode[] {
      if (this.filteredTree !== null) {
        return this.filteredTree;
      }
      return this.items;
    },
    hasSelected(): boolean {
      return this.selected !== null;
    },
  },
  watch: {
    debouncedQuery(value: string) {
      if (this.debouncedOnQuery !== null) {
        this.debouncedOnQuery(value);
      } else {
        this.onQuery(value);
      }
    },
    query(value: string) {
      this.$emit('search', value);
    },
  },
  created() {
    this.debouncedOnQuery =
      this.debounce !== null ? this.debounce(this.onQuery) : null;
  },
  methods: {
    open(id: TreeNodeIdentifier) {
      this.opened.set(id, true);
      // TODO(Vue3): remove
      this.opened = new Map(this.opened);
    },
    close(id: TreeNodeIdentifier) {
      this.opened.delete(id);
      // TODO(Vue3): remove
      this.opened = new Map(this.opened);
    },
    toggle(id: TreeNodeIdentifier) {
      if (this.opened.has(id)) {
        this.opened.delete(id);
      } else {
        this.opened.set(id, true);
      }
      // TODO(Vue3): remove
      this.opened = new Map(this.opened);
    },
    isOpen(id: TreeNodeIdentifier) {
      return this.opened.has(id);
    },
    isSelected(id: TreeNodeIdentifier): boolean {
      return this.selected.has(id);
    },
    onClick(...args: TreeEvents['click:item']) {
      this.$emit('click:item', args[0], args[1], args[2]);
    },
    onQuery(value: string) {
      if (value === '') {
        this.opened = new Map();
        this.filteredTree = null;
        this.query = value;
        return;
      }
      const result = searchTree(this.items, value);
      const map = new Map<TreeNodeIdentifier, true>();
      for (const id of result) {
        map.set(id, true);
      }
      const tree = filterTree(this.items, result);
      this.opened = map;
      this.filteredTree = tree;
      this.query = value;
    },
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    const tree = h('div', { staticClass: 'slds-tree_container' }, [
      h(
        'h4',
        {
          ref: 'header',
          attrs: { id: this.headerId },
          staticClass: 'slds-tree__group-header',
        },
        this.header
      ),
      h(
        'ul',
        {
          attrs: { 'aria-labelledby': this.headerId, role: 'tree' },
          staticClass: 'slds-tree',
        },
        this.tree.map((item) =>
          h(TreeItem, {
            key: item.id,
            props: { node: item, level: 1 },
            scopedSlots: this.$scopedSlots,
          })
        )
      ),
    ]);

    if (this.filterable) {
      const input = h(
        Input,
        {
          attrs: { placeholder: 'Quick Find' },
          props: { value: this.query, label: 'Filter', noLabel: true },
          on: {
            input: (value: string) => {
              this.debouncedQuery = value;
            },
          },
        },
        [
          h(Icon, {
            props: {
              category: 'utility',
              name: 'search',
              iconClass: 'slds-input__icon slds-input__icon_left',
            },
            slot: 'icon-left',
          }),
        ]
      );

      return h('div', undefined, [
        this.hasSlot('prepend') ? this.normalizeSlot('prepend') : null,
        h('div', { staticClass: 'slds-m-bottom_small' }, [input]),
        tree,
      ]);
    }

    return tree;
  },
});
