var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GlobalNavigation',{attrs:{"id":"nav"}},[_c('GlobalNavigationItem',{attrs:{"to":{ name: 'branches' },"title":"Dashboard"}}),_c('GlobalNavigationItem',{attrs:{"to":{
      name: 'tree',
      params:
        _vm.preferred === null ? undefined : { branchName: _vm.preferred.branchName },
    },"title":"Files"}}),_c('GlobalNavigationItem',{staticClass:"slds-show_medium",attrs:{"to":{ name: 'activity' },"title":"Activity"}}),_c('GlobalNavigationItem',{staticClass:"slds-show_medium",attrs:{"to":{
      name: 'commits',
      params:
        _vm.preferred === null ? undefined : { branchName: _vm.preferred.branchName },
    },"title":"History"}}),_c('GlobalNavigationItem',{staticClass:"slds-show_medium",attrs:{"to":{ name: 'compare' },"title":"Compare"}}),_c('GlobalNavigationItem',{attrs:{"to":{ name: 'deployments.open' },"title":"Deployments"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }