<template>
  <slds-button
    variant="icon"
    icon-variant="container"
    icon-name="notification"
    icon-size="small"
    icon-class-name="slds-global-header__icon"
    :title="buttonTitle"
    :class="[
      'slds-global-actions__notifications',
      'slds-global-actions__item-action',
      buttonClasses,
    ]"
    aria-live="assertive"
    aria-atomic="true"
    v-on="$listeners"
  >
    <span
      class="slds-notification-badge"
      :class="badgeClasses"
      aria-hidden="true"
      >{{ unreadMessageCount }}</span
    >
  </slds-button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import type { ClassNames } from '@/types';
import { ChangelogFragment } from '@/graphql/types';

export default Vue.extend({
  props: {
    changelogs: {
      type: Array as PropType<ChangelogFragment[]>,
      required: true,
    },
    lastReadTime: {
      type: Date as PropType<Date>,
    },
    likedIds: {
      type: Array as PropType<string[]>,
    },
  },
  computed: {
    unreadMessageCount(): number {
      if (!this.lastReadTime) {
        return this.changelogs.length;
      }
      return this.changelogs.filter(
        (d) => this.lastReadTime < new Date(d.createTime)
      ).length;
    },
    hasUnreadMessages(): boolean {
      return this.unreadMessageCount > 0;
    },
    buttonClasses(): ClassNames {
      return {
        'slds-incoming-notification': this.hasUnreadMessages,
      };
    },
    buttonTitle(): string {
      return `${this.unreadMessageCount} unread notifications`;
    },
    badgeClasses(): ClassNames {
      return {
        'slds-show-notification': this.hasUnreadMessages,
        'slds-incoming-notification': this.hasUnreadMessages,
      };
    },
  },
});
</script>
