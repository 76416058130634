import type { PluginObject } from 'vue';

import info from './info';

const InfoPlugin: PluginObject<never> = {
  install(Vue) {
    Vue.prototype.$info = info;
  },
};

export type Info = typeof info;

export default InfoPlugin;
