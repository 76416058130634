<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import type { SldsStatus } from '../../types';
import { SLDS_STATUS } from '../../types';

export default Vue.extend({
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String as PropType<SldsStatus>,
      default: undefined,
      validator: (val: SldsStatus) => SLDS_STATUS.has(val),
    },
  },
  render(h, { props, data, listeners, children, slots, scopedSlots }) {
    const slots$ = slots();
    const left =
      slots$['icon-left'] ?? scopedSlots['icon-left'] !== undefined
        ? scopedSlots['icon-left']({}) ?? null
        : null;
    const right = slots$['icon-right'];

    delete data.on;

    if (left !== null && left.length === 1) {
      const d = left[0].data ?? {};
      d.class = [d.class, 'slds-m-right_x-small'];
      left[0].data = d;
    }

    if (right !== undefined && right.length === 1) {
      const d = right[0].data ?? {};
      d.class = [d.class, 'slds-m-left_x-small'];
      right[0].data = d;
    }

    if (props.disabled) {
      delete listeners.click;
    }

    const container = h(
      'span',
      { attrs: { title: props.title }, staticClass: 'slds-truncate' },
      left !== undefined ? [left, children] : children
    );
    const attrs: Record<string, unknown> = {
      role: 'menuitem',
      tabindex: '-1',
      'aria-disabled': props.disabled ? 'true' : undefined,
    };

    if (props.status !== undefined && props.disabled === false) {
      attrs.class = `slds-has-${props.status}`;
    }

    return h(
      'li',
      mergeData(data, {
        attrs: { role: 'presentation' },
        staticClass: 'slds-dropdown__item',
      }),
      scopedSlots.default({
        attrs,
        on: listeners,
        children: [container, right],
      })
    );
  },
});
</script>
