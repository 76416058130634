<template>
  <div :class="`slds-tabs_${variant}`">
    <ul :class="`slds-tabs_${variant}__nav`" role="tablist">
      <slot v-for="(tab, index) in tabs" name="tab" :tab="tab" :index="index">
        <li
          :key="tab.name"
          :class="[
            `slds-tabs_${variant}__item`,
            {
              'slds-is-active': tab.isActive && !tab.isDisabled,
              'slds-is-disabled': tab.isDisabled,
              ...tab.titleClass,
            },
          ]"
          :title="tab.title"
          role="presentation"
        >
          <a
            :id="`${tab.uid}__tab`"
            role="tab"
            :class="`slds-tabs_${variant}__link slds-grid slds-grid_vertical-align-center`"
            :aria-controls="tab.uid"
            :aria-selected="tab.isActive"
            :tabindex="tab.isActive ? '0' : '-1'"
            :href="tab.activeHref"
            :target="tab.target"
            @click="tab.target != '_blank' && navigateToTab(tab.name, $event)"
          >
            <span
              v-if="tab.loading"
              class="slds-tabs__left-icon slds-p-right_xx-small"
            >
              <SldsSpinner inline size="x-small" class="slds-m-top_x-large" />
            </span>
            {{ tab.title }}
            <SldsBadge
              v-if="tab.badge !== undefined"
              :color="tab.badgeColor"
              class="slds-m-left_xx-small"
            >
              {{ tab.badge }}
            </SldsBadge>
          </a>
        </li>
      </slot>
    </ul>

    <slot></slot>
  </div>
</template>

<script>
import invariant from 'invariant';

import SldsBadge from './Badge/Badge';
import SldsSpinner from './Spinner/Spinner';

export default {
  name: 'SldsTabs',
  components: {
    SldsBadge,
    SldsSpinner,
  },
  /**
   * Provide variant for children <tab></tab>
   */
  provide() {
    return {
      variant: this.variant,
    };
  },
  props: {
    active: { type: String },
    /**
     * Rendering style of the tab row.
     */
    variant: {
      type: String,
      default: 'default',
      validator: (val) => ['default', 'scoped'].includes(val),
    },
  },
  data() {
    return {
      tabs: [],
      tabsRef: new Map(),
      activeTab: this.active,
    };
  },
  watch: {
    active(value) {
      this.activeTab = value;
    },
    activeTab: 'updateTabs',
  },
  mounted() {
    this.init();
    this.updateTabs();
  },
  methods: {
    async navigateToTab(to, evt) {
      if (evt) {
        evt.preventDefault();
      }
      if (!(await this.beforeChangeTab(to))) {
        return;
      }
      this.changeTab(to, this.activeTab);
    },
    async beforeChangeTab(to) {
      const toTab = this.tabsRef.get(to);
      invariant(toTab, `tab "${to}" not found`);
      if (toTab.beforeChange) {
        const allowed = await toTab.beforeChange(toTab);
        if (process.env.NODE_ENV !== 'production' && !allowed) {
          console.log(
            `slds-tabs: tab change to "${to}" not allowed by beforeChange`
          );
        }
        return allowed;
      }
      return true;
    },
    changeTab(to, from) {
      if (from === to) {
        return;
      }

      const fromTab = this.tabsRef.get(from);
      const toTab = this.tabsRef.get(to);

      if (from) {
        fromTab.isActive = false;
      }

      if (to) {
        toTab.isActive = true;
      }

      this.activeTab = to;
      this.$emit('update:active', to, from);
      this.tryChangeRoute(toTab);
    },
    tryChangeRoute(tab) {
      if (this.$router && tab.routeLocation) {
        this.$router.push(tab.routeLocation);
      }
    },
    updateTabs() {
      this.tabs.forEach((cmp) => {
        cmp.isActive = this.activeTab === cmp.name;
      });
    },
    init() {
      this.tabs = this.$children.filter(
        (cmp) => cmp.$options.name === 'SldsTab'
      );
      this.tabs.forEach((cmp) => {
        this.tabsRef.set(cmp.name, cmp);
      });
    },
  },
};
</script>

<style lang="scss">
@use '../../style/variables';

.slds-tabs_default,
.slds-tabs_scoped {
  .slds-is-disabled {
    pointer-events: none;

    .slds-tabs_default__link,
    .slds-tabs_scoped__link {
      color: variables.$brand-disabled;
    }
    .slds-badge {
      color: variables.$brand-disabled;
    }
  }
}

.slds-tabs_scoped__item,
.slds-tabs--scoped__item {
  &:first-child .slds-tabs_scoped__link,
  &:first-child .slds-tabs--scoped__link {
    border-top-left-radius: variables.$border-radius-medium;
  }
}
</style>
