<template>
  <SldsModal
    v-bind="$attrs"
    open
    :title="title"
    @hide="close"
    v-on="$listeners"
  >
    <slot
      ><p>{{ text }}</p></slot
    >
    <template #footer>
      <SldsButton variant="brand" @click="close">Close</SldsButton>
    </template>
  </SldsModal>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

import { SldsButton, SldsModal } from '@ui';

export default defineComponent({
  name: 'InfoModal',
  components: {
    SldsButton,
    SldsModal,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: {
    close: null,
  },
  setup(_, { emit }) {
    function close() {
      emit('close');
    }

    return {
      close,
    };
  },
});
</script>
