// Code generated by 'make generate' (gql-gen). DO NOT EDIT.
/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ActivityItem": [
      "ActivityItemDeploymentComment",
      "ActivityItemDeploymentCreate",
      "ActivityItemDeploymentDelete",
      "ActivityItemSync"
    ],
    "Actor": [
      "NamedUser"
    ],
    "GitCommit": [
      "GitlabCommit",
      "GitlabCommitPtr"
    ],
    "GitDelta": [
      "GitlabDelta",
      "RawDelta"
    ],
    "GitFile": [
      "GitlabFile",
      "RawFile"
    ],
    "GitSignature": [
      "RawGitSignature"
    ],
    "Node": [
      "Branch",
      "BranchRule",
      "Branchsync",
      "BranchsyncLog",
      "Changelog",
      "Deployment",
      "DeploymentMerge",
      "DeploymentMergeFile",
      "DeploymentOperation",
      "DeploymentTemplate",
      "GitlabDelta",
      "GitlabFile",
      "Integration",
      "Issue",
      "OAuth2Client",
      "PermissionsSelectionTemplate",
      "Role",
      "Swimlane",
      "User",
      "UserGitPassword",
      "Webhook"
    ]
  }
};
      export default result;
    