import { computed } from '@vue/composition-api';

import type { Maybe } from '@/types';
import type {
  BranchForUsePermissionsFragment,
  DeploymentForUsePermissionFragment,
} from '@/graphql/types';
import { Permission } from '@/graphql/types';
import useProfile from '@/composables/useProfile';
import useTenant from '@/composables/useTenant';

export default function usePermissions() {
  const { viewerRoles, viewerHasOwnerRole } = useProfile();
  const { isSysco } = useTenant();

  function check(
    branch: Maybe<BranchForUsePermissionsFragment>,
    permission: Permission
  ) {
    if (branch === null || branch === undefined) {
      return false;
    }

    const candidateRoles = branch.branchPermissions
      .filter((item) => item.permission === permission)
      .map((item) => item.role.name);

    for (const item of candidateRoles) {
      if (viewerRoles.value.includes(item)) {
        return true;
      }
    }

    return false;
  }

  // Deployment

  function viewerCanCreateDeploymentTo(
    targetBranch: BranchForUsePermissionsFragment
  ) {
    return check(targetBranch, Permission.CREATE_DEPLOYMENT);
  }

  function viewerCanUpdateDeployment(
    deployment: DeploymentForUsePermissionFragment
  ) {
    const { targetBranch } = deployment;
    return check(targetBranch, Permission.CREATE_DEPLOYMENT);
  }

  function viewerCanReviewDeployment(
    deployment: DeploymentForUsePermissionFragment
  ) {
    const { targetBranch } = deployment;
    return check(targetBranch, Permission.REVIEW_DEPLOYMENT);
  }

  function viewerCanSelfReviewDeployment(
    deployment: DeploymentForUsePermissionFragment
  ) {
    const { targetBranch } = deployment;
    return check(targetBranch, Permission.SELF_REVIEW_DEPLOYMENT);
  }

  function viewerCanAcceptDeployment(
    deployment: DeploymentForUsePermissionFragment
  ) {
    const { targetBranch } = deployment;
    return check(targetBranch, Permission.ACCEPT_DEPLOYMENT);
  }

  // Sysco: Only owners are allowed to create Releases, to ensure that every
  // deliverable can be attached as a story later on when they do they
  // staging-to-prod release.

  function viewerCanCreateReleaseTo(
    targetBranch: BranchForUsePermissionsFragment
  ) {
    if (isSysco.value) {
      return viewerHasOwnerRole.value;
    }

    return viewerCanCreateDeploymentTo(targetBranch);
  }

  const viewerCanCreateReleases = computed(() => {
    if (isSysco.value) {
      return viewerHasOwnerRole.value;
    }

    return true;
  });

  // Settings

  const viewerCanUpdateSettings = computed(() => {
    return viewerHasOwnerRole.value;
  });

  const viewerCanChangeRoles = computed(() => {
    return viewerHasOwnerRole.value;
  });

  const viewerCanChangePermissions = computed(() => {
    return viewerHasOwnerRole.value;
  });

  return {
    viewerCanCreateDeploymentTo,
    viewerCanUpdateDeployment,
    viewerCanReviewDeployment,
    viewerCanSelfReviewDeployment,
    viewerCanAcceptDeployment,
    viewerCanCreateReleaseTo,
    viewerCanCreateReleases,
    viewerCanUpdateSettings,
    viewerCanChangeRoles,
    viewerCanChangePermissions,
  };
}
