export type StepId = string;
export enum StepState {
  Active = 'active',
  Complete = 'complete',
  Error = 'error',
}
export type Step = {
  id: StepId;
  state?: StepState;
  class?: string;
  icon?: { name: string; title?: string };
};

export const STEP_STATE_TO_TITLE: Record<StepState, string> = {
  [StepState.Active]: 'Active',
  [StepState.Complete]: 'Complete',
  [StepState.Error]: 'Error',
};
