import { computed } from '@vue/composition-api';

import type { ViewerFragment } from '@/graphql/types';
import { useGetViewerQuery } from '@/graphql/types';

export default function useProfile() {
  const { result } = useGetViewerQuery();
  const profile = computed(
    () => result.value?.viewer ?? (null as unknown as ViewerFragment)
  );

  const viewerHasOwnerRole = computed(() =>
    profile.value.roles.includes('roles/owner')
  );

  const viewerRoles = computed(() => profile.value.roles);

  return {
    profile,
    viewerRoles,
    viewerHasOwnerRole,
  };
}
