var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.variant === 'button')?_c('slds-button',_vm._g(_vm._b({class:[
    'slds-input__icon',
    ( _obj = {}, _obj[("slds-input__icon_" + _vm.position)] = _vm.position, _obj ) ]},'slds-button',Object.assign({}, {variant: 'icon',
    iconName: _vm.name,
    iconClassName: 'slds-icon-text-light'},
    _vm.$attrs),false),_vm.$listeners)):(_vm.variant === 'combobox' || _vm.variant === 'lookup')?_c('span',{staticClass:"slds-icon_container slds-input__icon slds-input__icon_right"},[_c('base-icon',_vm._b({staticClass:"slds-icon"},'base-icon',Object.assign({}, {category: 'utility',
      name: _vm.variant === 'combobox' ? 'down' : 'search',
      size: 'x-small',
      colorVariant: 'default'},
      _vm.$attrs),false))],1):_c('base-icon',_vm._b({class:[
    'slds-icon',
    'slds-input__icon',
    ( _obj$1 = {}, _obj$1[("slds-input__icon_" + _vm.position)] = _vm.position, _obj$1 ) ],attrs:{"color-variant":"default"}},'base-icon',Object.assign({}, {category: 'utility',
    name: _vm.name},
    _vm.$attrs),false))}
var staticRenderFns = []

export { render, staticRenderFns }