<template>
  <RouterLink
    v-slot="{ href, isActive, navigate }"
    v-bind="$attrs"
    custom
    v-on="$listeners"
  >
    <li :class="{ 'slds-is-active': isActive }" class="slds-context-bar__item">
      <a
        :href="href"
        class="slds-context-bar__label-action"
        :title="title"
        @click="navigate"
      >
        <span v-if="isActive" class="slds-assistive-text">Current Page:</span>
        <span class="slds-truncate" :title="title">
          {{ title }}
        </span>
      </a>
    </li>
  </RouterLink>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GlobalNavigationItem',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>
