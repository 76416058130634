<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsSetupAssistant',
  functional: true,
  props: {
    card: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children, slots }) {
    const { header } = slots();

    const list = h(
      'ol',
      mergeData(data, { staticClass: 'slds-setup-assistant' }),
      children
    );

    if (props.card) {
      return h('section', { staticClass: 'slds-card' }, [
        h(
          'header',
          {
            staticClass:
              'slds-theme_shade slds-p-around_medium slds-m-bottom_xx-small',
          },
          header
        ),
        list,
      ]);
    }

    return list;
  },
});
</script>
