import { computed, inject } from '@vue/composition-api';

import { TENANT_INEJCTION_KEY, SYSCO_TENANT_ID } from '@/module/tenant';

export default function useTenant() {
  const tenant = inject(TENANT_INEJCTION_KEY);
  if (tenant === undefined) {
    throw new Error('Tenant not provided');
  }
  const tenantId = computed(() => tenant.value.tenantId);
  const repoId = computed(() => tenant.value.repo.repoId);
  const isSysco = computed(() => tenantId.value === SYSCO_TENANT_ID);
  return {
    tenant,
    tenantId,
    repoId,
    isSysco,
  };
}
