var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProgressRingShape',_vm._b({class:[
    _vm.themeClass,
    {
      'slds-progress-ring_large': _vm.size === 'large',
    } ]},'ProgressRingShape',{
    fillPercentDecimal: _vm.fillPercentDecimal,
    flowDirection: _vm.flowDirection,
    size: _vm.size,
  },false),[_vm._t("default",function(){return [(_vm.theme === 'warning')?_c('slds-icon',{attrs:{"category":"utility","name":"warning","title":"Warning"}}):_vm._e(),(_vm.theme === 'expired')?_c('slds-icon',{attrs:{"category":"utility","name":"error","title":"Expired"}}):_vm._e(),(_vm.theme === 'complete')?_c('slds-icon',{attrs:{"category":"utility","name":"check","title":"Complete"}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }