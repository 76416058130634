<template>
  <label
    v-if="variant === 'base'"
    :class="[
      'slds-form-element__label',
      { 'slds-assistive-text': assistiveText && !label },
      className,
    ]"
    :for="htmlFor"
    ><abbr v-if="required" class="slds-required" title="required">*</abbr
    >{{ labelText }}</label
  >

  <span
    v-else-if="variant === 'static'"
    :class="['slds-form-element__label', className]"
    >{{ labelText }}</span
  >
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
/**
 * Form label. This returns null if there is no label text (hidden or shown)
 */
export default Vue.extend({
  props: {
    /**
     * Assistive Text to use instead of a visible label
     */
    assistiveText: {
      type: Object as PropType<{ label: string }>,
    },
    /**
     * Class names to be added to the label
     */
    className: {},
    /*
     * Id of the input associated with this label
     */
    htmlFor: String,
    /**
     * Input Label
     */
    label: String,
    /**
     * Applies label styling for a required form element
     */
    required: Boolean,
    /**
     * Changes markup of label.
     */
    variant: {
      type: String,
      validator(val: string) {
        return ['base', 'static'].includes(val);
      },
    },
  },
  computed: {
    labelText(): string | undefined {
      // One of these is required to pass accessibility tests
      return this.label || (this.assistiveText && this.assistiveText.label);
    },
  },
});
</script>
