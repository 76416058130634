import type { Sanitizer } from '../interfaces';
import DefaultSanitizer from '../sanitizer';

export type ConstructorOptions = { sanitizer?: Sanitizer };

export default class AbstractHTMLMarkdownConverter {
  protected readonly sanitizer: Sanitizer;

  constructor(options: ConstructorOptions = {}) {
    this.sanitizer = options.sanitizer ?? new DefaultSanitizer();
  }
}
