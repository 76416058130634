import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsPageHeader',
  functional: true,
  props: {
    recordHome: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, data, children }) {
    return h(
      'div',
      mergeData(data, {
        staticClass: 'slds-page-header',
        class: { 'slds-page-header_record-home': props.recordHome },
      }),
      children
    );
  },
});
