<template>
  <slds-button
    variant="icon"
    icon-name="like"
    icon-size="small"
    icon-class-name="slds-align-middle"
    icon-position="left"
    :class="[
      'slds-button_reset',
      'slds-post__footer-action',
      { 'slds-is-active': active },
    ]"
    title="Like this item"
    :aria-pressed="active ? 'true' : 'false'"
    v-on="$listeners"
    >{{ active ? 'Liked' : 'Like' }}</slds-button
  >
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    active: {
      type: Boolean,
    },
  },
});
</script>
