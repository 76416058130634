<script lang="ts">
import type { VNode } from 'vue';
import type { WithRefs } from 'vue-typed-refs';
import type { WithProperties } from 'vue-typed-properties';
import { Wormhole } from 'portal-vue';
import invariant from 'invariant';

import type { VueComponentListeners } from '@/types';
import { PopperMixin } from '@/mixins';
import { generateId } from '@/util';

type Refs = {
  tooltip: HTMLDivElement;
};

export default (
  PopperMixin as WithRefs<
    Refs,
    WithProperties<{ timeout?: number }, typeof PopperMixin>
  >
).extend({
  inheritAttrs: false,
  props: {
    uid: {
      type: String,
      default() {
        return generateId();
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    portalFrom(): string {
      return `Tooltip-${this.uid}`;
    },
    activatorAttrs(): Record<string, string | number | boolean> {
      if (this.isOpen === false) {
        return {};
      }
      return {
        'aria-describedby': this.rootId,
      };
    },
    activatorOn(): VueComponentListeners {
      return {
        mouseenter: this.onMouseEnter,
        mouseleave: this.onMouseLeave,
        focus: this.onMouseEnter,
        blur: this.hide,
      };
    },
    rootId() {
      return `tooltip-${this.uid}`;
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (value) {
        this.updateWormhole();

        this.$nextTick(() => {
          this.createPopperInstance(this.$el, this.$refs.tooltip);
          this.$emit('open');
        });
      } else {
        this.destroyPopperInstance();
        Wormhole.close({
          to: 'slds-tooltip',
          from: this.portalFrom,
        });
        this.$emit('close');
      }
    },
  },
  /**
   * That's crucial. Please, don't remove it
   * Ensures that Wormhole always contains a fresh content
   */
  updated() {
    if (this.isOpen) {
      this.updateWormhole();
      if (this.popper !== null) {
        this.reposition();
      }
    }
  },
  beforeDestroy() {
    if (this.timeout !== undefined) {
      window.clearTimeout(this.timeout);
    }
  },
  methods: {
    show() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
    onMouseEnter() {
      if (this.timeout !== undefined) {
        window.clearTimeout(this.timeout);
      }
      if (this.disabled === false) {
        this.show();
      }
    },
    onMouseLeave() {
      this.timeout = window.setTimeout(() => {
        this.hide();
      }, 400);
    },
    onTooltipMouseEnter() {
      if (this.timeout !== undefined) {
        window.clearTimeout(this.timeout);
      }
    },
    renderTooltip(): VNode {
      const h = this.$createElement;

      return h(
        'div',
        {
          ref: 'tooltip',
          attrs: {
            id: this.rootId,
            role: 'tooltip',
            ...this.$attrs,
          },
          staticClass: 'slds-popover slds-popover_tooltip',
          class: [`slds-nubbin_${this.getNubbin()}`],
          on: {
            mouseenter: this.onTooltipMouseEnter,
            mouseleave: this.onMouseLeave,
          },
        },
        [
          h(
            'div',
            {
              staticClass: 'slds-popover__body',
            },
            this.$slots.default
          ),
        ]
      );
    },
    updateWormhole() {
      Wormhole.open({
        to: 'slds-tooltip',
        from: this.portalFrom,
        passengers: [this.renderTooltip()],
      });
    },
  },
  render(): VNode {
    const { activator } = this.$scopedSlots;
    const message = "`'activator'` slot is not provided";
    invariant(activator, message);

    const content = activator({
      attrs: this.activatorAttrs,
      on: this.activatorOn,
      isOpen: this.isOpen,
    });

    invariant(content, message);

    return content[0];
  },
});
</script>
