import { computed } from '@vue/composition-api';
import { useMediaQuery } from '@vueuse/core';

export default function useDevice() {
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const isMobile = computed(() => isDesktop.value === false);
  return {
    isDesktop,
    isMobile,
  };
}
