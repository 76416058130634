import Vue, { VNode } from 'vue';

import { generateId } from '@/util';

import FormElement from '../FormElement/FormElement';

export default Vue.extend({
  name: 'RadioGroup',
  provide(): { group: Vue } {
    return {
      group: this,
    };
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default() {
        return generateId();
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
    },
    help: {
      type: String,
    },
  },
  methods: {
    onCheck(value: string) {
      this.$emit('input', value);
    },
  },
  render(h): VNode {
    return h(
      FormElement,
      {
        props: {
          tag: 'fieldset',
          label: this.label,
          required: this.required,
          error: this.error,
          help: this.help,
        },
      },
      this.$slots.default
    );
  },
});
