import { Phase, State } from '@/graphql/types';

const PHASES: Record<Phase, number> = {
  [Phase.DRAFT]: 0,
  [Phase.NEW]: 1,
  [Phase.PERMISSIONS]: 2,
  [Phase.PACKAGE]: 4,
  [Phase.WAITING]: 5,
  [Phase.VALIDATE]: 6,
  [Phase.CHECK]: 7,
  [Phase.READY_TO_DEPLOY]: 8,
  [Phase.QUEUED]: 9,
  [Phase.APPLY]: 10,
  [Phase.DONE]: 11,
};

export class DeploymentState {
  constructor(
    public readonly phase: Phase,
    public readonly state: State
  ) {}

  public isComplete(phase: Phase): boolean {
    return (
      PHASES[phase] < PHASES[this.phase] ||
      (phase === this.phase && this.state === State.READY)
    );
  }

  public isActive(phase: Phase): boolean {
    return phase === this.phase;
  }

  public isRunningOrComplete(phase: Phase): boolean {
    return PHASES[this.phase] >= PHASES[phase];
  }

  public isBefore(phase: Phase): boolean {
    return PHASES[this.phase] < PHASES[phase];
  }
}
