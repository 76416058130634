<template>
  <base-icon
    v-bind="$attrs"
    category="utility"
    :class="[
      'slds-button__icon',
      {
        [`slds-button__icon_${size}`]: size && size !== 'medium',
        'slds-button__icon_inverse-hint': inverse && hint,
        'slds-button__icon_hint': hint && !inverse,
        [`slds-button__icon_${position}`]: position,
      },
    ]"
  />
</template>

<script lang="ts">
import Vue from 'vue';

import BaseIcon from './private/BaseIcon.vue';

/**
 * This is a non-interactive wrapper component for `BaseIcon` that specifies
 * button icon classes for an icon inside a `button` tag.
 *
 * Use of this component by itself is not recommended, but should be used as
 * part of other components to obtain the correct styling for icons within
 * buttons.
 *
 * This component only partially implements [Button Icons](http://www.lightningdesignsystem.com/components/button-icons).
 * It does not return a `button` HTML tag. It only returns an icon for use
 * within a button. Assistive text must also be rendered by the parent.
 */
export default Vue.extend({
  components: {
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    /**
     * Associates an icon button with another element on the page by changes the color of the SVG. Please reference <a href="http://www.lightningdesignsystem.com/components/buttons/#hint">Lightning Design System Buttons > Hint</a>.
     */
    size: String,
    hint: Boolean,
    /**
     * Setting `inverse` to true will switch the color of the icon: light to dark, dark to light.
     */
    inverse: Boolean,
    /**
     * Adds additional spacing on the opposite side specified between button icon and the button label
     */
    position: {
      type: String,
      validator(val: string) {
        return ['left', 'right'].includes(val);
      },
    },
  },
});
</script>
