import Vue, { PropType, VNode } from 'vue';
import type { ScopedSlotChildren } from 'vue/types/vnode';

import Highlight from '../private/Highlight/Highlight';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonIconIcon from '../ButtonIcon/ButtonIconIcon';

import type { TreeNode, Tree } from './Tree.types';

const SldsTreeItem = Vue.extend({
  name: 'SldsTreeItem',
  functional: true,
  inject: ['tree'],
  props: {
    node: {
      type: Object as PropType<TreeNode>,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  render(h, { props, injections, scopedSlots }): VNode {
    const { tree } = injections as { tree: Tree };
    const { node, level } = props;
    const hasChildren = props.node.children !== undefined;
    const isOpen = hasChildren ? tree.isOpen(node.id) : false;
    const isSelected = tree.hasSelected ? tree.isSelected(node.id) : false;
    let prependContent: ScopedSlotChildren | null = null;
    let appendContent: ScopedSlotChildren | null = null;
    if (scopedSlots['item-prepend'] !== undefined) {
      prependContent = scopedSlots['item-prepend']({ item: node, level });
    }
    if (scopedSlots['item-append'] !== undefined) {
      appendContent = scopedSlots['item-append']({
        item: node,
        level,
      });
    }
    let ariaExpanded: 'true' | 'false' | undefined;
    if (hasChildren) {
      if (isOpen) {
        ariaExpanded = 'true';
      } else {
        ariaExpanded = 'false';
      }
    }

    return h(
      'li',
      {
        attrs: {
          'aria-expanded': ariaExpanded,
          'aria-selected': isSelected ? 'true' : undefined,
          'aria-level': props.level,
          'aria-label': hasChildren ? node.label : undefined,
          role: 'treeitem',
        },
      },
      [
        h(
          'div',
          {
            staticClass: 'slds-tree__item',
            on: {
              click: () => {
                if (hasChildren) {
                  tree.toggle(node.id);
                }
                tree.onClick(node.id, node, level);
              },
            },
          },
          [
            hasChildren
              ? h(
                  ButtonIcon,
                  {
                    attrs: {
                      title: `Expand ${node.label}`,
                      'aria-hidden': 'true',
                      tabindex: '-1',
                    },
                    staticClass: 'slds-m-right_x-small',
                  },
                  [
                    h(ButtonIconIcon, {
                      props: { name: 'chevronright', size: 'small' },
                    }),
                  ]
                )
              : null,
            prependContent,
            h('span', { staticClass: 'slds-has-flexi-truncate' }, [
              h(
                'span',
                {
                  attrs: { title: node.label },
                  staticClass: 'slds-tree__item-label slds-truncate',
                },
                tree.query !== ''
                  ? [
                      h(Highlight, {
                        props: {
                          text: node.displayName ?? node.label,
                          query: tree.query,
                        },
                      }),
                    ]
                  : node.displayName ?? node.label
              ),
            ]),
            appendContent,
          ]
        ),
        node.children !== undefined && isOpen
          ? h('ul', { attrs: { role: 'group' } }, [
              node.children.map((child) =>
                h(SldsTreeItem, {
                  props: { node: child, level: props.level + 1 },
                  scopedSlots,
                })
              ),
            ])
          : null,
      ]
    );
  },
});

export default SldsTreeItem;
