const params = {
  'purestorage.my.bluecanvas.io': {
    BLUECANVAS_API_URL: 'https://purestorage.my.bluecanvas.io',
    BLUECANVAS_BACKEND_URL: 'https://purestorage.my.bluecanvas.io/apis',
    BLUECANVAS_MANAGE_URL: 'https://purestorage.my.bluecanvas.io/manage',
    BLUECANVAS_GIT_FRAME_URL: 'https://purestorage.my.bluecanvas.io',
    BLUECANVAS_GIT_CLONE_URL: 'https://purestorage.my.bluecanvas.io',
    BLUECANVAS_ACCOUNTS_URL: 'https://purestorage.my.bluecanvas.io',
    STATUSPAGE_ID: '3g3drzfh48hv',
  },
  '*.my.bluecanvas.io': {
    BLUECANVAS_BACKEND_URL: 'https://*.my.bluecanvas.io/apis',
    BLUECANVAS_MANAGE_URL: 'https://*.my.bluecanvas.io/manage',
    BLUECANVAS_GIT_FRAME_URL: 'https://*.my.bluecanvas.io',
    BLUECANVAS_GIT_CLONE_URL: 'https://*.my.bluecanvas.io',
    BLUECANVAS_ACCOUNTS_URL: 'https://accounts.bluecanvas.io',
    GOOGLE_ANALYTICS_ID: 'UA-80238983-2',
    STATUSPAGE_ID: '3g3drzfh48hv',
  },
  '*.my.bluetesting.io': {
    BLUECANVAS_BACKEND_URL: 'https://*.my.bluetesting.io/apis',
    BLUECANVAS_MANAGE_URL: 'https://*.my.bluetesting.io/manage',
    BLUECANVAS_GIT_FRAME_URL: 'https://*.my.bluetesting.io',
    BLUECANVAS_GIT_CLONE_URL: 'https://*.my.bluetesting.io',
    BLUECANVAS_ACCOUNTS_URL: 'https://accounts.bluetesting.io',
    GOOGLE_ANALYTICS_ID: 'UA-80238983-2',
    STATUSPAGE_ID: '3g3drzfh48hv',
  },
  '*.my.recovery.bluetesting.io': {
    BLUECANVAS_BACKEND_URL: 'https://*.my.recovery.bluetesting.io/apis',
    BLUECANVAS_MANAGE_URL: 'https://*.my.recovery.bluetesting.io/manage',
    BLUECANVAS_GIT_FRAME_URL: 'https://*.my.recovery.bluetesting.io',
    BLUECANVAS_GIT_CLONE_URL: 'https://*.my.recovery.bluetesting.io',
    BLUECANVAS_ACCOUNTS_URL: 'https://accounts.recovery.bluetesting.io',
    GOOGLE_ANALYTICS_ID: 'UA-80238983-2',
    STATUSPAGE_ID: '3g3drzfh48hv',
  },
  localhost: {
    BLUECANVAS_API_URL: 'http://localhost:8080',
    BLUECANVAS_BACKEND_URL: 'http://localhost:8081/apis',
    BLUECANVAS_MANAGE_URL: 'http://localhost:8081/manage',
    BLUECANVAS_GIT_FRAME_URL: 'http://localhost:8081',
    BLUECANVAS_GIT_CLONE_URL: 'http://localhost:19877',
    STATUSPAGE_ID: '3g3drzfh48hv',
  },
};

/** Recursively replaces '*' in rule params if rule name matches. */
function globRule(host, ruleName, rule) {
  if (ruleName.indexOf('*') === -1 || ruleName === '*') {
    return;
  }

  let pattern = ruleName.replace(/\./g, '\\.').replace(/\*/g, '([^.]+)');
  let re = new RegExp(`^${pattern}$`);
  let matches = re.exec(host);
  if (!matches) {
    return;
  }

  let result = Object.assign({}, rule);
  for (let match of matches.slice(1)) {
    for (let k in result) {
      result[k] = result[k].replace('*', match);
    }
  }

  return result;
}

function resolve() {
  var parts = document.createElement('a');
  parts.href = document.location.href;

  var host = parts.host.split(':')[0];

  for (var k in params) {
    let globMatch = globRule(host, k, params[k]);
    if (globMatch) {
      return globMatch;
    }
    if (host.endsWith(k)) {
      return params[k];
    }
  }
  if (params['*']) {
    return params['*'];
  }

  let knownKeys = Object.keys(params).join(', ');
  throw new Error(
    `params: No config for host "${host}", available: [${knownKeys}]`
  );
}

module.exports = {
  get BLUECANVAS_API_URL() {
    return resolve().BLUECANVAS_API_URL;
  },
  get BLUECANVAS_BACKEND_URL() {
    return resolve().BLUECANVAS_BACKEND_URL;
  },
  get BLUECANVAS_MANAGE_URL() {
    return resolve().BLUECANVAS_MANAGE_URL;
  },
  get BLUECANVAS_GIT_FRAME_URL() {
    return resolve().BLUECANVAS_GIT_FRAME_URL;
  },
  get BLUECANVAS_GIT_CLONE_URL() {
    return resolve().BLUECANVAS_GIT_CLONE_URL;
  },
  get BLUECANVAS_ACCOUNTS_URL() {
    return resolve().BLUECANVAS_ACCOUNTS_URL;
  },
  get GOOGLE_ANALYTICS_ID() {
    return resolve().GOOGLE_ANALYTICS_ID;
  },
  get STATUSPAGE_ID() {
    return resolve().STATUSPAGE_ID;
  },
  resolve: resolve,
};
