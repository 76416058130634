import Clipboard from 'clipboard';
import type { Ref } from '@vue/composition-api';
import {
  ref,
  shallowRef,
  computed,
  watch,
  onBeforeUnmount,
} from '@vue/composition-api';

import type { Undefined, Nullable } from '@/types';

const DELAY = 2000;

export default function useClipboard(el: Ref, content: Ref<string>) {
  const clipboard: Ref<Nullable<Clipboard>> = shallowRef(null);
  let timer: Undefined<number>;
  const copied = ref(false);
  watch(el, (v) => {
    if (v === null) return;
    const c = new Clipboard(v, {
      text: () => content.value,
    });
    clipboard.value = c;
    c.on('success', () => {
      copied.value = true;
      timer = window.setTimeout(() => {
        copied.value = false;
        timer = undefined;
      }, DELAY);
    });
  });
  onBeforeUnmount(() => {
    if (clipboard.value !== null) {
      clipboard.value.destroy();
      clipboard.value = null;
    }
    if (timer !== undefined) {
      window.clearTimeout(timer);
    }
  });
  const text = computed(() => (copied.value ? 'Copied' : 'Copy'));
  return { clipboard, text };
}
