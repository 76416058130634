<template>
  <div class="slds-context-bar global-navigation">
    <nav
      class="slds-context-bar__secondary slds-container_center slds-container_large"
      role="navigation"
    >
      <ul class="slds-grid">
        <slot />
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'GlobalNavigation',
};
</script>

<style lang="scss">
.global-navigation {
  padding-left: 0;
  border-bottom-width: 0;
}
</style>
