<template>
  <div class="slds-feed">
    <ul class="slds-feed__list">
      <slot />
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({});
</script>
