import type { PropType } from 'vue';
import Vue from 'vue';

import { SpinnerSize, SpinnerColor } from './Spinner.types';

export default Vue.extend({
  name: 'SldsSpinner',
  props: {
    size: {
      type: String as PropType<SpinnerSize>,
      default: SpinnerSize.Medium,
      validator: (val) => Object.values(SpinnerSize).includes(val),
    },
    color: {
      type: String as PropType<SpinnerColor>,
      default: undefined,
      validator: (val) => Object.values(SpinnerColor).includes(val),
    },
    inline: {
      type: Boolean,
      default: false,
    },
    delayed: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    assistiveText: {
      type: String,
      default: 'Loading',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h) {
    const spinner = h(
      this.tag,
      {
        attrs: { role: 'status' },
        staticClass: 'slds-spinner',
        class: {
          [`slds-spinner_${this.size}`]: true,
          'slds-spinner_inline': this.inline,
          [`slds-spinner_${this.color}`]: this.color !== undefined,
          'slds-spinner_delayed': this.delayed,
        },
      },
      [
        h('span', { staticClass: 'slds-assistive-text' }, this.assistiveText),
        h('div', { staticClass: 'slds-spinner__dot-a' }),
        h('div', { staticClass: 'slds-spinner__dot-b' }),
      ]
    );

    if (this.container) {
      return h(
        'div',
        {
          staticClass: 'slds-spinner_container',
          class: { 'slds-is-fixed': this.fixed },
        },
        [spinner]
      );
    }

    return spinner;
  },
});
