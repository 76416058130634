import Vue from 'vue';

import { uid } from '@/util';

import type { VueComponentListeners } from '../types';

export default Vue.extend({
  name: 'SldsTextarea',
  inheritAttrs: false,
  props: {
    uid: {
      type: String,
      default: uid,
    },
    value: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        input: (event: Event) => {
          this.$emit('input', (event.target as HTMLTextAreaElement).value);
        },
      };
    },
    hasHelp(): boolean {
      return this.help !== undefined;
    },
  },
  render(h) {
    return h('div', { staticClass: 'slds-form-element' }, [
      h(
        'label',
        { attrs: { for: this.uid }, staticClass: 'slds-form-element__label' },
        this.label
      ),
      h('div', { staticClass: 'slds-form-element__control' }, [
        h('textarea', {
          domProps: { value: this.value },
          attrs: {
            id: this.uid,
            ...this.$attrs,
            'aria-describedby': this.hasHelp ? `${this.uid}-help` : undefined,
          },
          staticClass: 'slds-textarea',
          on: this.listeners,
        }),
      ]),
      this.hasHelp
        ? h(
            'div',
            {
              attrs: { id: `${this.uid}-help` },
              staticClass: 'slds-form-element__help',
            },
            this.help
          )
        : null,
    ]);
  },
});
