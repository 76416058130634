<script lang="ts">
import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

const SPACE = ['top', 'bottom'] as const;

type Space = (typeof SPACE)[number];

export default Vue.extend({
  functional: true,
  props: {
    space: {
      type: String as PropType<Space>,
      validator: (val: Space) => SPACE.includes(val),
      default: 'top',
    },
  },
  render(h, { props, data }) {
    return h(
      'li',
      mergeData(data, {
        attrs: { role: 'separator' },
        class: `slds-has-divider_${props.space}-space`,
      })
    );
  },
});
</script>
