<template>
  <component
    :is="tagName"
    ref="button"
    :href="href"
    :class="classNames"
    :title="title"
    :to="to"
    :type="computedType"
    :aria-pressed="selected ? selected.toString() : undefined"
    v-on="$listeners"
  >
    <template v-if="iconPosition === 'right'">
      <slot></slot>
    </template>

    <template v-if="iconName">
      <slds-button-icon
        :class="iconClassName"
        :hint="hint"
        :inverse="inverse"
        :category="iconCategory"
        :name="iconName"
        :position="iconPosition"
        :size="
          iconVariant === 'container' || iconVariant === 'border'
            ? undefined
            : iconSize
        "
      />
    </template>

    <template v-if="iconVariant === 'more'">
      <slds-button-icon
        :class="iconClassName"
        category="utility"
        name="down"
        size="x-small"
      />
    </template>

    <template v-if="iconPosition !== 'right'">
      <slot></slot>
    </template>

    <!-- Render assisitve text only if button is icon -->
    <span v-if="isIcon" class="slds-assistive-text">{{ title }}</span>
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router';
import { WithRefs } from 'vue-typed-refs';

import type { ClassNames } from '@/types';

type Refs = {
  button: HTMLButtonElement | HTMLLinkElement;
};

/**
 * The Button component is the Lightning Design System Button component. The
 * Button should be used for label buttons, icon buttons, or buttons that have
 * both labels and icons.
 *
 * @slot default
 */
export default (Vue as WithRefs<Refs>).extend({
  name: 'SldsButton',
  props: {
    /**
     * Different types of buttons
     */
    variant: {
      type: String,
      validator(val: string) {
        return [
          'base',
          'link',
          'neutral',
          'brand',
          'outline-brand',
          'destructive',
          'success',
          'text-destructive',
          'icon',
        ].includes(val);
      },
      default: 'neutral',
    },
    /**
     * For icon variants, please reference <a href="http://www.lightningdesignsystem.com/components/buttons/#icon">Lightning Design System Icons</a>.
     *
     * @deprecated
     */
    iconVariant: {
      type: String,
      validator(val: string) {
        return [
          'bare',
          'container',
          'border',
          'border-filled',
          'brand',
          'more',
          'error',
        ].includes(val);
      },
    },
    /**
     * Name of the icon category. Visit <a href="http://www.lightningdesignsystem.com/resources/icons">Lightning Design System Icons</a> to reference icon categories.
     *
     * @deprecated
     */
    iconCategory: String,
    /**
     * Name of the icon. Visit <a href="http://www.lightningdesignsystem.com/resources/icons">Lightning Design System Icons</a> to reference icon names.
     *
     * @deprecated
     */
    iconName: String,
    /**
     * Determines the size of the icon.
     *
     * @deprecated
     */
    iconSize: {
      type: String,
      validator(val: string) {
        return ['xx-small', 'x-small', 'small', 'medium', 'large'].includes(
          val
        );
      },
      default: 'medium',
    },
    /**
     * If omitted, icon position is centered.
     *
     * @deprecated
     */
    iconPosition: {
      type: String,
      validator(val: string) {
        return ['left', 'right'].includes(val);
      },
    },
    /**
     * Associates an icon button with another element on the page by changes the color of the SVG. Please reference <a href="http://www.lightningdesignsystem.com/components/buttons/#hint">Lightning Design System Buttons > Hint</a>.
     */
    hint: Boolean,

    /**
     * CSS classes to be added to icon.
     *
     * @deprecated
     */
    iconClassName: {},

    /**
     * HTML title attribute
     */
    title: String,
    /**
     * Button type
     */
    type: {
      type: String,
      validator(val: string) {
        return ['reset', 'submit', 'button'].includes(val);
      },
      default: 'button',
    },
    /**
     * If true, button/icon is white. Meant for buttons or utility icons on dark backgrounds.
     */
    inverse: Boolean,

    selected: {
      type: Boolean,
      default: undefined,
    },
    href: {
      type: String as PropType<string | undefined>,
    },
    to: {
      type: [String, Object] as PropType<RawLocation>,
    },
    stretch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tagName(): string {
      if (this.href) return 'a';
      if (this.to) return 'router-link';
      return 'button';
    },
    isIcon(): boolean {
      return this.variant === 'icon';
    },
    computedType(): string | undefined {
      if (this.href || this.to) return undefined;
      return this.type;
    },
    classNames(): ClassNames {
      const iconMore = this.iconVariant === 'more';
      const iconBorder = this.iconVariant === 'border';

      const plainInverseBtn = this.inverse && !this.isIcon;
      const plainInverseIcon =
        this.inverse && this.isIcon && !iconMore && !iconBorder;
      const moreInverseIcon = this.inverse && iconMore;
      const borderInverseIcon = this.inverse && iconBorder;

      const { iconVariant } = this;

      return {
        'slds-button': this.variant !== 'link',
        [`slds-button_${this.variant}`]:
          this.variant !== 'base' && this.inverse !== true,
        'slds-button_inverse': plainInverseBtn,
        'slds-button_icon-inverse': plainInverseIcon || moreInverseIcon,
        'slds-button_icon-border-inverse': borderInverseIcon,
        [`slds-button_icon-${iconVariant}`]:
          iconVariant !== undefined && !borderInverseIcon,
        // If icon has a container, then we apply the icon size to the container
        // not the svg. Icon size is medium by default, so we don't need to
        // explicitly render it here.
        [`slds-button_icon-${this.iconSize}`]:
          iconVariant === 'container' ||
          (iconVariant === 'border' && this.iconSize !== 'medium'),
        'slds-button_reset': this.variant === 'link',
        'slds-text-link': this.variant === 'link',
        'slds-is-selected': this.selected,
        'slds-button_stretch': this.stretch,
      };
    },
  },
});
</script>
