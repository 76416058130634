import Vue from 'vue';

import { hasSlot, normalizeSlot } from '@/util';

export default Vue.extend({
  methods: {
    hasSlot,
    normalizeSlot,
  },
});
