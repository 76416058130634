export enum PopoverSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  FullWidth = 'full-width',
}

export enum PopoverVariant {
  Error = 'error',
  Warning = 'warning',
}
