import { formatDistance } from 'date-fns';

export const ONE_HOUR = 3600000;
export const ONE_MINUTE = 60000;
export const FIVE_SECONDS = 5000;

export function format(since: Date | string | null): string {
  if (since === null) {
    return 'Never';
  }

  return formatDistance(
    typeof since === 'string' ? new Date(since) : since,
    new Date(),
    {
      addSuffix: true,
      includeSeconds: true,
    }
  );
}

export function timeout(date: Date) {
  const diff = new Date().getTime() - date.getTime();
  if (diff < ONE_MINUTE) {
    return FIVE_SECONDS;
  }
  if (diff < ONE_HOUR) {
    return ONE_MINUTE;
  }
  return ONE_HOUR;
}
