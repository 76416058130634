<script lang="ts">
import type { PropType, VNode } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithRefs } from 'vue-typed-refs';
import type { WithProperties } from 'vue-typed-properties';
import { Wormhole } from 'portal-vue';
import { directive as clickOutside } from 'v-click-outside';

import { PopperMixin } from '@/mixins';
import { uid } from '@/util';

import ButtonIcon from './ButtonIcon/ButtonIcon';
import ButtonIconIcon from './ButtonIcon/ButtonIconIcon';

export const SIZES = [
  'base',
  'xx-small',
  'x-small',
  'small',
  'medium',
  'large',
] as const;

export type Size = (typeof SIZES)[number];

type Refs = {
  root: HTMLDivElement;
  dropdown: HTMLDivElement;
};

interface Events {
  show: undefined;
  shown: undefined;
}

export default (
  PopperMixin as WithEvents<
    Events,
    WithRefs<Refs, WithProperties<{ active: boolean }, typeof PopperMixin>>
  >
).extend({
  name: 'SldsMenu',
  directives: {
    clickOutside,
  },
  props: {
    uid: {
      type: String,
      default: uid,
    },
    tag: {
      type: String,
      default: 'div',
    },
    size: {
      type: String as PropType<Size>,
      default: 'base' as const,
      validator: (val) => SIZES.includes(val),
    },
    withNubbin: {
      type: Boolean,
      default: false,
    },
    placement: {
      default: 'bottom-start',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    activatorAttrs(): Record<string, string | boolean> {
      return { 'aria-haspopup': 'true', title: 'Show More' };
    },
    activatorOn(): { click: () => void } {
      return {
        click: this.toggle,
      };
    },
    portalFrom(): string {
      return `Menu-${this.uid}`;
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (value) {
        this.$emit('show');
        this._transport();
        this.$nextTick(() => {
          this.createPopperInstance(this.$el, this.$refs.dropdown);
          this.$emit('shown');
          this.active = true;
        });
      } else {
        this.destroy();
      }
    },
  },
  created() {
    this.active = false;
  },
  updated() {
    if (this.active) {
      this._transport();
    }
  },
  beforeDestroy() {
    if (this.isOpen) {
      this.destroy();
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    hide() {
      this.isOpen = false;
    },
    clickOutsideMiddleware(e: MouseEvent) {
      const { target } = e;

      if (target) {
        if (this.$el.contains(target as Node)) {
          return false;
        }
      }

      return true;
    },
    onClick() {
      this.hide();
    },
    content(): VNode {
      const h = this.$createElement;
      return h(
        'div',
        {
          ref: 'dropdown',
          key: this.portalFrom,
          attrs: {
            id: this.portalFrom,
          },
          directives: [
            {
              name: 'click-outside',
              value: {
                handler: this.hide,
                middleware: this.clickOutsideMiddleware,
              },
            },
          ],
          on: {
            click: this.onClick,
          },
          class: {
            [`slds-dropdown_${this.size}`]: this.size !== 'base',
            [`slds-nubbin_${this.getNubbin()}`]: this.withNubbin,
          },
          staticClass: 'slds-dropdown bc-dropdown',
        },
        [
          this.$slots.prepend,
          h(
            'ul',
            {
              attrs: { role: 'menu', 'aria-label': 'Show More' },
              staticClass: 'slds-dropdown__list',
            },
            this.$slots.default
          ),
        ]
      );
    },
    destroy() {
      Wormhole.close({
        to: 'slds-menu',
        from: this.portalFrom,
      });
      this.active = false;
    },
    hasArrow() {
      return this.withNubbin;
    },
    _transport() {
      Wormhole.open({
        to: 'slds-menu',
        from: this.portalFrom,
        passengers: [this.content()],
      });
    },
  },
  render(h): VNode {
    const { activator } = this.$scopedSlots;

    return h(
      this.tag,
      {
        ref: 'root',
        staticClass: 'slds-dropdown-trigger slds-dropdown-trigger_click',
        class: {
          'slds-is-open': this.isOpen,
        },
      },
      [
        activator !== undefined
          ? activator({
              attrs: this.activatorAttrs,
              on: this.activatorOn,
              isOpen: this.isOpen,
            })
          : h(
              ButtonIcon,
              {
                props: {
                  variant: 'border-filled',
                  size: 'x-small',
                  title: 'Open Menu',
                },
                attrs: this.activatorAttrs,
                on: this.activatorOn,
              },
              [h(ButtonIconIcon, { props: { name: 'down' } })]
            ),
      ]
    );
  },
});
</script>

<style lang="scss">
// TODO: Maybe remove using PostCSS
.bc-dropdown {
  top: 0;
  margin: {
    top: 0 !important;
    bottom: 0 !important;
  }
  z-index: 10000;
}
</style>
