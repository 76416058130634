import type { HighlightRange, HighlightChunk } from './Highlight.types';

export function rangesOf(text: string, query: string): HighlightRange[] {
  const lowercasedText = text.toLowerCase();
  const lowercasedQuery = query.toLowerCase();
  const result: HighlightRange[] = [];
  let i = -1;
  while ((i = lowercasedText.indexOf(lowercasedQuery, i + 1)) >= 0) {
    result.push({ start: i, end: i + query.length });
  }
  return result;
}

export function getHighlightChunks(
  text: string,
  indices: HighlightRange[]
): HighlightChunk[] {
  const result: HighlightChunk[] = [];
  let previousRange: HighlightRange = { start: 0, end: 0 };
  let lastEnd = 0;
  for (const range of indices) {
    const notHighlighted = text.substring(previousRange.end, range.start);
    const highlighted = text.substring(range.start, range.end);
    previousRange = range;
    result.push({ text: notHighlighted, highlighted: false });
    result.push({ text: highlighted, highlighted: true });
    lastEnd = range.end;
  }
  if (lastEnd !== text.length) {
    result.push({
      text: text.substring(lastEnd, text.length),
      highlighted: false,
    });
  }
  return result;
}
