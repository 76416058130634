<template>
  <li :id="id" class="slds-setup-assistant__item">
    <article class="slds-setup-assistant__step">
      <div :class="{ 'slds-is-open': expanded, 'slds-summary-detail': true }">
        <SldsButtonIcon
          :title="`Toggle details for ${heading}`"
          :class="[
            'slds-m-right_x-small',
            { 'slds-m-top_x-small': hasFigure },
            { 'slds-hidden': expandable === false },
          ]"
          @click="toggle"
        >
          <SldsButtonIconIcon
            name="switch"
            class="slds-summary-detail__action-icon"
          />
        </SldsButtonIcon>
        <div class="slds-container_fluid">
          <div class="slds-summary-detail__title">
            <div class="slds-setup-assistant__step-summary">
              <div class="slds-media">
                <div
                  v-if="progress !== undefined || hasSlot('figure')"
                  class="slds-media__figure"
                >
                  <slot name="figure">
                    <SldsProgressRing
                      v-if="progress !== undefined"
                      :theme="computedProgressRingTheme"
                      :value="progress"
                      :flow-direction="progressRingFlowDirection"
                      size="large"
                    >
                      <template v-if="progress !== 100">
                        {{ number }}
                      </template>
                    </SldsProgressRing>
                  </slot>
                </div>
                <SldsSetupAssistantStepSummary :figure="hasFigure">
                  <div
                    class="slds-setup-assistant__step-summary-content slds-media__body"
                  >
                    <h3
                      class="slds-setup-assistant__step-summary-title slds-text-heading_small"
                    >
                      <button
                        v-if="expandable"
                        class="slds-button slds-button_reset"
                        @click="toggle"
                      >
                        <slot name="heading">{{ heading }}</slot>
                      </button>
                      <slot v-else name="heading">{{ heading }}</slot>
                    </h3>
                    <slot name="description">
                      <p>{{ description }}</p>
                    </slot>
                  </div>
                  <div class="slds-media__figure slds-media__figure_reverse">
                    <slot name="action" />
                  </div>
                </SldsSetupAssistantStepSummary>
              </div>
            </div>
          </div>
          <div
            v-if="hasSlot('default')"
            :id="`${id}-detail-content`"
            class="slds-summary-detail__content"
          >
            <div class="slds-setup-assistant__step-detail">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </article>
  </li>
</template>

<script lang="ts">
import Vue from 'vue';

import { generateId, hasSlot } from '@/util';

import SldsButtonIcon from './ButtonIcon/ButtonIcon';
import SldsButtonIconIcon from './ButtonIcon/ButtonIconIcon';
import SldsProgressRing from './ProgressRing.vue';

const SldsSetupAssistantStepSummary = Vue.extend({
  name: 'SldsSetupAssistantStepSummary',
  functional: true,
  props: {
    figure: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { props, children }) {
    if (props.figure) {
      return h(
        'div',
        { attrs: { class: 'slds-media__body slds-m-top_x-small' } },
        [h('div', { attrs: { class: 'slds-media' } }, children)]
      );
    }
    return h('div', undefined, children);
  },
});

export default Vue.extend({
  name: 'SldsSetupAssistantStep',
  components: {
    SldsButtonIcon,
    SldsButtonIconIcon,
    SldsProgressRing,
    SldsSetupAssistantStepSummary,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    id: {
      type: String,
      default: () => generateId(),
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      default: undefined,
    },
    /**
     * Override the theme for the progress ring component. If not set, the
     * theme is computed based on the progress percentage.
     */
    progressRingTheme: {
      type: String,
      validator(val: string) {
        return ['active', 'warning', 'expired', 'complete'].includes(val);
      },
    },
    /**
     * Direction that the progress ring "flows." Default is counter-clockwise,
     * or `drain`. For clockwise flow, use `fill`. If not set, defaults
     * to `fill`.
     */
    progressRingFlowDirection: {
      type: String,
      validator(val: string) {
        return ['drain', 'fill'].includes(val);
      },
      default: 'fill',
    },
    number: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      expanded: this.open,
    };
  },
  computed: {
    computedProgressRingTheme() {
      if (this.progressRingTheme) {
        return this.progressRingTheme;
      } else if (this.progress > 0 && this.progress < 100) {
        return 'active';
      } else if (this.progress >= 100) {
        return 'complete';
      } else {
        return undefined;
      }
    },
    hasFigure(): boolean {
      return this.progress !== undefined || this.hasSlot('figure');
    },
  },
  watch: {
    open(value: boolean) {
      this.expanded = value;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    hasSlot,
  },
});
</script>

<style lang="scss">
.slds-setup-assistant__item {
  &:last-child,
  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
</style>
