<template>
  <div class="slds-progress_vertical">
    <ol
      class="slds-progress__list"
      :class="{ 'slds-progress__list-bordered': bordered }"
    >
      <ProgressIndicatorStepVertical
        v-for="step of steps"
        :key="step.id"
        :step="step"
        :state="state(step)"
      >
        <slot :step="step" />
      </ProgressIndicatorStepVertical>
    </ol>
    <div
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="progress"
      role="progressbar"
    >
      <span class="slds-assistive-text">Progress: {{ progress }}%</span>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';

import type { Step, StepId } from './ProgressIndicator/ProgressIndicator.types';
import { StepState } from './ProgressIndicator/ProgressIndicator.types';
import ProgressIndicatorStepVertical from './ProgressIndicatorStepVertical.vue';

export default Vue.extend({
  name: 'ProgressIndicator',
  components: {
    ProgressIndicatorStepVertical,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    steps: {
      type: Array as PropType<Step[]>,
      required: true,
    },
    selected: {
      type: Set as PropType<Set<StepId>>,
      default() {
        return new Set();
      },
    },
    completed: {
      type: Set as PropType<Set<StepId>>,
      default() {
        return new Set();
      },
    },
    error: {
      type: Set as PropType<Set<StepId>>,
      default() {
        return new Set();
      },
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentStep(): number {
      const found = this.steps.findIndex(
        (step) => this.selected.has(step.id) || step.state === StepState.Active
      );
      if (found !== -1) {
        return found;
      }
      return 0;
    },
    progress(): number {
      let i = this.steps.length - 1;
      while (i >= 0) {
        const step = this.steps[i];
        if (this.completed.has(step.id) || step.state === StepState.Complete)
          break;
        i -= 1;
      }
      return ((i + 1) / this.steps.length) * 100;
    },
  },
  methods: {
    state(step: Step) {
      if (step.state !== undefined) return step.state;
      const { id } = step;
      if (this.currentStep) if (this.error.has(id)) return StepState.Error;
      if (this.selected.has(id)) return StepState.Active;
      if (this.completed.has(id)) return StepState.Complete;
      return undefined;
    },
  },
});
</script>
