export enum SpinnerSize {
  XXSmall = 'xx-small',
  XSmall = 'x-small',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum SpinnerColor {
  Inverse = 'inverse',
  Brand = 'brand',
}

export interface SpinnerProps {
  size?: SpinnerSize;
  color?: SpinnerColor;
  inline?: boolean;
  delayed?: boolean;
  container?: boolean;
  fixed?: boolean;
  assistiveText?: string;
  tag?: string;
}
