import type { PropType } from 'vue';

import SlotMixin from '@/mixins/slot.mixin';
import { isDefined } from '@/util';

import type { ActivityTimelineItem } from './ActivityTimeline.types';

export default SlotMixin.extend({
  name: 'ActivityTimelineItem',
  props: {
    item: {
      type: Object as PropType<ActivityTimelineItem>,
      required: true,
    },
  },
  render(h) {
    const { item } = this;
    return h('li', undefined, [
      h('div', undefined, [
        h('div', { staticClass: 'slds-media' }, [
          h(
            'div',
            { staticClass: 'slds-media__figure' },
            this.normalizeSlot('icon')
          ),
          h('div', { staticClass: 'slds-media__body' }, [
            h('div', { staticClass: 'slds-grid slds-grid_align-spread' }, [
              h(
                'div',
                {
                  staticClass:
                    'slds-grid slds-grid_vertical-align-center slds-truncate_container_75 slds-no-space',
                },
                [
                  h(
                    'h3',
                    { staticClass: 'slds-truncate' },
                    this.hasSlot('title')
                      ? this.normalizeSlot('title', { item })
                      : item.title
                  ),
                ]
              ),
              isDefined(item.date) || this.hasSlot('date')
                ? h(
                    'div',
                    {
                      staticClass:
                        'slds-timeline__actions slds-timeline__actions_inline',
                    },
                    isDefined(item.date) && this.hasSlot('date') === false
                      ? h(
                          'time',
                          {
                            staticClass: 'slds-timeline__date',
                            attrs: { datetime: item.date.toISOString() },
                          },
                          item.date
                        )
                      : this.normalizeSlot('date', { date: item.date })
                  )
                : null,
            ]),
            this.normalizeSlot('body', { item }),
          ]),
        ]),
      ]),
    ]);
  },
});
