import type { RawLocation } from 'vue-router';

export enum ButtonIconVariant {
  Brand = 'brand',
  Inverse = 'inverse',
  Container = 'container',
  BorderFilled = 'border-filled',
  Border = 'border',
  BorderInverse = 'border-inverse',
}

export enum ButtonIconIconSize {
  Large = 'large',
  Small = 'small',
  XSmall = 'x-small',
  // Blue Canvas
  Medium = 'medium',
}

export enum ButtonIconSize {
  Small = 'small',
  XSmall = 'x-small',
  XXSmall = 'xx-small',
}

export interface ButtonIconProps {
  variant?: ButtonIconVariant;
  size?: ButtonIconSize;
  title: string;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  dropdown?: boolean;
  href?: string;
  to?: RawLocation;
}

export interface ButtonIconIconProps {
  size?: ButtonIconIconSize;
  hint?: boolean;
  inverseHint?: boolean;
  position?: ButtonIconIconPosition;
}

export enum ButtonIconIconPosition {
  Left = 'left',
  Right = 'right',
}
