import Vue, { VNode } from 'vue';

import InfoModal from './components/InfoModal.vue';

export default function info(
  message: string | VNode[],
  options: { title: string }
): void {
  const div = document.createElement('div');
  document.body.append(div);
  const Extended = Vue.extend(InfoModal);
  const instance = new Extended({
    propsData: {
      text: typeof message === 'string' ? message : undefined,
      title: options.title,
    },
  });
  if (typeof message !== 'string') {
    instance.$slots.default = message;
  }
  instance.$mount(div);
  instance.$once('close', () => {
    instance.$destroy();
    instance.$el.remove();
  });
}
