import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsBreadcrumbs',
  functional: true,
  render(h, { data, children }) {
    return h(
      'nav',
      mergeData(data, {
        attrs: { role: 'navigation', 'aria-label': 'Breadcrumbs' },
      }),
      [
        h(
          'ol',
          {
            staticClass: 'slds-breadcrumb slds-list_horizontal slds-wrap',
          },
          children.map((child, index) =>
            h(
              'li',
              {
                key: child.key,
                staticClass: 'slds-breadcrumb__item',
                attrs: {
                  'aria-current':
                    index === children.length - 1 ? 'page' : undefined,
                },
              },
              [child]
            )
          )
        ),
      ]
    );
  },
});
