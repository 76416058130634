import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { IconType } from './Icon.types';

const SPRITE_BY_CATEGORY: Record<IconType, NodeRequire> = {
  [IconType.Utility]: require('@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg'),
  [IconType.Doctype]: require('@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg'),
  [IconType.Standard]: require('@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg'),
  [IconType.Custom]: require('@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg'),
  [IconType.Action]: require('@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg'),
};

export default Vue.extend({
  name: 'SldsBaseIcon',
  functional: true,
  props: {
    type: {
      type: String as PropType<IconType>,
      default: IconType.Utility,
      validator: (val) => Object.values(IconType).includes(val),
    },
    name: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data }) {
    const use = h('use', {
      attrs: {
        'xlink:href': `${SPRITE_BY_CATEGORY[props.type]}#${props.name}`,
      },
    });

    return h('svg', mergeData(data, { attrs: { 'aria-hidden': 'true' } }), [
      use,
    ]);
  },
});
