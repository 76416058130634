<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

function getWidth(value?: string | number) {
  if (typeof value === 'string') {
    return value;
  }

  if (typeof value === 'number') {
    return `${value}px`;
  }

  return null;
}

const BUMP = ['left', 'right'] as const;

export default Vue.extend({
  name: 'SldsCol',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    of: {
      type: [String, Number],
      default: '12',
    },
    large: {
      type: [String, Number],
      default: undefined,
    },
    size: {
      type: [String, Number],
      default: undefined,
    },
    width: {
      type: [String, Number],
      default: undefined,
    },
    grow: {
      type: Boolean,
      default: undefined,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    bump: {
      type: String as PropType<(typeof BUMP)[number]>,
      default: undefined,
      validator: (val) => BUMP.includes(val),
    },
  },
  render(h, { props, data, children }) {
    const { tag, of, size, large, width, grow, shrink, bump } = props;

    return h(
      tag,
      mergeData(data, {
        staticClass: 'bc-col',
        class: {
          'slds-col': shrink === false,
          [`slds-size_${size}-of-${of}`]: size,
          [`slds-large-size_${large}-of-${of}`]: large,
          'slds-grow': grow === true,
          'slds-shrink-none': width || shrink !== false,
          'slds-grow-none': width || grow === false,
          [`slds-col_bump-${bump}`]: bump,
        },
        style: {
          width: getWidth(width),
        },
      }),
      children
    );
  },
});
</script>

<style lang="scss">
.bc-col {
  min-width: 0;
}
</style>
