<script lang="ts">
import Vue from 'vue';
import { DocumentNode } from 'graphql';
import { VueApolloQueryDefinition } from '@vue/apollo-option/types/options';
import {
  ApolloError,
  MutationOptions,
  ServerError,
  ServerParseError,
} from '@apollo/client/core';

function logError(kind: string, name: string, err: ApolloError): void {
  if (err.graphQLErrors && err.graphQLErrors.length !== 0) {
    console.error(`GraphQL execution errors for ${kind} '${name}':`);
    for (const e of err.graphQLErrors) {
      console.error(e);
    }
  } else if (err.networkError) {
    console.error(`Error sending ${kind} '${name}`, err.networkError);
  } else {
    console.error(`An error has occurred for ${kind} '${name}':`);
    if (Array.isArray(err)) {
      console.error(...err);
    } else {
      console.error(err);
    }
  }
}

function isDocumentNode(o: any): o is DocumentNode {
  return typeof o === 'object' && o !== null && o.kind === 'Document';
}

function isQueryOptions(o: any): o is VueApolloQueryDefinition {
  return typeof o === 'object' && o !== null && o.query !== undefined;
}

const PREDEFINED_STATUS_CODE_MESSAGES: Record<number, string> = {
  401: 'HTTP 401: Session expired, please log in again',
  502: 'HTTP 502: Server timeout',
};

function isServerError(err: Error): err is ServerError | ServerParseError {
  return ['ServerError', 'ServerParseError'].includes(err.name);
}

/**
 * Abstract base component. Provides interaction with Apollo GraphQL to
 * simplify common behaviors.
 *
 * This base class is responsible for:
 *  - Error state
 *  - Loading state
 *  - Default error handling
 */
export default Vue.extend({
  data() {
    return {
      apolloConsumer: {
        /**
         * The last GraphQL error, if any.
         */
        error: null as ApolloError | null,

        /**
         * Whether a manual GraphQL request is currently in flight.
         */
        loading: false,
      },
    };
  },

  computed: {
    /**
     * Whether any GraphQL request is currently in flight.
     */
    gqlLoading(): boolean {
      return this.$apollo.loading || this.apolloConsumer.loading;
    },

    /**
     * The last GraphQL error, if any.
     */
    gqlError(): ApolloError | null {
      return this.apolloConsumer.error;
    },
  },

  /**
   * Injects a SmartQuery error handler to capture errors in `gqlError`.
   * If a custom error handler is configured, it is left unchanged.
   */
  beforeCreate() {
    if (!this.$options.apollo) return;

    for (const key of Object.keys(this.$options.apollo)) {
      const o = this.$options.apollo[key];
      if (isDocumentNode(o)) {
        throw new Error(
          `ApolloConsumer: Smart query this.apollo.${key} must be declared as ` +
            `{ query: require("schema.graphql") } instead of ` +
            `require("schema.graphql")`
        );
      }

      if (!isQueryOptions(o)) {
        continue;
      }

      if (o.error === undefined) {
        o.error = function defaultErrorHandler(this: any, err) {
          this.onApolloError(err);
        };
      }
    }
  },

  methods: {
    /**
     * Like `$apollo.mutate()` but with better error handling and with options
     * that more closely resemble the full set of QueryOptions.
     */
    async mutate<R, TVariables>(options: MutationOptions<R, TVariables>) {
      this.apolloConsumer.error = null;
      this.apolloConsumer.loading = true;

      try {
        const resp = await this.$apollo.mutate(options);
        return resp.data;
      } catch (err) {
        if (err instanceof ApolloError) {
          this.onApolloError(err);
        }
        throw err;
      } finally {
        this.apolloConsumer.loading = false;
      }
    },
    onApolloError(err: ApolloError) {
      logError('mutation', '...', err);
      const { networkError } = err;
      if (
        networkError !== null &&
        isServerError(networkError) &&
        networkError.statusCode in PREDEFINED_STATUS_CODE_MESSAGES
      ) {
        err.message = PREDEFINED_STATUS_CODE_MESSAGES[networkError.statusCode];
      }

      this.apolloConsumer.error = err;
    },
  },
});
</script>
