import Vue, { type PropType, type VNode } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithProperties } from 'vue-typed-properties';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonIconIcon from '../ButtonIcon/ButtonIconIcon';
import {
  ButtonIconIconSize,
  ButtonIconVariant,
} from '../ButtonIcon/ButtonIcon.types';
import Icon from '../Icon/Icon';
import { IconSize } from '../Icon/Icon.types';
import { type ToastEvents, ToastTheme } from './Toast.types';

export default (
  Vue as WithEvents<ToastEvents, WithProperties<{ timeoutId?: number }>>
).extend({
  name: 'SldsToast',
  props: {
    heading: {
      type: String,
    },
    value: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String as PropType<ToastTheme>,
      validator: (val) => Object.values(ToastTheme).includes(val),
      default: ToastTheme.Info,
    },
    icon: {
      type: String,
    },
    timeout: {
      type: Number,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    iconName(): string {
      if (this.icon) {
        return this.icon;
      }

      switch (this.theme) {
        case ToastTheme.Info:
          return 'info';
        case ToastTheme.Success:
          return 'success';
        case ToastTheme.Warning:
          return 'warning';
        case ToastTheme.Error:
          return 'error';
        default:
          return 'info';
      }
    },
  },
  watch: {
    value: {
      handler(value) {
        if (this.timeout && value) {
          this.timeoutId = window.setTimeout(this.hide, this.timeout);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.timeoutId !== undefined) {
      window.clearTimeout(this.timeoutId);
    }
  },
  methods: {
    /** @public */
    toggle() {
      this.$emit('input', !this.value);
    },
    /** @public */
    show() {
      this.$emit('input', true);
    },
    /** @public */
    hide() {
      this.$emit('input', false);
    },
  },
  render(h): VNode {
    if (this.value === false) {
      return h();
    }

    return h(
      'div',
      {
        attrs: { role: 'status' },
        class: `slds-theme_${this.theme}`,
        staticClass: 'slds-notify slds-notify_toast',
      },
      [
        h('span', { staticClass: 'slds-assistive-text' }, this.iconName),
        h(Icon, {
          props: {
            category: 'utility',
            name: this.iconName,
            size: IconSize.Small,
            color: null,
          },
          staticClass: 'slds-m-right_small slds-no-flex slds-align-top',
        }),
        h('div', { staticClass: 'slds-notify__content' }, [
          this.$slots.default ??
            h('h2', { staticClass: 'slds-text-heading_small' }, this.heading),
        ]),
        this.closeable
          ? h('span', { staticClass: 'slds-notify__close' }, [
              h(
                ButtonIcon,
                {
                  props: { variant: ButtonIconVariant.Inverse, title: 'Close' },
                  on: { click: this.toggle },
                },
                [
                  h(ButtonIconIcon, {
                    props: { name: 'close', size: ButtonIconIconSize.Large },
                  }),
                ]
              ),
            ])
          : null,
      ]
    );
  },
});
