import type { DefaultSelectOption } from '@/components/slds/Select.types';

export default function toSelectOptions<T extends string>(
  array: Iterable<T>
): DefaultSelectOption<T>[] {
  const res: DefaultSelectOption<T>[] = [];
  for (const item of array) {
    res.push({ value: item, label: item });
  }
  return res;
}
