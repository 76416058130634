import { getCurrentInstance } from '@/util';

export default function useToast() {
  const instance = getCurrentInstance();

  const toast = instance.proxy.$toast;

  function success(message = 'Changes successfully saved.') {
    toast.success(message, {
      timeout: 3000,
    });
  }

  const error = toast.error;

  return {
    toast,
    success,
    error,
  };
}
