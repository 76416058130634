export enum BadgeColor {
  Inverse = 'inverse',
  Lightest = 'lightest',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface BadgeProps {
  color?: BadgeColor;
}
