<template>
  <GlobalNavigation id="nav">
    <GlobalNavigationItem :to="{ name: 'branches' }" title="Dashboard" />
    <GlobalNavigationItem
      :to="{
        name: 'tree',
        params:
          preferred === null ? undefined : { branchName: preferred.branchName },
      }"
      title="Files"
    />
    <GlobalNavigationItem
      :to="{ name: 'activity' }"
      title="Activity"
      class="slds-show_medium"
    />
    <GlobalNavigationItem
      :to="{
        name: 'commits',
        params:
          preferred === null ? undefined : { branchName: preferred.branchName },
      }"
      title="History"
      class="slds-show_medium"
    />
    <GlobalNavigationItem
      :to="{ name: 'compare' }"
      title="Compare"
      class="slds-show_medium"
    />
    <GlobalNavigationItem
      :to="{ name: 'deployments.open' }"
      title="Deployments"
    />
  </GlobalNavigation>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

import { useBranches } from '@/composables';

import GlobalNavigation from './GlobalNavigation.vue';
import GlobalNavigationItem from './GlobalNavigationItem.vue';

export default defineComponent({
  name: 'RepoNavigation',
  components: {
    GlobalNavigation,
    GlobalNavigationItem,
  },
  setup() {
    const { preferred } = useBranches();
    return {
      preferred,
    };
  },
});
</script>
