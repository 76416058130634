<template>
  <div style="max-height: 80vh; overflow-y: auto">
    <WhatsnewMessageList>
      <WhatsnewMessage
        v-for="{ id, createTime, title, safeHtml } of changelogs"
        v-bind="{ id, title, safeHtml, createTime, lastReadTime, likedIds }"
        :key="id"
        @like="$emit('like', { id })"
      />
    </WhatsnewMessageList>

    <div v-if="gqlError" class="slds-text-color_error">
      {{ gqlError }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import { ChangelogFragment } from '@/graphql/types';

import WhatsnewMessage from './WhatsnewMessage.vue';
import WhatsnewMessageList from './WhatsnewMessageList.vue';

export default Vue.extend({
  components: {
    WhatsnewMessage,
    WhatsnewMessageList,
  },
  props: {
    changelogs: {
      type: Array as PropType<ChangelogFragment[]>,
      required: true,
    },
    gqlError: {
      type: String,
    },
    lastReadTime: {
      type: Date as PropType<Date>,
    },
    likedIds: {
      type: Array,
    },
  },
});
</script>
