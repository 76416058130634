<template>
  <div
    ref="el"
    class="markdown-view slds-rich-text-editor__output"
    @change="onChange"
    v-html="html"
  />
</template>

<script lang="ts">
import type { PropType } from '@vue/composition-api';
import { defineComponent, ref, toRefs, computed } from '@vue/composition-api';

import type { MarkdownString } from '@/types';
import { useListUsersForMarkdownViewQuery } from '@/graphql/types';
import { MENTION_RE } from '@/module/mentions/mentions.constants';
import { extractNode } from '@/util';

import type { EditableMarkdownHTMLConverter } from '../RichTextEditor/core/interfaces';
import {
  DefaultMarkdownHTMLConverter,
  HTMLMarkdownConverter,
} from '../RichTextEditor/core/converters';

export default defineComponent({
  name: 'MarkdownView',
  props: {
    value: {
      type: String as PropType<MarkdownString>,
      default: '',
    },
    converter: {
      type: Object as PropType<EditableMarkdownHTMLConverter>,
      default: () => new DefaultMarkdownHTMLConverter(),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    input: (markdown: MarkdownString) => typeof markdown === 'string',
  },
  setup(props, { emit }) {
    const { value, converter, editable } = toRefs(props);
    const hasMentions = computed(() => MENTION_RE.test(value.value));
    const { result } = useListUsersForMarkdownViewQuery(() => ({
      enabled: hasMentions.value,
    }));
    const users = computed(
      () => result.value?.users.edges.map(extractNode) ?? []
    );
    const html = computed(() =>
      converter.value.convert(value.value, users.value, editable.value)
    );
    const el = ref(null as unknown as HTMLElement);
    function onChange(e: InputEvent) {
      const traget = e.target as HTMLInputElement;
      if (traget.checked) {
        traget.setAttribute('checked', 'true');
      } else {
        traget.removeAttribute('checked');
      }
      const markdown = new HTMLMarkdownConverter().convert(el.value.innerHTML);
      emit('input', markdown);
    }
    return { html, el, onChange };
  },
});
</script>
