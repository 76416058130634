<template>
  <div
    class="slds-global-header slds-grid slds-grid_align-spread global-header"
  >
    <a
      href="/"
      class="slds-grid slds-grid_vertical-align-center slds-global-header__item slds-text-heading_medium slds-text-link_reset slds-show_medium"
    >
      <img
        src="@/assets/images/logo.svg"
        width="24"
        height="24"
        alt="Blue Canvas logo"
        class="slds-shrink-none slds-m-right_x-small"
      />
      Blue Canvas
    </a>
    <div class="slds-global-header__item global-header__navigation-slot">
      <slot name="navigation" />
    </div>
    <div class="slds-global-header__item">
      <ul class="slds-global-actions">
        <!-- Help -->
        <li class="slds-global-actions__item slds-show_medium">
          <slds-button
            variant="icon"
            icon-variant="container"
            icon-name="question"
            icon-size="small"
            icon-class-name="slds-global-header__icon"
            href="https://docs.bluecanvas.io/docs?utm_source=webapp&amp;utm_content=help"
            target="_blank"
            title="Help and Training"
            class="slds-global-actions__item-action slds-global-actions__help"
          ></slds-button>
        </li>

        <!-- What's New -->
        <li class="slds-global-actions__item slds-show_medium">
          <Whatsnew />
        </li>

        <!-- Avatar -->
        <li class="slds-global-actions__item">
          <slds-menu placement="bottom-end" size="small" with-nubbin>
            <template #activator="{ attrs, on }">
              <slds-button
                v-bind="attrs"
                variant="base"
                class="slds-global-actions__avatar slds-global-actions__item-action"
                v-on="on"
              >
                <span class="slds-avatar slds-avatar_circle">
                  <slds-icon
                    category="standard"
                    name="user"
                    title="Show User Menu"
                  />
                </span>
              </slds-button>
            </template>
            <SldsMenuItem :to="{ name: 'profile' }" :title="profile.email">{{
              profile.email
            }}</SldsMenuItem>
            <slds-menu-divider />
            <SldsMenuItem :to="{ name: 'settings.branches' }" title="Settings"
              ><template #icon-left><SldsMenuIcon name="settings" /></template
              >Settings</SldsMenuItem
            >
            <SldsMenuItem
              v-if="profile.canSwitchRoles"
              href="/auth/oidc/switch-role"
              title="Switch Role"
              ><template #icon-left
                ><SldsMenuIcon name="change_owner" /></template
              >Switch Role</SldsMenuItem
            >
            <SldsMenuItem href="/logout" title="Logout"
              ><template #icon-left><SldsMenuIcon name="logout" /></template
              >Logout</SldsMenuItem
            >
          </slds-menu>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api';

import { SldsMenuItem, SldsMenuIcon } from '@ui';
import { useProfile } from '@/composables';

import Whatsnew from '@/module-next/whatsnew/Whatsnew.vue';

export default defineComponent({
  components: {
    SldsMenuItem,
    SldsMenuIcon,
    Whatsnew,
  },
  setup() {
    const { profile } = useProfile();

    return {
      profile,
    };
  },
});
</script>

<style lang="scss">
.global-header {
  &__navigation-slot {
    margin: {
      right: auto;
      bottom: -9px;
    }
  }
}
</style>
