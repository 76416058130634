<template>
  <div class="slds-section" :class="className">
    <h3 class="slds-section__title">
      <slds-button
        type="button"
        variant="base"
        icon-name="switch"
        icon-class-name="slds-section__title-action-icon"
        class="slds-section__title-action"
        @click="toggle"
      >
        <slot name="title"
          ><span class="slds-truncate" :title="title">{{ title }}</span></slot
        >
      </slds-button>
    </h3>
    <div v-if="expanded" class="slds-section__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

import SldsButton from './Button.vue';

export default Vue.extend({
  name: 'SldsExpandableSection',
  components: {
    SldsButton,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    className(): string {
      return this.expanded ? 'slds-is-open' : 'slds-is-closed';
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
});
</script>
