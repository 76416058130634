<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import SldsPillContainer from '../slds/PillContainer/PillContainer';

export default Vue.extend({
  name: 'BcPillContainer',
  functional: true,
  render(h, { data, children }) {
    return h(
      SldsPillContainer,
      mergeData(data, { staticClass: 'bc-pill-container' }),
      children
    );
  },
});
</script>

<style lang="scss">
@use '../../style/variables';

.bc-pill-container {
  .slds-listbox_horizontal {
    padding: {
      top: variables.$spacing-xxx-small;
      bottom: variables.$spacing-xxx-small;
    }
    gap: 4px;

    li:first-child {
      padding-left: variables.$spacing-xxx-small;
    }
  }
}
</style>
