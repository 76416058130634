<script lang="ts">
import type { PropType, VNode } from 'vue';
import type { WithProperties } from 'vue-typed-properties';
import type { WithRefs } from 'vue-typed-refs';
import { Wormhole } from 'portal-vue';
import { directive } from 'v-click-outside';

import type { ClassNames, VueComponentListeners } from '@/types';
import { PopperMixin } from '@/mixins';
import { hasSlot, normalizeSlot, generateId } from '@/util';

import {
  ButtonIconVariant,
  ButtonIconSize,
} from './ButtonIcon/ButtonIcon.types';
import { PopoverSize, PopoverVariant } from './Popover/Popover.types';
import ButtonIcon from './ButtonIcon/ButtonIcon';
import ButtonIconIcon from './ButtonIcon/ButtonIconIcon';

type Refs = {
  popover: HTMLDivElement;
};

const DELAY = 400;

export default (
  PopperMixin as WithRefs<
    Refs,
    WithProperties<{ timeout?: number }, typeof PopperMixin>
  >
).extend({
  inheritAttrs: false,
  directives: {
    'click-outside': directive,
  },
  props: {
    uid: {
      type: String,
      default() {
        return generateId();
      },
    },
    headerUid: {
      type: String,
      default() {
        return generateId();
      },
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<PopoverVariant>,
      default: undefined,
      validator: (val: PopoverVariant) =>
        Object.values(PopoverVariant).includes(val),
    },
    size: {
      type: String as PropType<PopoverSize>,
      default: undefined,
      validator: (val) => Object.values(PopoverSize).includes(val),
    },
    header: {
      type: String,
      default: undefined,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
    popoverClass: {
      type: [Object, Array, String],
      default: undefined,
    },
    bodyClassName: {
      type: [String, Object, Array] as PropType<ClassNames>,
      default: undefined,
    },
    zIndex: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    portalFrom(): string {
      return `Popover-${this.uid}`;
    },
    onActivator(): VueComponentListeners {
      if (this.clickable) {
        return {
          click: this.toggle,
        };
      }

      return {
        mouseenter: this.onMouseEnter,
        mouseleave: this.onMouseLeave,
      };
    },
    bodyId() {
      return `tooltip-${this.uid}`;
    },
  },
  watch: {
    isOpen(value: boolean) {
      if (value) {
        this.updateWormhole();

        this.$nextTick(() => {
          this.createPopperInstance(this.$el, this.$refs.popover);
          this.$emit('open');
        });
      } else {
        this.destroyPopperInstance();
        Wormhole.close({
          to: 'slds-tooltip',
          from: this.portalFrom,
        });
        this.$emit('close');
      }
    },
  },
  created() {
    this.timeout = undefined;
  },
  /**
   * That's crucial. Please, don't remove it
   * Ensures that Wormhole always contains a fresh content
   */
  updated() {
    if (this.isOpen) {
      this.updateWormhole();
    }
  },
  beforeDestroy() {
    if (this.timeout !== undefined) {
      window.clearTimeout(this.timeout);
    }
  },
  methods: {
    show() {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    onMouseEnter() {
      this.show();
    },
    onMouseLeave() {
      this.timeout = window.setTimeout(() => {
        this.hide();
      }, DELAY);
    },
    renderTooltip(): VNode {
      const h = this.$createElement;

      const footer$ = this.hasSlot('footer')
        ? this.normalizeSlot('footer', { close: this.hide }) ?? null
        : null;

      return h(
        'div',
        {
          ref: 'popover',
          attrs: {
            role: 'dialog',
            'aria-describedby': this.bodyId,
            'aria-labelledby': this.header ? this.headerUid : undefined,
            ...this.$attrs,
          },
          staticClass: 'slds-popover',
          class: [
            `slds-nubbin_${this.getNubbin()}`,
            {
              [`slds-popover_${this.size}`]: this.size,
              [`slds-popover_${this.variant}`]: this.variant,
            },
            this.popoverClass,
          ],
          style: {
            '--slds-c-popover-position-zindex': this.zIndex,
          },
          on: this.clickable
            ? undefined
            : {
                mouseenter: this.onMouseEnter,
                mouseleave: this.onMouseLeave,
              },
          directives: this.clickable
            ? [
                {
                  name: 'click-outside',
                  value: this.hide,
                },
              ]
            : undefined,
        },
        [
          this.closeable
            ? h(
                ButtonIcon,
                {
                  props: {
                    variant:
                      this.variant === PopoverVariant.Error
                        ? ButtonIconVariant.Inverse
                        : ButtonIconVariant.Container,
                    size: ButtonIconSize.Small,
                    title: 'Close Dialog',
                  },
                  staticClass: 'slds-float_right slds-popover__close',
                  on: {
                    click: this.hide,
                  },
                },
                [
                  h(ButtonIconIcon, {
                    props: { name: 'close' },
                  }),
                ]
              )
            : undefined,
          this.header || this.hasSlot('header')
            ? h(
                'header',
                {
                  staticClass: 'slds-popover__header',
                  class: { 'slds-assistive-text': this.hideHeader },
                },
                [
                  this.hasSlot('header')
                    ? this.normalizeSlot('header')
                    : h(
                        'h2',
                        {
                          attrs: { id: this.headerUid },
                          staticClass: 'slds-text-heading_small',
                        },
                        this.hasSlot('header-content')
                          ? this.normalizeSlot('header-content')
                          : this.header
                      ),
                ]
              )
            : undefined,
          h(
            'div',
            {
              attrs: {
                id: this.bodyId,
              },
              class: this.bodyClassName,
              staticClass: 'slds-popover__body',
            },
            this.$slots.default
          ),
          footer$ !== null
            ? h('div', { staticClass: 'slds-popover__footer' }, [footer$])
            : null,
        ]
      );
    },
    updateWormhole() {
      Wormhole.open({
        to: 'slds-tooltip',
        from: this.portalFrom,
        passengers: [this.renderTooltip()],
      });
    },
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    const { activator = null } = this.$scopedSlots;
    if (activator === null) return h();
    const content =
      activator({
        on: this.onActivator,
        isOpen: this.isOpen,
      }) ?? null;
    if (content === null) {
      return h();
    }
    return content[0];
  },
});
</script>
