import type { Ref } from '@vue/composition-api';
import type { BranchName } from '@/types';
import {
  usePauseBranchMutation,
  useResumeBranchMutation,
} from '@/graphql/types';

export default function useBranchSyncState(name: Ref<BranchName>) {
  const { mutate: pause, loading: pausing } = usePauseBranchMutation(() => ({
    variables: {
      name: name.value,
    },
  }));
  const { mutate: resume, loading: resuming } = useResumeBranchMutation(() => ({
    variables: {
      name: name.value,
    },
  }));
  return {
    pause,
    pausing,
    resume,
    resuming,
  };
}
