export default function splice<T>(
  array: T[],
  start: number,
  count: number,
  ...items: T[]
): T[] {
  const copy = array.slice();
  copy.splice(start, count, ...items);
  return copy;
}
