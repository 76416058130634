import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { snakeToKebab } from '@/util';

import { IconType, IconSize, IconColor } from './Icon.types';
import BaseIcon from './BaseIcon';

export default Vue.extend({
  name: 'SldsIcon',
  functional: true,
  props: {
    type: {
      type: String as PropType<IconType>,
      default: IconType.Utility,
      validator: (val) => Object.values(IconType).includes(val),
    },
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String as PropType<IconSize>,
      default: undefined,
      validator: (val) => Object.values(IconSize).includes(val),
    },
    color: {
      type: String as PropType<IconColor | null>,
      default: undefined,
      validator: (val) =>
        val === null || Object.values(IconColor).includes(val),
    },
    title: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    flip: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'span',
      validator: (val) => val === 'span' || val === 'div',
    },
    iconClass: {},
  },
  render(h, { props, data }) {
    return h(
      props.tag,
      mergeData(data, {
        attrs: { title: props.title },
        staticClass: 'slds-icon_container',
        class: {
          [`slds-icon-${props.type}-${snakeToKebab(props.name)}`]: true,
          'slds-icon_container_circle': props.type === IconType.Action,
          'slds-current-color':
            props.type === IconType.Utility &&
            props.color === IconColor.Current,
          'slds-icon_flip': props.flip,
          'slds-icon_disabled': props.disabled,
        },
      }),
      [
        h(BaseIcon, {
          staticClass: 'slds-icon',
          class: [
            {
              [`slds-icon_${props.size}`]: props.size !== undefined,
              [`slds-icon-text-${props.color}`]:
                props.type === IconType.Utility &&
                props.color !== undefined &&
                props.color !== null &&
                props.color !== IconColor.Current,
              'slds-icon-text-default':
                props.type === IconType.Utility && props.color === undefined,
            },
            props.iconClass,
          ],
          props: {
            type: props.type,
            name: props.name,
          },
        }),
        props.title !== undefined
          ? h('span', { staticClass: 'slds-assistive-text' }, props.title)
          : null,
      ]
    );
  },
});
