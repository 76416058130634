import { createPopper } from '@popperjs/core';
import type { PropType } from 'vue';
import Vue from 'vue';

import type { TableEditable } from './Table.types';

export default Vue.extend({
  name: 'TablePopover',
  props: {
    editable: {
      type: Object as PropType<TableEditable>,
      required: true,
    },
  },
  mounted() {
    createPopper(
      // @ts-expect-error Migrate to floating-ui
      { getBoundingClientRect: this.rect },
      this.$refs.popover
    );
  },
  methods: {
    rect() {
      const rect = this.editable.td.getBoundingClientRect();
      return {
        width: 0,
        height: 0,
        top: rect.y,
        right: rect.x,
        bottom: rect.y,
        left: rect.x + rect.width / 2,
      };
    },
  },
  render(h) {
    return h(
      'div',
      {
        ref: 'popover',
        staticClass:
          'slds-popover slds-popover slds-popover_edit slds-p-left_xx-small',
        style: {
          width: this.editable.column.width,
        },
      },
      [h('div', { staticClass: 'slds-popover__body' }, this.$slots.default)]
    );
  },
});
