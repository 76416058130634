import Vue, { VNode } from 'vue';

import { rangesOf, getHighlightChunks } from './Highlight.util';

export default Vue.extend({
  name: 'SldsHighlight',
  functional: true,
  props: {
    text: {
      type: String,
      required: true,
    },
    query: {
      type: String,
      required: true,
    },
  },
  render(h, { props, _v: createTextVNode }): VNode {
    const chunks = getHighlightChunks(
      props.text,
      rangesOf(props.text, props.query)
    );
    return h(
      'span',
      undefined,
      chunks.map((chunk) =>
        chunk.highlighted
          ? h('mark', undefined, chunk.text)
          : createTextVNode(chunk.text)
      )
    );
  },
});
