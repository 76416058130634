<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import SldsIcon from './Icon/Icon';
import type { Step } from './ProgressIndicator/ProgressIndicator.types';
import {
  StepState,
  STEP_STATE_TO_TITLE,
} from './ProgressIndicator/ProgressIndicator.types';

export default Vue.extend({
  name: 'ProgressIndicatorStepVertical',
  functional: true,
  props: {
    step: {
      type: Object as PropType<Step>,
      required: true,
    },
    state: {
      type: String as PropType<StepState>,
      default: undefined,
      validator: (val) => Object.values(StepState).includes(val),
    },
  },
  render(h, { props, data, children }) {
    const isComplete = props.state === StepState.Complete;
    const isError = props.state === StepState.Error;
    const hasIcon =
      props.state === StepState.Complete ||
      isError ||
      props.step.icon !== undefined;
    return h(
      'li',
      mergeData(data, {
        staticClass: 'slds-progress__item',
        class: [
          props.step.class,
          {
            'slds-is-completed': isComplete,
            'slds-is-active': props.state === StepState.Active,
            'slds-has-error': isError,
          },
        ],
      }),
      [
        h(
          'span',
          {
            staticClass: 'slds-progress__marker',
            class: {
              'slds-progress__marker_icon': hasIcon,
              'slds-progress__marker_icon-success': isComplete,
            },
          },
          [
            hasIcon
              ? h(SldsIcon, {
                  props: {
                    type: 'utility',
                    name:
                      props.step.icon?.name ?? (isError ? 'error' : 'success'),
                    size: 'x-small',
                    title:
                      props.step.icon?.title ??
                      (props.state !== undefined
                        ? STEP_STATE_TO_TITLE[props.state]
                        : undefined),
                  },
                })
              : null,
          ]
        ),
        h(
          'div',
          {
            staticClass:
              'slds-progress__item_content slds-grid slds-grid_align-spread',
          },
          children
        ),
      ]
    );
  },
});
</script>
