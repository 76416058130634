<template>
  <div class="slds-form-element">
    <div
      :class="[
        'slds-checkbox',
        'bc-checkbox',
        { 'bc-checkbox--hide-label': hideLabel },
        className,
      ]"
    >
      <input
        :id="uid"
        ref="input"
        type="checkbox"
        :checked="isChecked"
        :indeterminate.prop="indeterminate"
        v-bind="$attrs"
        v-on="inputListeners"
      />
      <label :for="uid" class="slds-checkbox__label" @mouseup="onMouseUp">
        <span class="slds-checkbox_faux"></span>
        <span
          :class="[
            'slds-form-element__label',
            { 'slds-assistive-text': hideLabel },
          ]"
          ><slot name="label">{{ label }}</slot></span
        >
      </label>
    </div>
    <div v-if="hasSlot('help')" class="slds-form-element__help">
      <slot name="help"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithRefs } from 'vue-typed-refs';
import type { WithProperties } from 'vue-typed-properties';

import type { VueComponentListeners } from '@/types';
import { generateId, hasSlot } from '@/util';

type Refs = {
  input: HTMLInputElement;
};

interface CheckboxEvents {
  change: boolean | string[];
}

export default (
  Vue as WithEvents<
    CheckboxEvents,
    WithRefs<Refs, WithProperties<{ uid: string }>>
  >
).extend({
  name: 'SldsCheckbox',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: [Boolean, Array] as PropType<boolean | string[]>,
      default: false,
    },
    value: {
      type: String,
    },
    indeterminate: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    hideLabel: {
      type: Boolean,
    },
    className: {
      type: [Object, Array, String],
      default: undefined,
    },
  },
  computed: {
    inputListeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: (event: InputEvent) => {
          event.preventDefault();

          const { checked } = this;
          const target = event.target as HTMLInputElement;

          if (typeof checked === 'boolean') {
            this.$emit('change', target.checked);
            return;
          }

          const set = new Set(checked);
          if (target.checked) {
            set.add(this.value);
          } else {
            set.delete(this.value);
          }

          this.$emit('change', [...set]);
        },
      };
    },
    isChecked(): boolean {
      const { checked, value } = this;
      if (typeof checked === 'boolean') {
        return checked;
      }

      return checked.includes(value);
    },
  },
  watch: {
    indeterminate() {
      this.$refs.input.checked = false;
    },
  },
  created() {
    this.uid = generateId();
  },
  methods: {
    /** Firefox doesn't emit a click event on `<label>` element when Shift is pressed */
    onMouseUp(e: MouseEvent) {
      if (e.shiftKey) {
        const isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        if (isFirefox) {
          this.$emit('change', !this.checked);
        }
      }
    },
    hasSlot,
  },
});
</script>

<style lang="scss">
// TODO: Remove when standalone checkbox is implemented
.bc-checkbox {
  &--hide-label {
    .slds-checkbox_faux {
      margin-right: 0 !important;
    }
  }
}
</style>
