import Vue, { VNode } from 'vue';
import type { WithProperties } from 'vue-typed-properties';

import type { VueComponentListeners } from '@/types';
import { generateId, hasSlot, normalizeSlot } from '@/util';
import type RadioGroup from './RadioGroup';

export default (
  Vue as WithProperties<{
    uid: string;
    group: InstanceType<typeof RadioGroup>;
  }>
).extend({
  name: 'SldsRadio',
  inject: ['group'],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked(): boolean {
      return this.group.value === this.value;
    },
    listeners(): VueComponentListeners {
      return {
        ...this.$listeners,
        change: () => {
          this.group.onCheck(this.value);
        },
      };
    },
    computedDisabled(): boolean {
      return this.disabled || this.group.disabled;
    },
  },
  created() {
    this.uid = generateId();
  },
  methods: {
    hasSlot,
    normalizeSlot,
  },
  render(h): VNode {
    return h('span', { staticClass: 'slds-radio' }, [
      h('input', {
        domProps: { checked: this.checked },
        attrs: {
          ...this.$attrs,
          id: this.uid,
          type: 'radio',
          value: this.value,
          name: this.group.name,
          disabled: this.computedDisabled,
        },
        on: this.listeners,
      }),
      h(
        'label',
        { attrs: { for: this.uid }, staticClass: 'slds-radio__label' },
        [
          h('span', { staticClass: 'slds-radio_faux' }),
          h('span', { staticClass: 'slds-form-element__label' }, this.label),
        ]
      ),
      this.hasSlot('append') ? this.normalizeSlot('append') : null,
    ]);
  },
});
