import {
  addDays,
  startOfYesterday,
  endOfYesterday,
  startOfToday,
  endOfToday,
} from 'date-fns';

import { ref, computed } from '@vue/composition-api';

function get() {
  return {
    startOfYesterday: startOfYesterday(),
    endOfYesterday: endOfYesterday(),
    startOfToday: startOfToday(),
    endOfToday: endOfToday(),
    ninetyDaysAgo: addDays(startOfToday(), -90),
  };
}

const periods = ref(get());

const ranges = computed(() => {
  return {
    Today: [periods.value.startOfToday, periods.value.endOfToday],
    Yesterday: [periods.value.startOfYesterday, periods.value.endOfYesterday],
    'Last 7 Days': [
      addDays(periods.value.startOfToday, -7),
      periods.value.endOfToday,
    ],
    'Last 30 Days': [
      addDays(periods.value.startOfToday, -30),
      periods.value.endOfToday,
    ],
    'Last 90 Days': [periods.value.ninetyDaysAgo, periods.value.endOfToday],
  };
});

document.addEventListener('visibilitychange', () => {
  if (document.hidden === false) {
    periods.value = get();
  }
});

export default function useDefaultDateRanges() {
  return {
    ranges,
  };
}
