export enum ToastTheme {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

export interface ToastProps {
  value?: boolean;
  heading?: string;
  theme?: ToastTheme;
  icon?: string;
  timeout?: number;
}

export interface ToastEvents {
  input: boolean;
}
