<template>
  <div class="slds-dropdown">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SldsDropdown',
};
</script>
