<script lang="ts">
import type { PropType } from '@vue/composition-api';
import {
  defineComponent,
  toRefs,
  provide,
  onMounted,
} from '@vue/composition-api';

import type { TenantFragment } from '@/graphql/types';
import { enableSessionWarning } from '@/apolloClient';
import { TENANT_INEJCTION_KEY } from '@/module/tenant';

export default defineComponent({
  name: 'AuthContainer',
  props: {
    tenant: {
      type: Object as PropType<TenantFragment>,
      required: true,
    },
  },
  setup(props, { slots }) {
    const { tenant } = toRefs(props);
    provide(TENANT_INEJCTION_KEY, tenant);
    onMounted(enableSessionWarning);
    return () => (slots.default !== undefined ? slots.default() : null);
  },
});
</script>
