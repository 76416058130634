import type { Nullable } from '@/types';

import BaseColumn from './BaseColumn';
import type { BaseColumnOptions } from './BaseColumn.types';
import type {
  TableItem,
  TableColumnWithSorting,
  TableColumnGetterFn,
  TableColumnFormatterFn,
  TableColumnSortFn,
} from './Table.types';
import type { SortStrategyConstructor } from './Table.sort';

export interface TableColumnOptions<T extends TableItem, U>
  extends BaseColumnOptions<T> {
  value: TableColumnGetterFn<T, U>;
  title: string;
}

export default class TableColumn<
  T extends TableItem = TableItem,
  U = unknown,
> extends BaseColumn<T> {
  readonly getter: TableColumnGetterFn<T, U>;
  public formatter: null | TableColumnFormatterFn<U>;
  public sorting: Nullable<{
    predicate: Nullable<TableColumnSortFn<T>>;
  }>;

  constructor(options: TableColumnOptions<T, U>) {
    const { value, ...rest } = options;
    super(rest);
    this.getter = value;
    this.formatter = null;
    this.sorting = null;
  }

  useFormatter(formatter: TableColumnFormatterFn<U>): this {
    this.formatter = formatter;
    return this;
  }

  useSortStrategy(Strategy: SortStrategyConstructor<T, U>): this {
    const strategy = new Strategy(this.getter);
    this.sorting = { predicate: strategy.sort.bind(strategy) };
    return this;
  }

  useSortPredicate(predicate: TableColumnSortFn<T>): this {
    this.sorting = { predicate };
    return this;
  }

  sortable(): this {
    this.sorting = { predicate: null };
    return this;
  }

  isSortable(): this is TableColumnWithSorting<T, U> {
    return this.sorting !== null;
  }
}
