import type { MarkedOptions } from 'marked';
import { Marked } from 'marked';

import type { MarkdownString, HTMLString } from '@/types';
import { MENTION_RE } from '@/module/mentions/mentions.constants';
import type { UserForRichTextEditor } from '@/module/rte/rte.types';
import { MENTION_CLASS_NAME } from '@/module/rte/rte.constants';

import { indexBy } from '@/util';

import type { EditableMarkdownHTMLConverter } from '../interfaces';
import type { ConstructorOptions } from './AbstractMarkdownHTMLConverter';
import AbstractHTMLMarkdownConverter from './AbstractMarkdownHTMLConverter';

declare module 'marked' {
  interface MarkedOptions {
    editable?: boolean;
  }
}

export default class DefaultMarkdownHTMLConverter
  extends AbstractHTMLMarkdownConverter
  implements EditableMarkdownHTMLConverter
{
  private readonly marked: Marked;

  constructor(options?: ConstructorOptions) {
    super(options);
    const marked = new Marked();
    marked.use({
      renderer: {
        listitem(text, task) {
          if (task) return `<li class="task-list-item">${text}</li>\n`;
          return `<li>${text}</li>\n`;
        },
        checkbox(this: { options: MarkedOptions }, checked) {
          return `<input ${checked ? 'checked="" ' : ''}${
            this.options.editable ? '' : 'disabled=""'
          } type="checkbox">`;
        },
      },
    });
    this.marked = marked;
  }

  convert(
    markdown: MarkdownString,
    users: UserForRichTextEditor[],
    editable = false
  ): HTMLString {
    const indexed = indexBy(users, 'email');
    const value = markdown.replace(MENTION_RE, (match, email) => {
      const found = indexed.get(email) ?? null;
      if (found === null) return match;
      return `<a href="mailto:${email}" class="${MENTION_CLASS_NAME}">@${found.displayName}</a>`;
    });
    const parsed = this.marked.parse(value, { editable });
    return this.sanitizer.sanitize(parsed);
  }
}
