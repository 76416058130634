import { computed } from '@vue/composition-api';

export default function usePropSync<
  T extends Record<string, unknown>,
  K extends Extract<keyof T, string>,
  E extends (event: `update:${K}`, value: T[K]) => void,
>(props: T, name: K, emit: E) {
  const model = computed<T[K]>({
    get: () => props[name],
    set: (v) => {
      emit(`update:${name}`, v);
    },
  });

  return model;
}
