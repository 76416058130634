import { useLocalStorage } from '@vueuse/core';

import type { Nullable, BranchName } from '@/types';
import { ProvisioningState } from '@/graphql/types';

import { useBookmarks } from '@/composables';

export default function usePreferredBranch() {
  const { bookmarks } = useBookmarks();
  const last = useLocalStorage<Nullable<BranchName>>('preferred-branch', null);

  function get<
    T extends { branchName: BranchName; provisioningState: ProvisioningState },
  >(branches: T[]): Nullable<T> {
    if (branches.length === 0) return null;
    if (branches.length === 1) return branches[0];
    if (last.value !== null) {
      const branch = branches.find((b) => b.branchName === last.value) ?? null;
      if (branch !== null) return branch;
    }
    for (const bookmark of bookmarks.value) {
      const branch = branches.find((b) => b.branchName === bookmark) ?? null;
      if (branch !== null) return branch;
    }
    for (const branch of branches) {
      if (branch.provisioningState === ProvisioningState.SUCCEEDED)
        return branch;
    }
    return branches[0];
  }

  function set(branchName: BranchName) {
    last.value = branchName;
  }

  return {
    get,
    set,
  };
}
