<template>
  <fieldset
    class="slds-form-element"
    :class="{ 'slds-form-element_stacked': stacked, 'slds-has-error': error }"
  >
    <legend class="slds-form-element__legend slds-form-element__label">
      {{ legend }}
    </legend>
    <div class="slds-form-element__control">
      <slot />
    </div>
    <div v-if="error" :id="errorId" class="slds-form-element__help">
      {{ error }}
    </div>
  </fieldset>
</template>

<script lang="ts">
import Vue from 'vue';

import { generateId } from '@/util';

export default Vue.extend({
  props: {
    uuid: {
      type: String,
      default() {
        return generateId();
      },
    },
    legend: { type: String, required: true },
    stacked: { type: Boolean },
    error: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    errorId(): string {
      return `error-${this.uuid}`;
    },
  },
});
</script>
