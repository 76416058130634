<script lang="ts">
import Vue, { PropType, VNode } from 'vue';

import type { ClassNames } from '@/types';
import { generateId } from '@/util';

enum BcProgressBarPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export default Vue.extend({
  props: {
    uid: {
      type: String,
      default() {
        return generateId();
      },
    },
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: undefined,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    bcLabelPosition: {
      type: String as PropType<BcProgressBarPosition>,
      default: BcProgressBarPosition.Top,
      validator: (val) => Object.values(BcProgressBarPosition).includes(val),
    },
  },
  computed: {
    integerValue(): number {
      return Math.ceil(this.value * 100);
    },
    valueClass(): ClassNames {
      return {
        [`slds-progress-bar__value_${this.color}`]: this.color !== undefined,
      };
    },
    valueStyle(): { width: string } {
      return { width: `${this.integerValue}%` };
    },
  },
  render(h): VNode {
    const progressbar = h(
      'div',
      {
        class: [
          'slds-progress-bar',
          'bc-progress-bar',
          { 'slds-progress-bar_circular': this.circular },
        ],
        attrs: {
          role: 'progressbar',
          'aria-valuemin': '0',
          'aria-valuemax': '0',
          'aria-valuenow': this.integerValue,
          'aria-labelledby': this.label ? this.uid : undefined,
        },
      },
      [
        h(
          'span',
          {
            class: this.valueClass,
            style: this.valueStyle,
            staticClass: 'slds-progress-bar__value bc-progress-bar__value',
          },
          [
            h(
              'span',
              { staticClass: 'slds-assistive-text' },
              `Progress: ${this.integerValue}%`
            ),
          ]
        ),
      ]
    );

    const label = this.$slots.label ?? this.label;

    const labelContainer =
      label !== undefined
        ? h(
            'div',
            {
              attrs: { id: this.uid },
              staticClass: 'slds-grid slds-grid_align-spread',
              class: {
                'slds-p-bottom_x-small':
                  this.bcLabelPosition === BcProgressBarPosition.Top,
                'slds-p-top_x-small':
                  this.bcLabelPosition === BcProgressBarPosition.Bottom,
              },
            },
            [
              h('span', undefined, label),
              h('span', { attrs: { 'aria-hidden': 'true' } }, [
                h('strong', undefined, `${this.integerValue}% Complete`),
              ]),
            ]
          )
        : null;

    return label !== undefined
      ? h('div', undefined, [
          this.bcLabelPosition === BcProgressBarPosition.Top
            ? labelContainer
            : null,
          progressbar,
          this.bcLabelPosition === BcProgressBarPosition.Bottom
            ? labelContainer
            : null,
        ])
      : progressbar;
  },
});
</script>

<style lang="scss">
.slds-progress-bar__value_weak {
  background-color: #9ba0a5;
}

.bc-progress-bar {
  &__value {
    transition: width 0.4s ease-in-out;
  }
}
</style>
