import type { Reference } from '@apollo/client/core';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  ServerError,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

import type {
  Deployment,
  DeploymentReview,
  ChangedFile,
  PermissionNext,
  PermissionUrl,
  Branchsync,
} from '@/graphql/types';
import { Phase, State } from '@/graphql/types';
import { DeploymentState } from '@/module/deployments/classes/DeploymentState';
import PermissionIdentifier from '@/module/permissions/classes/PermissionIdentifier';

import introspection from './graphql/introspection';
import { TypedTypePolicies } from './graphql/apollo-helpers';

window.CONFIG = window.CONFIG || {};

let sessionWarningEnabled = false;
export function enableSessionWarning(): void {
  sessionWarningEnabled = true;
}

const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: async (error: ServerError) => {
      // redirect authentication errors to login page
      if (error.statusCode === 401) {
        const message =
          'Your session has expired. Do you want leave this page and log in again?';
        if (sessionWarningEnabled && !window.confirm(message)) {
          return false;
        }

        window.location.href = `/login?next=${window.location.href}`;
      }

      // don't retry other errors
      return false;
    },
  },
});

const httpLink = new HttpLink({
  uri: `${window.CONFIG.BLUECANVAS_BACKEND_URL}/graphql`,
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': (
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('BLUECANVAS_CSRF_TOKEN=')) || '='
    ).split('=')[1],
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors !== undefined) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }

    if (networkError !== undefined) {
      console.warn(`[Network error]: ${networkError}`);
    }

    forward(operation);
  }
);

const link = ApolloLink.from([retryLink, errorLink, httpLink]);

const typePolicies: TypedTypePolicies = {
  User: {
    fields: {
      bookmarks: {
        merge: false,
      },
    },
  },
  ChangedFile: {
    fields: {
      date: {
        read(_, { readField }) {
          const timestamp =
            readField<ChangedFile['timestamp']>('timestamp') ?? undefined;
          if (timestamp === undefined) {
            return null;
          }

          return new Date(timestamp * 1000);
        },
      },
    },
  },
  Deployment: {
    fields: {
      deploymentState: {
        read(_, { readField }): Deployment['deploymentState'] {
          const phase = readField<Deployment['phase']>('phase');
          const state = readField<Deployment['state']>('state');
          if (phase === undefined || state === undefined) {
            throw new Error(
              'Phase and state must be queried when reading deploymentState'
            );
          }

          return new DeploymentState(phase, state);
        },
      },
      isPackagingComplete: {
        read(_, { readField }): Deployment['isPackagingComplete'] {
          const state =
            readField<Deployment['deploymentState']>('deploymentState');
          if (state === undefined) return false;

          return state.isComplete(Phase.VALIDATE);
        },
      },
      hasPackagingError: {
        read(_, { readField }): Deployment['hasPackagingError'] {
          const phase = readField<Deployment['phase']>('phase');
          const state = readField<Deployment['state']>('state');
          if (phase === Phase.PACKAGE && state === State.INTERNAL_ERROR) {
            return true;
          }
          if (phase === Phase.VALIDATE && state === State.PROBLEM) {
            return true;
          }
          if (phase === Phase.VALIDATE && state === State.INTERNAL_ERROR) {
            return true;
          }
          return false;
        },
      },
      cancelDate: {
        read(_, { readField }) {
          const cancelTime =
            readField<Deployment['cancelTime']>('cancelTime') ?? undefined;
          if (cancelTime === undefined) {
            return null;
          }
          return new Date(cancelTime);
        },
      },
      submitDate: {
        read(_, { readField }) {
          const submitTime =
            readField<Deployment['submitTime']>('submitTime') ?? undefined;
          if (submitTime === undefined) {
            return null;
          }
          return new Date(submitTime);
        },
      },
      updateDate: {
        read(_, { readField }) {
          const updateTime =
            readField<Deployment['updateTime']>('updateTime') ?? undefined;
          if (updateTime === undefined) {
            return null;
          }
          return new Date(updateTime);
        },
      },
      assignees: {
        merge: false,
      },
    },
  },
  DeploymentReview: {
    fields: {
      updateDate: {
        read(_, { readField }) {
          const updateTime =
            readField<DeploymentReview['updateTime']>('updateTime') ??
            undefined;
          if (updateTime === undefined) {
            return null;
          }
          return new Date(updateTime);
        },
      },
    },
  },
  NamedUser: {
    keyFields: ['email'],
  },
  PprDiff: {
    keyFields: ['diffId'],
  },
  PermissionIdentifier: {
    fields: {
      key: {
        read(_, { readField }) {
          const type = readField<PermissionIdentifier['type']>('type');
          const fullName =
            readField<PermissionIdentifier['fullName']>('fullName');
          if (type === undefined || fullName === undefined) return null;
          return new PermissionIdentifier(type, fullName).key;
        },
      },
    },
  },
  PermissionNext: {
    fields: {
      key: {
        read(_, { readField }) {
          const type = readField<PermissionNext['type']>('type');
          const fullName = readField<PermissionNext['fullName']>('fullName');
          if (type === undefined || fullName === undefined) return null;
          return new PermissionIdentifier(type, fullName).key;
        },
      },
      urls: {
        merge(
          existing: Reference[] = [],
          incoming: Reference[],
          { readField }
        ) {
          const res: Reference[] = [];
          const seen = new Set<string>();
          for (const url of existing) {
            const u = readField<PermissionUrl['url']>('url', url);
            if (u === undefined) continue;
            seen.add(u);
          }
          for (const url of incoming) {
            const u = readField<PermissionUrl['url']>('url', url);
            if (u === undefined) continue;
            if (seen.has(u)) continue;
            res.push(url);
          }
          return [...existing, ...res];
        },
      },
    },
  },
  PermissionURL: {
    keyFields: ['url'],
  },
  Branch: {
    fields: {
      deployRunState: {
        merge: true,
      },
    },
  },
  DeploymentPermissionMetadata: {
    fields: {
      selectedPatch: {
        merge: true,
      },
    },
  },
  Branchsync: {
    fields: {
      startTimeDate: {
        read(_, { readField }) {
          const startTime =
            readField<Branchsync['startTime']>('startTime') ?? undefined;
          if (startTime === undefined) {
            return null;
          }
          return new Date(startTime);
        },
      },
      endTimeDate: {
        read(_, { readField }) {
          const endTime =
            readField<Branchsync['endTime']>('endTime') ?? undefined;
          if (endTime === undefined) {
            return null;
          }
          return new Date(endTime);
        },
      },
    },
  },
};
const apolloClient = new ApolloClient({
  uri: `${window.CONFIG.BLUECANVAS_BACKEND_URL}/graphql`,
  link,
  cache: new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies,
  }),
  connectToDevTools: process.env.NODE_ENV === 'development',
});

export default apolloClient;
