import { PropOptions } from 'vue';

/**
 * Icon category from [lightningdesignsystem.com/icons/](https://www.lightningdesignsystem.com/icons/)
 */
export const category: PropOptions<string> = {
  type: String,
  required: true,
  validator(val: string) {
    return [
      'action',
      'custom',
      'doctype',
      'standard',
      'utility',
      'local',
    ].includes(val);
  },
};

export const colorVariant: PropOptions<string> = {
  type: String,
  validator(val: string) {
    return ['base', 'default', 'success', 'error', 'light', 'warning'].includes(
      val
    );
  },
};
