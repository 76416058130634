<template>
  <div
    v-show="isActive"
    :id="uid"
    :aria-labelledby="`${uid}__tab`"
    role="tabpanel"
    :class="`slds-tabs_${variant}__content`"
  >
    <slot :tab="this" :active="isActive"></slot>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import type { Location } from 'vue-router';
import type { WithProperties } from 'vue-typed-properties';

import type { Nullable } from '@/types';
import { generateId } from '@/util';

import { BadgeColor } from './Badge/Badge.types';

export default (Vue as WithProperties<{ uid: string }>).extend({
  name: 'SldsTab',
  inject: {
    variant: { default: 'default' },
  },
  props: {
    name: { type: String, required: true },
    title: { type: String, required: true },
    titleClass: { type: Object },
    badge: { type: [String, Number] },
    badgeColor: {
      type: String as PropType<BadgeColor>,
      default: undefined,
      validator: (val: BadgeColor) => Object.values(BadgeColor).includes(val),
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean },
    href: { type: String },
    target: { type: String },
    routeTo: { type: [String, Object] },
    // Callback function executed before tab switch. Returns a boolean that,
    // if false, tab will not be activated.
    beforeChange: { type: Function },
  },
  data() {
    return {
      routeLocation: null as Nullable<Location>,
      isDisabled: this.disabled,
      isActive: false,
    };
  },
  computed: {
    activeHref(): string {
      if (this.href) {
        return this.href;
      }
      if (this.routeTo) {
        return this.routeHref();
      }

      return '#';
    },
  },
  watch: {
    disabled(val) {
      this.isDisabled = val;
    },
  },
  created() {
    this.uid = generateId();
    // @ts-expect-error TODO
    this.$parent.init();
  },
  methods: {
    routeHref() {
      const router = this.$router;
      const current = this.$route;
      const { location, href } = router.resolve(this.routeTo, current, false);
      this.routeLocation = location;
      return href;
    },
  },
});
</script>
