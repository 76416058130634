import type { MaybeRef } from '@vue/composition-api';
import { unref, computed } from '@vue/composition-api';

import { TenantSettingsKey } from '@/data';

import useTenantSettings from '../useTenantSettings/useTenantSettings';

export default function useTenantSetting(setting: MaybeRef<TenantSettingsKey>) {
  const { indexed } = useTenantSettings();
  const s = computed(() => indexed.value.get(unref(setting)) ?? null);
  const value = computed(() => s.value?.value ?? null);
  const enabled = computed(() => s.value?.value === true);
  return { setting: s, value, enabled };
}
