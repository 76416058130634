import { Marked } from 'marked';

import type { MarkdownString, HTMLString } from '@/types';
import { MENTION_RE } from '@/module/mentions/mentions.constants';
import { UserForRichTextEditor } from '@/module/rte/rte.types';
import { indexBy } from '@/util';

import type { MarkdownHTMLConverter } from '../interfaces';
import type { ConstructorOptions } from './AbstractMarkdownHTMLConverter';
import AbstractHTMLMarkdownConverter from './AbstractMarkdownHTMLConverter';

export default class TipTapMarkdownHTMLConverter
  extends AbstractHTMLMarkdownConverter
  implements MarkdownHTMLConverter
{
  private readonly marked: Marked;

  constructor(options?: ConstructorOptions) {
    super(options);
    const marked = new Marked();
    marked.use({
      renderer: {
        list(body, ordered, start, hasTasks): string {
          const type = ordered ? 'ol' : 'ul';
          const startatt = ordered && start !== 1 ? ` start="${start}"` : '';
          const dataType = hasTasks ? ' data-type="taskList"' : '';
          return `<${type}${startatt}${dataType}>\n${body}</${type}>\n`;
        },
        listitem(text, task, checked) {
          if (task)
            return `<li data-type="taskItem" data-checked="${checked}">${text.trimStart()}</li>\n`;
          return `<li>${text}</li>\n`;
        },
        checkbox(_) {
          return '';
        },
      },
    });
    this.marked = marked;
  }

  convert(
    markdown: MarkdownString,
    users: UserForRichTextEditor[]
  ): HTMLString {
    let value = markdown;
    // `<br>` is converted to `<p><br><br class="ProseMirror-trailingBreak"></p>` by tiptap
    value = value.replace(/^<br>/gm, '<p></p>');
    const indexed = indexBy(users, 'email');
    value =
      indexed.size > 0
        ? value.replace(MENTION_RE, (match, email) => {
            const user = indexed.get(email) ?? null;
            if (user === null) {
              return match;
            }
            return `<span data-type="mention" data-id="${email}" data-label="${user.displayName}"></span>`;
          })
        : value;
    let result = this.sanitizer.sanitize(this.marked.parse(value));
    result = result.replace(/\n<\/code>/gm, '</code>');
    return result;
  }
}
