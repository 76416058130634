import type { PermissionType } from '@/graphql/types';

import type { PermissionFullName } from '../permissions.types';

export type PermissionIdentifierKey = string;

export default class PermissionIdentifier {
  constructor(
    public readonly type: PermissionType,
    public readonly fullName: PermissionFullName
  ) {}

  get key(): PermissionIdentifierKey {
    return `${this.type}:${this.fullName}`;
  }

  static fromKey(key: PermissionIdentifierKey): PermissionIdentifier {
    const [type, fullName] = key.split(':');
    return new PermissionIdentifier(type as PermissionType, fullName);
  }
}
