import type { MarkNonNullable } from '@/types';
import { SortOrder } from '@/module/common/sort/sort.types';

import type TableColumn from './TableColumn';
import type CustomColumn from './CustomColumn';
import type EditableTableColumn from './EditableTableColumn';

export type TableColumnKey = string;
export type TableItem = unknown;
export type Selected = string[] | Set<string>;

export interface HeaderScopedSlotData<T extends TableItem = TableItem> {
  column: TableColumn<T, unknown> | CustomColumn;
  index: number;
  items: T[];
}

export interface CellScopedSlotData<T extends TableItem = TableItem> {
  value: unknown;
  item: T;
  identity: string;
  index: number;
  items: T[];
  selected: boolean | null;
  selectedIdentity: string | undefined;
  expanded: boolean;
  toggleExpanded?: (item: T) => void;
}

export type TableExpandEventPayload = string[];

export interface ClickCellEventPayload<T extends TableItem> {
  item: T;
  key: string;
}

export type IdentityFn<T extends TableItem = TableItem> = (item: T) => string;

export type SelectedIdentityFn<T extends TableItem = TableItem> = (
  item: T
) => string;
/**
 * `true`: selected, `false`: not selected, `null`: indeterminate
 */
export type SelectedPredicate<T extends TableItem = TableItem> = (
  item: T
) => boolean | null;

export type ExpandedIdentityFn<T extends TableItem = TableItem> = (
  item: T
) => string;
export type ExpandedPredicate<T extends TableItem = TableItem> = (
  item: T
) => boolean;

export type TableEditable<T extends TableItem = TableItem> = {
  item: T;
  column: EditableTableColumn<T>;
  td: HTMLTableCellElement;
};

export enum TableSortingMode {
  CLIENT,
  SERVER,
}

export type TableSorting<T extends TableColumnKey = TableColumnKey> = {
  key: T;
  order: SortOrder;
};

export type TableColumnWithSorting<
  T extends TableItem = TableItem,
  U = unknown,
> = MarkNonNullable<TableColumn<T, U>, 'sorting'>;

export type TableSortingWithColumn<
  T extends TableItem = TableItem,
  U = unknown,
> = {
  column: TableColumnWithSorting<T, U>;
  order: SortOrder;
};

// Events

export interface TableEvents {
  'update:sort': TableSorting;
}

export type TableColumnGetterFn<T, U> = (item: T) => U;
export type TableColumnFormatterFn<T> = (value: T) => string;
export type TableColumnSortFn<T> = (a: T, b: T) => number;
export { SortOrder } from '@/module/common/sort/sort.types';
