import Vue from 'vue';
import {
  ComponentInternalInstance,
  InjectionKey,
  inject as originalInject,
  getCurrentInstance as originalGetCurrentInstance,
} from '@vue/composition-api';
import { isFunction, isEqual } from 'lodash-es';
import { customAlphabet } from 'nanoid/non-secure';
import { alphanumeric } from 'nanoid-dictionary';
import invariant from 'invariant';

const nanoid = customAlphabet(alphanumeric, 10);

export const generateId = nanoid;
export const uid = generateId;

/**
 * Returns a new Set excluding values that exist in `other`.
 */
export function setSubtract<T>(
  set: ReadonlySet<T>,
  other: ReadonlySet<T>
): Set<T> {
  return new Set([...set].filter((x) => !other.has(x)));
}

/**
 * Verifies that passed value is defined. Intended to use as a TS guard
 * @example
 * const values = [1, 2, undefined, 3, null].filter(isDefined) // typeof values => number
 */
export function isDefined<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}

export function hasSlot(this: Vue, name: string): boolean {
  return (
    this.$slots[name] !== undefined || this.$scopedSlots[name] !== undefined
  );
}

export function normalizeSlot(this: Vue, name = 'default', scope = {}) {
  const slot = this.$scopedSlots[name] || this.$slots[name];
  return isFunction(slot) ? slot(scope) : slot;
}

export function slugify(value: string) {
  return value.replace(/\s/g, '-').replace(/[^a-zA-Z0-9_/.-]/g, '-');
}

type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: unknown;
};

export function groupBy<
  T extends Record<string, unknown>,
  K extends KeyOfType<T, PropertyKey>,
>(items: Iterable<T>, field: K): Map<T[K], T[]> {
  const map = new Map<T[K], T[]>();
  for (const item of items) {
    const array = map.get(item[field]) ?? [];
    array.push(item);
    map.set(item[field], array);
  }
  return map;
}

export function inject<T>(key: InjectionKey<T> | string): T {
  const injected = originalInject<T>(key);
  if (injected === undefined) {
    throw new Error(`There is no provided value for "${key}" key`);
  }
  return injected;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getCurrentInstance(): ComponentInternalInstance {
  const instance = originalGetCurrentInstance();
  invariant(instance !== null, 'There is no current Vue instance');
  return instance;
}

export function snakeToKebab(string: string): string {
  return string.replace(/_/g, '-');
}

export function extractNode<T>(edge: { node: T }): T {
  return edge.node;
}

export function indexBy<T extends Record<string, unknown>, K extends keyof T>(
  items: Iterable<T>,
  fieldName: K
): Map<T[K], T> {
  const map = new Map<T[K], T>();
  for (const item of items) {
    map.set(item[fieldName], item);
  }
  return map;
}

export function changed<T extends Record<string, unknown>>(
  original: T,
  value: T
): Array<keyof T> {
  const result: Array<keyof T> = [];
  for (const key of Object.keys(original)) {
    if (isEqual(original[key], value[key]) === false) {
      result.push(key);
    }
  }
  return result;
}

export { default as isScrolledToBottom } from './util/is-scrolled-to-bottom';
export { default as omitTypename } from './util/omit-typename';
export { default as range } from './util/range';
export { default as setIs } from './util/set-is';
export { default as shortHash } from './util/short-hash';
export { default as splice } from './util/splice';
export { default as toPathComponents } from './util/toPathComponents';
export { default as toSelectOptions } from './util/toSelectOptions';
