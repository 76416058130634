import Vue from 'vue';
import type { VNode } from 'vue';
import type { WithEvents } from 'vue-typed-emit';
import type { WithProperties } from 'vue-typed-properties';

import { generateId } from '@/util';
import { IconType, IconSize, IconColor } from '../Icon/Icon.types';
import Icon from '../Icon/Icon';
import type { CheckboxButtonEvents } from './CheckboxButton.types';

export default (
  Vue as WithEvents<CheckboxButtonEvents, WithProperties<{ uid: string }>>
).extend({
  name: 'SldsCheckboxButton',
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'check',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  created() {
    this.uid = generateId();
  },
  methods: {
    onChange(e: InputEvent) {
      this.$emit('change', (e.target as HTMLInputElement).checked);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  render(h): VNode {
    return h(
      'label',
      {
        attrs: { for: this.uid },
        class: {
          'slds-checkbox-button_is-checked': this.checked,
          'slds-checkbox-button_is-disabled': this.disabled,
          'slds-checkbox-button_is-focused': this.focused,
        },
        staticClass: 'slds-checkbox-button',
      },
      [
        h('input', {
          attrs: { id: this.uid, type: 'checkbox', disabled: this.disabled },
          domProps: {
            checked: this.checked,
          },
          staticClass: 'slds-assistive-text',
          on: { change: this.onChange, focus: this.onFocus, blur: this.onBlur },
        }),
        h(Icon, {
          props: {
            type: IconType.Utility,
            name: this.icon,
            size: IconSize.XSmall,
            color: IconColor.Current,
            title: this.title,
          },
        }),
      ]
    );
  },
});
