<template>
  <SldsFormElement
    :label="label"
    :stacked="stacked"
    :horizontal="horizontal"
    :hide-label="hideLabel"
    :error="error"
  >
    <div class="slds-select_container">
      <select
        ref="select"
        v-model="model"
        v-bind="$attrs"
        class="slds-select"
        :class="{ 'slds-select--empty': model === '' }"
        v-on="inputListeners"
      >
        <option v-if="hasPlaceholderOption" value="" selected disabled>
          {{ placeholder }}
        </option>
        <option
          v-for="option in options"
          :key="option[optionValue]"
          :value="option[optionValue]"
        >
          {{ option[optionLabel] }}
        </option>
      </select>
    </div>
  </SldsFormElement>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { WithRefs } from 'vue-typed-refs';
import Vue from 'vue';

import type { VueComponentListeners } from '@/types';

import SldsFormElement from './FormElement.vue';

type Refs = {
  select: HTMLSelectElement;
};

export default (Vue as WithRefs<Refs>).extend({
  name: 'SldsSelect',
  components: {
    SldsFormElement,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: undefined,
    },
    options: {
      type: Array as PropType<Record<string, unknown>[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    model: {
      get(): string | number {
        return this.value;
      },
      set(value: string) {
        if (value === this.value) return;
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
    inputListeners(): VueComponentListeners {
      const { input: _, change: __, ...listeners } = this.$listeners;
      return listeners as VueComponentListeners;
    },
    hasPlaceholderOption(): boolean {
      return (
        this.options.length === 1 ||
        this.options.find(
          (option) =>
            option[this.optionValue] === undefined ||
            option[this.optionValue] === null ||
            option[this.optionValue] === ''
        ) === undefined
      );
    },
  },
  methods: {
    /** @public */
    focus() {
      this.$refs.select.focus();
    },
  },
});
</script>

<style lang="scss">
@use '../../style/variables';

.slds-select {
  &--empty {
    color: variables.$color-text-placeholder;
  }
}
</style>
