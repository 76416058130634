<template>
  <div class="slds-form__item" role="listitem">
    <div
      class="slds-form-element slds-form-element_edit slds-form-element_stacked slds-hint-parent"
      :class="{
        'slds-form-element_edit': editable && editing === false,
        'slds-form-element_readonly': editable === false && editing === false,
        'slds-is-editing': editing,
      }"
    >
      <span class="slds-form-element__label">
        <abbr v-if="required" class="slds-required" title="required">* </abbr>
        {{ title }}
      </span>
      <div class="slds-form-element__control">
        <div v-if="editing === false" class="slds-form-element__static">
          <slot />
        </div>
        <slot v-else />
        <ButtonIcon
          v-if="editable && editing === false"
          :title="`Edit: ${title}`"
          @click="edit"
        >
          <ButtonIconIcon name="edit" hint />
        </ButtonIcon>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import type { WithEvents } from 'vue-typed-emit';

import ButtonIcon from './ButtonIcon/ButtonIcon';
import ButtonIconIcon from './ButtonIcon/ButtonIconIcon';
import type { RecordEvents } from './Record/Record.types';

export default (Vue as WithEvents<RecordEvents>).extend({
  name: 'SldsRecord',
  components: {
    ButtonIcon,
    ButtonIconIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    edit() {
      this.$emit('edit');
    },
  },
});
</script>
