import type { PluginObject } from 'vue';

import toast from './toast';

const ToastPlugin: PluginObject<never> = {
  install(Vue) {
    Vue.prototype.$toast = toast;
  },
};

export default ToastPlugin;
