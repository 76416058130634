import { computed } from '@vue/composition-api';

import { useListTenantSettingsQuery } from '@/graphql/types';
import { indexBy } from '@/util';
import { TenantSettingsKey } from '@/data/settings';

export default function useTenantSettings() {
  const { result, loading } = useListTenantSettingsQuery();
  const settings = computed(() => result.value?.tenantSettings ?? []);
  const indexed = computed(() => indexBy(settings.value, 'setting'));
  function enabled(setting: TenantSettingsKey) {
    return indexed.value.get(setting)?.value === true;
  }
  return {
    indexed,
    enabled,
    loading,
  };
}
