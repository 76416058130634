import Vue, { PropType } from 'vue';
import {
  Instance as PopperInstance,
  Options as PopperOptions,
  Placement as PopperPlacement,
  createPopper,
} from '@popperjs/core';
import { placements } from '@popperjs/core/lib/enums';
import type { WithProperties } from 'vue-typed-properties';

import { NUBBIN } from '@/components/slds/types/nubbin';

const SLDS_ARROW_SIZE = 16;
const SLDS_ARROW_OFFSET = 16;
const VERTICAL_OFFSET = 12;

export type Options = PopperOptions;

export type Placement = PopperPlacement;

export const PopperMixin = (
  Vue as WithProperties<{ popper: PopperInstance | null }>
).extend({
  props: {
    placement: {
      type: String as PropType<Placement>,
      default: 'top-start' as Placement,
      validator: (val) => placements.includes(val),
    },
    options: {
      type: Object as PropType<PopperOptions>,
    },
  },
  data() {
    return {
      actualPlacement: this.placement,
    };
  },
  created() {
    this.popper = null;
  },
  beforeDestroy() {
    this.destroyPopperInstance();
  },
  methods: {
    createPopperInstance(
      target: Element,
      element: HTMLElement,
      options?: PopperOptions
    ) {
      const computedOptions: PopperOptions = {
        placement: this.placement,
        strategy: 'fixed',
        ...options,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: this.getOffset(),
            },
          },
          ...(this.options && this.options.modifiers
            ? this.options.modifiers
            : []),
        ],
        onFirstUpdate: (state) => {
          if (state.placement) {
            this.actualPlacement = state.placement;
          }
        },
      };
      this.popper = createPopper(target, element, computedOptions);
    },
    destroyPopperInstance() {
      if (this.popper !== null) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    // TODO: handle all the cases
    getNubbin(): (typeof NUBBIN)[number] {
      switch (this.actualPlacement) {
        case 'top-start':
          return 'bottom-left';
        case 'top':
          return 'bottom';
        case 'top-end':
          return 'bottom-right';
        case 'right':
          return 'left';
        case 'bottom-end':
          return 'top-right';
        case 'bottom-start':
          return 'top-left';
        case 'bottom':
          return 'top';
        case 'left':
          return 'right';
        default:
          return 'left-top';
      }
    },
    // TODO: handle all the cases
    getOffset() {
      const hasArrow = this.hasArrow();

      switch (this.actualPlacement) {
        case 'top-start':
          return [-SLDS_ARROW_OFFSET, VERTICAL_OFFSET];
        case 'top':
        case 'bottom':
          return [0, VERTICAL_OFFSET];
        case 'top-end':
          return [SLDS_ARROW_OFFSET, VERTICAL_OFFSET];
        case 'right':
          return [0, VERTICAL_OFFSET];
        case 'bottom-end':
          return hasArrow
            ? [SLDS_ARROW_OFFSET / 2, VERTICAL_OFFSET + SLDS_ARROW_SIZE / 2]
            : [0, 4];
        case 'bottom-start':
          return hasArrow ? [0, VERTICAL_OFFSET + SLDS_ARROW_SIZE / 2] : [0, 4];
        case 'left':
          return [0, VERTICAL_OFFSET];
        default:
          return [0, 0];
      }
    },
    reposition() {
      if (this.popper === null) return;
      this.popper.update();
    },
    hasArrow() {
      return true;
    },
  },
});
