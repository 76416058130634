import { computed } from '@vue/composition-api';

import type { BranchName } from '@/types';
import { useUpdateBookmarksMutation } from '@/graphql/types';

import useProfile from '../useProfile';

export default function useBookmarks() {
  const { profile } = useProfile();
  const bookmarks = computed(
    () => new Set(profile.value.bookmarks.map((branch) => branch.branchName))
  );
  const { mutate } = useUpdateBookmarksMutation();
  async function toggle(branchName: BranchName, value: boolean): Promise<void> {
    const user = profile.value;
    let list: BranchName[];
    if (value) {
      if (bookmarks.value.has(branchName)) return;
      list = [...bookmarks.value, branchName];
    } else {
      if (bookmarks.value.has(branchName) === false) return;
      list = [...bookmarks.value].filter((b) => b !== branchName);
    }
    await mutate(
      { id: user.id, bookmarks: list },
      {
        optimisticResponse: {
          __typename: 'Mutation',
          updateUser: {
            __typename: 'UpdateUserPayload',
            user: {
              ...user,
              bookmarks: value
                ? [
                    ...user.bookmarks,
                    { __typename: 'Branch', id: 'ID', branchName },
                  ]
                : user.bookmarks.filter((b) => b.branchName !== branchName),
            },
          },
        },
      }
    );
  }

  function isBookmarked(branch: BranchName): boolean {
    return bookmarks.value.has(branch);
  }

  return {
    bookmarks,
    isBookmarked,
    toggle,
  };
}
