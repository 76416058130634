<script lang="ts">
import type { PropType } from 'vue';
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { ButtonGroupType } from './ButtonGroup.types';

export default Vue.extend({
  name: 'SldsButtonGroup',
  functional: true,
  props: {
    type: {
      type: String as PropType<ButtonGroupType>,
      validator: (val) => Object.values(ButtonGroupType).includes(val),
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
  },
  render(h, { props, children, data }) {
    const { type, label } = props;
    const tag = type === ButtonGroupType.LIST ? 'ul' : 'div';
    const items = children ?? [];
    return h(
      tag,
      mergeData(data, {
        attrs: {
          role:
            props.type === 'row' || props.type === undefined
              ? 'group'
              : undefined,
          'aria-label': label,
        },
        class: {
          [`slds-button-group-${props.type}`]: props.type !== undefined,
          'slds-button-group': props.type === undefined,
        },
      }),
      tag === 'ul'
        ? items.map((child) =>
            h('li', { staticClass: 'slds-button-group-item' }, [child])
          )
        : items
    );
  },
});
</script>
