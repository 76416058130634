import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsPillContainer',
  functional: true,
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data, children }) {
    return h('div', mergeData(data, { staticClass: 'slds-pill_container' }), [
      h(
        'ul',
        {
          attrs: {
            role: 'listbox',
            'aria-label': props.label,
            'aria-orientation': 'horizontal',
          },
          staticClass: 'slds-listbox slds-listbox_horizontal',
        },
        children.map((child) => {
          if (child.data !== undefined) {
            const attrs = { role: 'option', 'aria-selected': 'true' };
            child.data.attrs = { ...child.data.attrs, ...attrs };
          }
          return h(
            'li',
            {
              key: child.key,
              attrs: { role: 'presentation' },
              staticClass: 'slds-listbox-item"',
            },
            [child]
          );
        })
      ),
    ]);
  },
});
