import type { VNode, PropType } from 'vue';
import type { WithEvents } from 'vue-typed-emit';

import SlotMixin from '@/mixins/slot.mixin';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonIconIcon from '../ButtonIcon/ButtonIconIcon';
import {
  ButtonIconVariant,
  ButtonIconSize,
} from '../ButtonIcon/ButtonIcon.types';

import { AlertVariant, AlertEvents } from './Alert.types';

export default (SlotMixin as WithEvents<AlertEvents, typeof SlotMixin>).extend({
  name: 'SldsAlert',
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String as PropType<AlertVariant>,
      default: AlertVariant.Info,
      validator: (val) => Object.values(AlertVariant).includes(val),
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCloseClick() {
      this.$emit('input', false);
      this.$emit('close');
    },
  },
  render(h): VNode {
    if (this.value === false) {
      return h();
    }

    return h(
      'div',
      {
        attrs: { role: 'alert' },
        staticClass: 'slds-notify slds-notify_alert',
        class: {
          [`slds-alert_${this.variant}`]: this.variant !== AlertVariant.Info,
        },
      },
      [
        h('span', { staticClass: 'slds-assistive-text' }, this.variant),
        this.hasSlot('icon') ? this.normalizeSlot('icon') : null,
        h('h2', undefined, this.$slots.default),
        this.dismissible
          ? h('div', { staticClass: 'slds-notify__close' }, [
              h(
                ButtonIcon,
                {
                  props: {
                    variant:
                      this.variant === AlertVariant.Warning
                        ? undefined
                        : ButtonIconVariant.Inverse,
                    size: ButtonIconSize.Small,
                    title: 'Close',
                  },
                  on: {
                    click: this.onCloseClick,
                  },
                },
                [
                  h(ButtonIconIcon, {
                    props: { name: 'close' },
                  }),
                ]
              ),
            ])
          : null,
      ]
    );
  },
});
