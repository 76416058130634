<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsForm',
  functional: true,
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
  render(h, { props, data, children }) {
    return h(
      props.tag,
      mergeData(data, { staticClass: 'slds-form' }),
      children
    );
  },
});
</script>
