<template>
  <div
    v-click-outside="close"
    class="slds-dropdown-trigger slds-dropdown-trigger_click"
    :class="[{ 'slds-is-open': isShow }]"
    @click.stop.prevent="toggle"
  >
    <slot />
  </div>
</template>

<script>
import { directive as clickOutside } from 'v-click-outside';

export default {
  name: 'SldsDropdownTrigger',
  directives: {
    clickOutside,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      isShow: this.show,
    };
  },
  watch: {
    show: function (val) {
      this.isShow = val;
    },
  },
  methods: {
    open() {
      if (!this.isShow) {
        this.isShow = true;
        this.$emit('open');
      }
    },
    close() {
      if (this.isShow) {
        this.isShow = false;
        this.$emit('close');
      }
    },
    toggle() {
      if (this.isShow) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>
