import Vue from 'vue';

export const ModalMixin = Vue.extend({
  name: 'ModalMixin',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    $open: {
      get(): boolean {
        return this.open;
      },
      set(value: boolean): void {
        this.$emit('update:open', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:open', false);
    },
  },
});
