import type { PropType, VNode } from 'vue';

import { generateId } from '@/util';
import SlotMixin from '@/mixins/slot.mixin';

export default SlotMixin.extend({
  name: 'SldsFormElement',
  props: {
    tag: {
      type: String as PropType<'div' | 'fieldset'>,
      default: 'div' as const,
      validator: (val) => val === 'div' || val === 'fieldset',
    },
    label: {
      type: String,
      required: true,
    },
    for: {
      type: String,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: undefined,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    help: {
      type: String,
      default: undefined,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasError(): boolean {
      return (
        this.error !== undefined && this.error !== null && this.error !== ''
      );
    },
    errorUid(): string {
      return generateId();
    },
  },
  render(h): VNode {
    const { hasError } = this;
    const { default: defaultSlot, static: staticSlot } = this.$scopedSlots;

    if (defaultSlot === undefined) {
      return h();
    }

    let labelTag = 'label';

    if (this.tag === 'fieldset') {
      labelTag = 'legend';
    } else if (this.static) {
      labelTag = 'span';
    }

    return h(
      this.tag,
      {
        staticClass: 'slds-form-element',
        class: { 'slds-has-error': hasError, 'slds-has-warning': this.warning },
      },
      [
        h(
          labelTag,
          {
            attrs: { for: labelTag === 'label' ? this.for : undefined },
            staticClass: 'slds-form-element__label',
            class: {
              'slds-form-element__legend': labelTag === 'legend',
              'slds-assistive-text': this.noLabel,
            },
          },
          [
            this.required
              ? h(
                  'abbr',
                  {
                    attrs: { title: 'required' },
                    staticClass: 'slds-required',
                  },
                  '*'
                )
              : null,
            this.label,
          ]
        ),
        h(
          'div',
          {
            staticClass: 'slds-form-element__control',
            class: { 'slds-border_bottom': this.static },
          },
          this.static
            ? staticSlot!({})
            : defaultSlot({
                errorUid: hasError ? this.errorUid : undefined,
              })
        ),
        this.hasSlot('help') || hasError || this.help !== undefined
          ? h(
              'div',
              {
                attrs: { id: this.errorUid },
                staticClass: 'slds-form-element__help',
              },
              this.hasSlot('help')
                ? this.normalizeSlot('help')
                : this.error ?? this.help
            )
          : null,
      ]
    );
  },
});
