import { computed } from '@vue/composition-api';

import { useListRolesQuery } from '@/graphql/types';
import { extractNode } from '@/util';

export default function useRoles() {
  const { result } = useListRolesQuery();
  const roles = computed(
    () => result.value?.roles.edges.map(extractNode) ?? []
  );

  return {
    roles,
  };
}
