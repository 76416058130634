<template>
  <span :title="title" :class="containerClassName" v-on="$listeners">
    <base-icon
      v-bind="$attrs"
      :category="category"
      :name="name"
      :color-variant="computedColorVariant"
      :class="[svgClassName, 'slds-icon']"
      :style="svgStyle"
    />
    <span v-if="title" class="slds-assistive-text">{{ title }}</span>
  </span>
</template>

<script lang="ts">
import Vue from 'vue';

import BaseIcon from './private/BaseIcon.vue';

import { category, colorVariant } from './common/icon/props';
import { getIconIdentityClassName } from './common/icon/util';

/**
 * The Icon component is the Lightning Design System Icon component and should
 * be used for naked icons.
 *
 * For icons that are buttons, use the <a href='/components/buttons/'>Button component</a>
 * component with <code>variant='icon'</code>.
 */
export default Vue.extend({
  name: 'SldsIcon',
  components: {
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    category,
    colorVariant,
    /**
     * CSS classes that are applied to the SVG.
     */
    svgClassName: {},
    /**
     * Name of the icon. Visit <a href='http://www.lightningdesignsystem.com/resources/icons'>Lightning Design System Icons</a> to reference icon names.
     */
    name: String,
    /**
     * Custom styles to be passed to the SVG. Could be used to change icon or background color.
     */
    svgStyle: {},
    /**
     * Title attribute for the icon container
     */
    title: String,
    container: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerClassName(): Record<string, boolean> {
      const { category } = this;
      return {
        'slds-icon_container': category !== 'utility' || this.container,
        'slds-icon_container_circle': category === 'action',
        [this.kababCaseName]: category !== 'utility' && category !== 'doctype',
      };
    },
    computedColorVariant(): string | undefined {
      return this.colorVariant
        ? this.colorVariant
        : this.category === 'utility'
          ? 'default'
          : undefined;
    },
    kababCaseName(): string {
      return this.name
        ? getIconIdentityClassName(this.category, this.name)
        : '';
    },
  },
});
</script>
