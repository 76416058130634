<script lang="ts">
import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import BaseIcon from './private/BaseIcon.vue';

export default Vue.extend({
  functional: true,
  render(h, { data }) {
    return h(
      BaseIcon,
      mergeData(data, {
        props: { category: 'utility' },
        staticClass: 'slds-icon',
      })
    );
  },
});
</script>
