<script lang="ts">
/**
 * @see https://developer.microsoft.com/en-us/fluentui#/controls/web/persona
 * @see https://github.com/microsoft/fluentui/tree/master/packages/react-internal/src/components/Persona
 */
import Vue, { PropType } from 'vue';
import { mergeData } from 'vue-functional-data-merge';

import { PersonaInitialsColor, COLOR_SWATCHES_LOOKUP } from './Avatar.types';

const COLOR_SWATCHES_NUM_ENTRIES = COLOR_SWATCHES_LOOKUP.length;

function getInitialsColorFromName(displayName: string): PersonaInitialsColor {
  let color = PersonaInitialsColor.blue;

  let hashCode = 0;
  for (let iLen = displayName.length - 1; iLen >= 0; iLen--) {
    const ch = displayName.charCodeAt(iLen);
    const shift = iLen % 8;
    hashCode ^= (ch << shift) + (ch >> (8 - shift));
  }

  color = COLOR_SWATCHES_LOOKUP[hashCode % COLOR_SWATCHES_NUM_ENTRIES];

  return color;
}

function personaInitialsColorToHexCode(
  personaInitialsColor: PersonaInitialsColor
): string {
  switch (personaInitialsColor) {
    case PersonaInitialsColor.lightBlue:
      return '#4F6BED';
    case PersonaInitialsColor.blue:
      return '#0078D4';
    case PersonaInitialsColor.darkBlue:
      return '#004E8C';
    case PersonaInitialsColor.teal:
      return '#038387';
    case PersonaInitialsColor.lightGreen:
    case PersonaInitialsColor.green:
      return '#498205';
    case PersonaInitialsColor.darkGreen:
      return '#0B6A0B';
    case PersonaInitialsColor.lightPink:
      return '#C239B3';
    case PersonaInitialsColor.pink:
      return '#E3008C';
    case PersonaInitialsColor.magenta:
      return '#881798';
    case PersonaInitialsColor.purple:
      return '#5C2E91';
    case PersonaInitialsColor.orange:
      return '#CA5010';
    case PersonaInitialsColor.lightRed:
      return '#D13438';
    case PersonaInitialsColor.darkRed:
      return '#A4262C';
    case PersonaInitialsColor.violet:
      return '#8764B8';
    case PersonaInitialsColor.gold:
      return '#986F0B';
    case PersonaInitialsColor.burgundy:
      return '#750B1C';
    case PersonaInitialsColor.warmGray:
      return '#7A7574';
    case PersonaInitialsColor.cyan:
      return '#005B70';
    case PersonaInitialsColor.rust:
      return '#8E562E';
    case PersonaInitialsColor.coolGray:
      return '#69797E';
  }
}

const SIZES = ['small'] as const;

export type Size = (typeof SIZES)[number];

export default Vue.extend({
  name: 'SldsAvatar',
  functional: true,
  props: {
    displayName: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<Size>,
      default: undefined,
      validator: (val) => SIZES.includes(val),
    },
  },
  render(h, { props, data }) {
    const { src } = props;
    const [firstname, lastname] = props.displayName.split(' ');
    const displayName = `${firstname}${lastname ? ` ${lastname}` : ''}`;
    return h(
      'span',
      mergeData(data, {
        staticClass: 'slds-avatar slds-avatar_circle',
        class: { [`slds-avatar_${props.size}`]: props.size },
      }),
      [
        src
          ? h('img', { attrs: { src, alt: displayName, title: displayName } })
          : h(
              'abbr',
              {
                attrs: {
                  title: displayName,
                },
                style: {
                  backgroundColor: personaInitialsColorToHexCode(
                    getInitialsColorFromName(displayName)
                  ),
                },
                staticClass: 'slds-avatar__initials',
              },
              firstname && lastname
                ? `${firstname[0].toUpperCase()}${
                    lastname ? lastname[0].toUpperCase() : ''
                  }`
                : firstname[0].toUpperCase()
            ),
      ]
    );
  },
});
</script>
