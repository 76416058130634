import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsPageHeaderName',
  functional: true,
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  render(h, { props, data }) {
    return h(
      'div',
      mergeData(data, { staticClass: 'slds-page-header__name' }),
      [
        h('div', { staticClass: 'slds-page-header__name-title' }, [
          h('h1', undefined, [
            h('span', undefined, props.subtitle),
            h(
              'span',
              {
                attrs: { title: props.title },
                staticClass: 'slds-page-header__title slds-truncate',
              },
              props.title
            ),
          ]),
        ]),
      ]
    );
  },
});
