export type PathComponents = {
  dirname: string;
  basename: string;
};

const SRC_FOLDER_PREFIX = 'src/';

export default function toPathComponents(path: string): PathComponents {
  const lastSlash = path.lastIndexOf('/');
  if (lastSlash === -1) {
    return {
      dirname: '',
      basename: path,
    };
  }
  return {
    dirname: path.startsWith(SRC_FOLDER_PREFIX)
      ? path.substring(SRC_FOLDER_PREFIX.length, lastSlash)
      : path.substring(0, lastSlash),
    basename: path.substring(lastSlash + 1),
  };
}
