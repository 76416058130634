<template>
  <div
    :class="[
      'slds-form-element',
      {
        'slds-has-error': error,
        'slds-form-element_stacked': stacked,
        'slds-form-element_readonly': isStatic,
      },

      containerClassName,
    ]"
    :style="containerStyle"
  >
    <slds-utility-label
      v-bind="{
        assistiveText,
        htmlFor: isStatic ? undefined : id,
        label,
        required,
        variant: isStatic ? 'static' : 'base',
      }"
      :class="{ 'slds-assistive-text': noLabel }"
    />
    <slds-utility-tooltip-field-level-help
      v-if="$slots.fieldLevelHelpTooltip && hasRenderedLabel"
    >
      <slot name="fieldLevelHelpTooltip" />
    </slds-utility-tooltip-field-level-help>

    <Input_InnerInput
      ref="input"
      v-bind="{
        ...$attrs,
        'aria-describedby': errorId,
        assistiveText,
        containerClassName: 'slds-form-element__control',
        className: [
          { 'slds-input_counter': variant === 'counter' },
          { 'slds-p-horizontal_none': variant === 'counter' && readonly },
          inputClassName,
        ],
        id,
        inputStyle,
        isStatic,
        readonly,
        required,
        type: variant === 'counter' ? 'number' : type,
        value,
        variant,
        placeholder,
        clearable,
      }"
      v-on="$listeners"
    >
      <template v-if="hasSlot('icon-left')" #icon-left>
        <slot name="icon-left" />
      </template>

      <template v-if="hasSlot('icon-right')" #icon-right>
        <slot
          name="icon-right"
          classes="slds-input__icon slds-input__icon_right"
        />
      </template>
      <template v-if="hasSlot('addon-right')" #addon-right>
        <slot name="addon-right" />
      </template>
      <template v-if="$slots.inlineEditTrigger" #inlineEditTrigger>
        <slot name="inlineEditTrigger" />
      </template>
    </Input_InnerInput>

    <div
      v-if="inlineHelpText || $slots.inlineHelpText"
      class="slds-form-element__help"
    >
      <slot name="inlineHelpText">{{ inlineHelpText }}</slot>
    </div>

    <div
      v-if="error || $slots.error"
      :id="errorId"
      class="slds-form-element__help"
    >
      <slot name="error">{{ error }}</slot>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { WithRefs } from 'vue-typed-refs';

import { generateId, hasSlot } from '@/util';

import Input_InnerInput from '@/components/slds/Input_InnerInput.vue';

export type Refs = {
  input: InstanceType<typeof Input_InnerInput>;
};

/**
 * A styled HTML `<input>` element for freeform data entry with label, help
 * text, and error handling.
 *
 * @event inputRef This callback exposes the input reference / DOM node to parent components. `<Parent inputRef={(inputComponent) => this.input = inputComponent} />`
 *
 * @slot default - Elements are added after the `input`.
 * @slot error - Message to display when the input is in an error state.
 * @slot fieldLevelHelpTooltip - A `Tooltip` component that is displayed next to the label.
 * @slot icon-left - Left aligned icon, must be instance of `design-system-react/components/icon/input-icon`
 * @slot icon-right - Right aligned icon, must be instance of `design-system-react/components/icon/input-icon`
 * @slot inlineEditTrigger
 * @slot inlineHelpText - Displays help text under the input.
 *
 * @see https://lightningdesignsystem.com/components/input/
 * @see https://lightningdesignsystem.com/components/input
 */
export default (Vue as WithRefs<Refs>).extend({
  name: 'SldsInput',
  components: {
    Input_InnerInput,
  },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * The `aria-describedby` attribute is used to indicate the IDs of the elements that describe the object. It is used to establish a relationship between widgets or groups and text that described them. This is very similar to aria-labelledby: a label describes the essence of an object, while a description provides more information that the user might need.
     */
    'aria-describedby': String,
    /**
     * **Assistive text for accessibility**
     * * `label`: Visually hidden label but read out loud by screen readers.
     * * `spinner`: Text for loading spinner icon.
     */
    assistiveText: {
      type: Object as PropType<{
        label: string;
        spinner: string;
      }>,
      default() {
        return {};
      },
    },
    /**
     * Class names to be added to the outer container of the input.
     */
    containerClassName: {},
    /**
     * Custom styles to be passed to the component container
     */
    containerStyle: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
    },
    error: {
      type: String,
      default: undefined,
    },
    /**
     * Every input must have a unique ID in order to support keyboard navigation and ARIA support.
     */
    id: {
      type: String,
      default() {
        return generateId();
      },
    },
    /**
     * Displays help text under the input.
     */
    inlineHelpText: String,
    /**
     * Class names to be added to the the input element.
     */
    inputClassName: {},
    /**
     * styles to be added to input
     */
    inputStyle: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
    },
    /**
     * Displays the value of the input statically. This follows the static input UX pattern.
     */
    isStatic: Boolean,
    /**
     * This label appears above the input.
     */
    label: String,
    placeholder: {
      type: String,
      default: undefined,
    },
    /**
     * Displays the value of the input as read-only. This is used in the inline edit UX pattern.
     */
    readonly: Boolean,
    /**
     * Highlights the input as a required field (does not perform any validation).
     */
    required: Boolean,
    /**
     * The `<Input>` element includes support for all HTML5 types.
     */
    type: {
      type: String,
      validator(val: string) {
        return [
          'text',
          'password',
          'datetime',
          'datetime-local',
          'date',
          'month',
          'time',
          'week',
          'number',
          'email',
          'url',
          'search',
          'tel',
          'color',
        ].includes(val);
      },
      default: 'text',
    },
    /**
     * The input value, bound with `v-model`.
     */
    value: [String, Number],
    /**
     * Which UX pattern of input? The default is `base` while other option is `counter`
     */
    variant: {
      type: String,
      validator(val: string) {
        return ['base', 'counter'].includes(val);
      },
      default: 'base',
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    errorId(): string {
      return this['aria-describedby'] || `${this.id}-error`;
    },
  },
  methods: {
    focus() {
      this.$refs.input.$refs.input.focus();
    },
    hasSlot,
  },
});
</script>

<style lang="scss">
@use '../../style/variables';

// Fix the border rendering in readonly inputs, so that the help text is
// below the line.
.slds-form-element_readonly {
  border-bottom: none;

  .slds-form-element__control {
    border-bottom: variables.$border-width-thin solid variables.$color-border;
  }
}
</style>
