export enum AlertVariant {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Offline = 'offline',
}

export interface AlertProps {
  value?: boolean;
  variant?: AlertVariant;
}

export interface AlertEvents {
  input: boolean;
  close: undefined;
}
