import Vue from 'vue';
import { mergeData } from 'vue-functional-data-merge';

export default Vue.extend({
  name: 'SldsExpressionTitle',
  functional: true,
  render(h, { data, children }) {
    return h(
      'h2',
      mergeData(data, { staticClass: 'slds-expression__title' }),
      children
    );
  },
});
