var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"slds-card",class:{
    'slds-p-top_small': !_vm.hasHeader && !_vm.noHeaderPadding,
    'slds-card_boundary': _vm.boundary,
  },attrs:{"id":_vm.id}},[(_vm.hasHeader)?_c('div',{staticClass:"slds-card__header slds-grid",class:_vm.headerClass},[_c('header',{staticClass:"slds-media slds-media_center slds-has-flexi-truncate"},[(_vm.hasSlot('icon'))?_c('div',{staticClass:"slds-media__figure"},[_vm._t("icon")],2):_vm._e(),_c('div',{staticClass:"slds-media__body"},[_vm._t("title",function(){return [(_vm.id)?_c(_vm.titleTag,{tag:"component"},[_c('router-link',{staticClass:"slds-card__header-link slds-truncate",attrs:{"title":_vm.title,"to":("#" + _vm.id)}},[_c('span',{staticClass:"slds-text-heading_small"},[_vm._v(_vm._s(_vm.title))])])],1):_c(_vm.titleTag,{tag:"component"},[_c('span',{staticClass:"slds-card__header-title slds-truncate",attrs:{"title":_vm.title}},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]},{"card":this}),_vm._t("header")],2)]),_c('div',{staticClass:"slds-no-flex"},[_vm._t("buttons",null,{"card":this})],2),_vm._t("header-append")],2):_vm._e(),(_vm.loading)?_c('div',{class:{ 'slds-spinner_container': _vm.loading && _vm.noContentHideLoading }},[_vm._m(0)]):_vm._e(),(!_vm.loading || _vm.noContentHideLoading)?_c('div',{class:[
      {
        'slds-card__body': true,
        'slds-card__body_inner': _vm.noPadding === false,
      },
      _vm.bodyClass ],style:(_vm.bodyStyle)},[_vm._t("default")],2):_vm._e(),_c('footer',{class:{
      'slds-card__footer': true,
      'slds-p-around_xx-small': _vm.noFooterPadding,
    }},[_vm._t("footer")],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slds-spinner slds-spinner_small",attrs:{"role":"status"}},[_c('span',{staticClass:"slds-assistive-text"},[_vm._v("Loading")]),_c('div',{staticClass:"slds-spinner__dot-a"}),_c('div',{staticClass:"slds-spinner__dot-b"})])}]

export { render, staticRenderFns }